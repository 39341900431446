import {AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {SocialSharingService} from '../../../services/social-sharing.service';
import {copyToClipboard} from '../../../helper/clipboard';
import {MobileDeviceService} from '../../../services/mobile-device.service';
import {TippyDirective} from '@ngneat/helipopper';
import {Clipboard} from '@angular/cdk/clipboard';
import {ToastService} from '../../../services/toast.service';
import {HttpReqService} from '../../../services/http-req.service';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'mypart-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SocialSharingComponent implements AfterViewInit {
  @ViewChild('tooltip', {static: false}) tippy: TippyDirective | undefined;

  @Input() source: 'playlist' | 'song' | 'search' | 'affiliate link' = 'playlist';
  @Input() link: string | undefined;
  @Input() youtubeId: string | undefined;
  @Input() shareColor: string = 'text-mypart_red-500';
  @Input() showTooltip = true;
  @Input() hideShareButton = false;
  @Input() songInfo?: string;

  isMobileDevice = this.mobileDeviceService.isMobileDevice;

  hasRoomOnLeft: boolean = true; // default to true

  constructor(private socialSharingService: SocialSharingService,
              private mobileDeviceService: MobileDeviceService,
              private clipboard: Clipboard,
              private toast: ToastService,
              private httpReqService: HttpReqService,
              private userStateService: UserStateService) {
  }

  ngAfterViewInit() {
    if (this.source === 'song') {
      // const url = window.location.href.replace(/\/[^\/]*$/, '/?referenceSongIds=');
      // this.link = `${url}${this.link}&youtubeId=${this.youtubeId}`;
    }
  }

  share(shareTo: 'facebook' | 'twitter' | 'linkedin' | 'whatsapp') {
    const user = this.userStateService.user();
    this.httpReqService.sendEventToServer('SH_SOCIAL_SHARE_BUTTON', {
      'email': user ? user.email : 'no user',
      'url': this.link!,
      'destination': shareTo
    });

    this.socialSharingService.shareOnSocial(this.link!, shareTo, this.source);
  }

  copyToClipboard(copyLink = false) {
    const valueToCopy = copyLink ? this.link : this.source === 'song' ? this.songInfo : this.link;
    copyToClipboard(this.toast, valueToCopy!);
  }

  showShareTooltip() {
    if (!this.hideShareButton) {
      this.tippy?.show();
    }
  }

  hideTooltip(tooltip: TippyDirective) {
    tooltip.hide();
  }

  getCopyText() {
    if (this.source === 'song') {
      return `Copy ${this.songInfo} to clipboard`;
    } else {
      return `Copy ${this.source} to clipboard`;
    }
  }
}
