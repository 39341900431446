import {ElementRef, Injectable, Signal, signal} from '@angular/core';
import {FinalSong} from '../interfaces/final-song';
import {CuratedPlaylist} from '../interfaces/curated-playlist';
import {FeaturedArtist} from '../interfaces/featured-artist';
import {generateRandomString} from '../helper/random';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {
  playlistSongs = signal<Set<FinalSong>>(new Set());
  referenceSongFromUrl = signal<number | undefined>(undefined);
  highlightedSong = signal<FinalSong | undefined>(undefined);
  signUpSvg = signal<string | undefined>(undefined);

  showNoMoreResults = signal(false);
  noMoreResultsMessage = signal<string>(this.getDefaultNoMoreResultsMessage());
  showCuratedPlaylists = signal(false);
  featuredArtist = signal<FeaturedArtist | undefined>(undefined);
  curatedPlaylists = signal<CuratedPlaylist[] | undefined>(undefined);
  playAllSongs = signal(false);
  logoLocationLink = signal('');
  songs = signal<FinalSong[]>([]);
  initialLoadingProcessComplete = signal(false);
  showBigSearchHomePage = signal<boolean | undefined>(undefined);
  showBigSearchHomePageIsUndefined = signal(true);
  showPlaylist = signal(false);
  showAdminPanel = signal(false);
  showProfilePage = signal(false);
  songsLoading = signal(true);
  loadMoreSongsEvent = signal(false);
  scrollEventOnMobile = signal(false);
  filterWasClickedDontHideFilters = signal(false);
  discoveryMode = signal(false);
  appTheme = signal<'dark' | 'light'>('light');
  semanticsModel = signal<'SimCSE' | 'Roberta'>('SimCSE');
  resultItemWidth = signal(240);
  resultItemHeight = signal(0);

  errorRetrievingPlaylists = false;
  artistPages = -1;
  resultsPageNumber = 0;
  totalTimesNoSongsFound = 0;
  totalPlaylistSongs = 0;
  whiteLabelProjectIds: number[] = [];
  publicProjectIds: number[] = [];
  projectIdsToUse: string = '';
  navBar: ElementRef | undefined;

  private _updateGlobalStateSignal = signal(generateRandomString());

  get updateGlobalStateSignal(): Signal<string> {
    return this._updateGlobalStateSignal;
  }

  set updateGlobalStateSignal(_: string) {
    this._updateGlobalStateSignal.set(generateRandomString());
  }

  initAppTheme() {
    const theme = localStorage.getItem('theme');
    this.appTheme.set(
      localStorage['theme']
      || (window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
      )
    );
    if (theme) {
      localStorage.setItem('theme', this.appTheme());
    }
  }

  setShowBigSearchHomePage(value: boolean, caller: string) {
    this.showBigSearchHomePageIsUndefined.set(false);
    console.log(`setShowBigSearchHomePage invoked by: ${caller}`);
    this.showBigSearchHomePage.set(value);
  }

  setSongs(songs: FinalSong[]) {
    setTimeout(() => {
      if (this.resultsPageNumber === 0) {
        this.songs.set(songs); // Directly set new songs
      } else {
        this.songs.update((currentSongs) => [...currentSongs, ...songs]); // Append new songs
      }
    });
  }

  setSongsLyricsMatch(finalSongs: FinalSong[]) {
    this.songs.update(currentSongs =>
      currentSongs.map(currentSong => {
        // Find the corresponding song in finalSongs using the unique identifier (e.g., id)
        const matchingFinalSong = finalSongs.find(finalSong => finalSong.id === currentSong.id);

        // If a matching song is found, merge its properties with the current song
        return matchingFinalSong ? {...currentSong, lyricsMatch: matchingFinalSong.lyricsMatch} : currentSong;
      })
    );
  }

  addRemoveSongFromPlaylist(song: FinalSong) {
    let songAddedToPlaylist = false;
    const currentSongs = this.playlistSongs();
    if (currentSongs.has(song)) {
      currentSongs.delete(song);
    } else {
      currentSongs.add(song);
      songAddedToPlaylist = true;
    }
    this.playlistSongs.set(new Set(currentSongs)); // Correct way to update the signal
    this.totalPlaylistSongs = currentSongs.size;
    return songAddedToPlaylist;
  }

  addTopTwentySongsToPlaylist(): void {
    // Get the current songs and playlist songs
    const allSongs = this.songs(); // Correct way to get the signal value
    const playlistSongs = this.playlistSongs();

    // Create a set of IDs for songs already in the playlist for faster lookup
    const playlistSongIds = new Set([...playlistSongs].map(song => song.id));

    // Take the first 20 songs and filter out the ones already added
    const songsToAdd = allSongs.slice(0, 20).filter(song =>
      !song.songAddedToPlaylist && !playlistSongIds.has(song.id)
    );

    if (songsToAdd.length === 0) return;

    // Update `songs` signal by marking added songs
    this.songs.update(currentSongs =>
      currentSongs.map(song =>
        songsToAdd.some(s => s.id === song.id) ? {...song, songAddedToPlaylist: true} : song
      )
    );

    // Create a new Set to avoid mutating the existing one
    const updatedPlaylistSongs = new Set(playlistSongs);
    songsToAdd.forEach(song => updatedPlaylistSongs.add(song));

    // Update `playlistSongs$` with the new set
    this.playlistSongs.set(new Set(updatedPlaylistSongs));
  }

  getSongsForYoutube(): string[] {
    const currentSongs = this.playlistSongs();
    return Array.from(currentSongs).map(song => song.youtube_id);
  }

  getSongsForSpotify(): { artist: string, title: string }[] {
    const currentSongs = this.playlistSongs();
    return Array.from(currentSongs).map(song => ({artist: song.artist, title: song.title}));
  }

  addPageNumber() {
    this.resultsPageNumber++;
  }

  resetPageNumber() {
    this.totalTimesNoSongsFound = 0;
    this.resultsPageNumber = 0;
  }

  resetPlaylist() {
    this.playlistSongs.set(new Set());
  }

  getPageNumForCall(): string {
    return this.resultsPageNumber.toString();
  }

  removeSongAddedToPlaylist(song: FinalSong) {
    this.songs.update(currentSongs =>
      currentSongs.map(s =>
        s.youtube_id === song.youtube_id ? {...s, songAddedToPlaylist: false} : s
      )
    );
  }

  getDefaultNoMoreResultsMessage() {
    return 'Nothing to see here!<br>\n' +
      '          Your search seems to be too specific to return any results. Loosen a few search filters or read more about\n' +
      '          why this might happen\n' +
      '          <a class="underline hover:text-mypart_red-500" href="https://www.mypart.net/songhunt-faq"\n' +
      '             target="_blank">here.</a>';
  }

  getArtworkIdsFromPlaylist(): string[] {
    const songs = Array.from(this.playlistSongs()); // Convert the Set to an array
    return songs.map(song => song.id); // Replace 'id' with the appropriate property for artworkId
  }

  setSongsLoading(loading: boolean) {
    this.songsLoading.set(loading);
  }
}
