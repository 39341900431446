import {Directive, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ModalService} from '../../services/modal.service';
import {Modal} from 'flowbite';
import {ModalOptions} from 'flowbite/lib/esm/components/modal/types';

@UntilDestroy()
@Directive()
export abstract class BaseModalComponent {
  @Input() modalId!: string;
  isLoading = this.modalService.isLoading;
  @ViewChild('modal', {static: false, read: ElementRef}) protected modalElement?: ElementRef;
  protected modal?: Modal;
  protected canCloseWithEsc: boolean = true;
  private readonly ANIMATION_DURATION = 250;

  protected constructor(protected modalService: ModalService) {
  }

  @HostListener('document:keydown.escape')
  onEscapePress() {
    if (this.canCloseWithEsc) {
      this.hideModal();
    }
  }

  showModal(): void {
    this.modal?.show();
  }

  hideModal(): void {
    // First trigger our animation by removing from service
    this.modalService.close(this.modalId);

    // Wait for animation to complete before cleaning up Flowbite
    setTimeout(() => {
      if (this.modal) {
        this.modal.hide();
        this.modal.destroyAndRemoveInstance();
        this.modal = undefined;
      }

      // Clean up any remaining backdrop elements from Flowbite
      const backdropElements = document.querySelectorAll('[modal-backdrop]');
      backdropElements.forEach(el => el.remove());
    }, this.ANIMATION_DURATION);
  }

  protected initModal(options: ModalOptions = {
    backdrop: 'dynamic',
    closable: false
  }) {
    if (this.modalElement?.nativeElement) {
      this.modal = new Modal(this.modalElement.nativeElement, options);
    }
    this.setLoading(false);

    this.showModal();
  }

  protected setLoading(loading: boolean): void {
    this.modalService.setLoading(loading);
  }
} 
