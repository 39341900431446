import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'mypart-no-results',
  templateUrl: './no-results.component.html',
  styleUrl: './no-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NoResultsComponent extends BaseModalComponent {
  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }
}
