import {Injectable, signal} from '@angular/core';
import {FinalSong} from '../interfaces/final-song';

@Injectable({
  providedIn: 'root'
})
export class IFrameAudioPlayerStateService {
  selectedSongSignal = signal<FinalSong | undefined>(undefined);
  playingIframeId = signal<string | undefined>(undefined);
  playingAudioUrl = signal<string | undefined>(undefined);
  songPlayedFromPlaylist = signal(false);

  playingAudioElement: HTMLAudioElement | undefined = undefined;
  songPosition: number | undefined = undefined;

  setPlayingIframe(youtube_id: string | undefined) {
    this.playingAudioElement = undefined;
    this.playingIframeId.set(youtube_id);
    this.playingAudioUrl.set(undefined);
  }

  setPlayingAudio(url: string | undefined, audioElement: HTMLAudioElement | undefined): void {
    this.playingAudioElement = audioElement;
    this.playingIframeId.set(undefined);
    this.playingAudioUrl.set(url);
    this.songPlayedFromPlaylist.set(false);
  }

  setSongAndPosition(song: FinalSong | undefined, position: number | undefined) {
    this.selectedSongSignal.set(song);
    this.songPosition = position;
    this.songPlayedFromPlaylist.set(false);
  }

  playSongFromPlaylist(song: FinalSong): void {
    this.selectedSongSignal.set(song);
    this.songPlayedFromPlaylist.set(true);
  }
}
