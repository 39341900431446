import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {UserStateService} from '../user-state.service';

@Injectable({
  providedIn: 'root'
})
export class SpotifyTokensService {
  readonly accessTokenAddress = 'spotifyAccessToken';
  readonly refreshTokenAddress = 'spotifyRefreshToken';
  readonly codeVerifierAddress = 'spotifyCodeVerifier';
  readonly isSpotifyConnectedAddress = 'isSpotifyConnected';
  readonly clientIdAddress = 'spotifyClientId';
  readonly connectedUserEmailAddress = 'spotifyUserEmailAddress';

  constructor(private userStateService: UserStateService) {
  }

  clearCredentials(): void {
    localStorage.removeItem(this.accessTokenAddress);
    localStorage.removeItem(this.refreshTokenAddress);
    localStorage.removeItem(this.codeVerifierAddress);
    localStorage.removeItem(this.isSpotifyConnectedAddress);
    localStorage.removeItem(this.clientIdAddress);
    localStorage.removeItem(this.connectedUserEmailAddress);

    const user = this.userStateService.user();
    if (user) {
      user.isSpotifyConnected = false;
      this.userStateService.setUser(user);
    }
  }

  setRefreshToken(refreshToken: string) {
    if (localStorage.getItem(this.isSpotifyConnectedAddress)) {
      localStorage.setItem(this.refreshTokenAddress, refreshToken);
    } else {
      environment.spotifyRefreshToken = refreshToken;
    }
  }

  setUserId(clientId: string) {
    localStorage.setItem(this.clientIdAddress, clientId);
  }

  setIsSpotifyConnected() {
    localStorage.setItem(this.isSpotifyConnectedAddress, 'true');
  }

  setTokens(accessToken: string, refreshToken: string, email: string) {
    localStorage.setItem(this.accessTokenAddress, accessToken);
    localStorage.setItem(this.refreshTokenAddress, refreshToken);
    localStorage.setItem(this.connectedUserEmailAddress, email);
  }

  setCodeVerifier(codeVerifier: string) {
    localStorage.setItem(this.codeVerifierAddress, codeVerifier);
  }

  getAccessToken() {
    return localStorage.getItem(this.accessTokenAddress)!;
  }

  getCodeVerifierAddress() {
    return localStorage.getItem(this.codeVerifierAddress)!;
  }

  getConnectedUserEmailAddress() {
    return localStorage.getItem(this.connectedUserEmailAddress)!;
  }

  getRefreshToken() {
    if (this.getIsSpotifyConnected() && this.userStateService.user()?.isSubscribed) {
      return localStorage.getItem(this.refreshTokenAddress)!;
    } else {
      return environment.spotifyRefreshToken;
    }
  }

  getUserId() {
    if (this.getIsSpotifyConnected() && this.userStateService.user()?.isSubscribed) {
      return localStorage.getItem(this.clientIdAddress)!;
    } else {
      return environment.spotifyDefaultUserId;
    }
  }

  getIsSpotifyConnected() {
    const value = localStorage.getItem(this.isSpotifyConnectedAddress);
    return value === 'true';
  }
}
