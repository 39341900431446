import {AfterViewInit, ChangeDetectionStrategy, Component, effect, signal} from '@angular/core';
import {IFrameAudioPlayerStateService} from '../../../services/i-frame-audio-player-state.service';
import {FinalSong} from '../../../interfaces/final-song';
import {GlobalStateService} from '../../../services/global-state.service';
import {popupWindow} from '../../../helper/window.helper';
import {HttpReqService} from '../../../services/http-req.service';
import {MobileDeviceService} from '../../../services/mobile-device.service';
import {UserStateService} from '../../../services/user-state.service';
import {ToastService} from '../../../services/toast.service';
import {ModalType} from '../../../interfaces/modal-types.enum';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'mypart-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerComponent implements AfterViewInit {
  showPlayer = false;
  selectedSongSignal = this.iFrameAudioPlayerStateService.selectedSongSignal;
  isMobileDevice = this.mobileDeviceService.isMobileDevice;
  playAllSongs = this.globalStateService.playAllSongs;

  selectedSong = signal<FinalSong | undefined>(undefined);
  firstSongId = '';
  lastSongId = '';
  isApiReady = false;
  isSongPlaying = signal(false);
  showControls = signal(false);

  audioElement: HTMLAudioElement | undefined;
  isThisIphone = this.isIphone();
  player: any;
  initializationInProgress = false;
  // Progress tracking signals
  progress = signal(0);
  currentTime = signal('0:00');
  duration = signal('0:00');
  bufferProgress = signal(0);
  previewTime = signal('');
  previewPosition = signal(0);
  private YT: any;
  private video: any;
  private counter = 0;
  private debounceTimer: ReturnType<typeof setTimeout> | null = null;
  private songsWereAddedToPlaylist = false;
  private songsToAddToPlaylist: FinalSong[] = [];
  private progressInterval: ReturnType<typeof setInterval> | null = null;

  constructor(private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService,
              private globalStateService: GlobalStateService,
              private modalService: ModalService,
              private mobileDeviceService: MobileDeviceService,
              private userStateService: UserStateService,
              private httpReqService: HttpReqService,
              private toast: ToastService) {
    effect(() => {
      // Read the new song from the service signal.
      const newSong = this.iFrameAudioPlayerStateService.selectedSongSignal();

      // Clear any pending debounce timer.
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      // Set a new debounce timer (150ms).
      this.debounceTimer = setTimeout(() => {
        // Implement distinctUntilChanged: if the new song is the same as the current, do nothing.
        if (this.selectedSong()?.id === newSong?.id) {
          return;
        }

        // If there is a new song, send an event to the server.
        if (newSong) {
          this.checkUserPlaylistEngagement(newSong);

          const user = this.userStateService.user();
          this.httpReqService.sendEventToServer('SH_SONG_PLAY', {
            email: user ? user.email : 'no user',
            artworkId: newSong.id,
            url: window.location.href,
            songIndex: this.getSongIndexById(newSong.id)
          });

          this.destroyPlayer();
          this.showControls.set(false);
          this.selectedSong.set(newSong);
          this.showPlayer = !!newSong;

          if (this.iFrameAudioPlayerStateService.playingIframeId()) {
            this.stopAudioElementListeners();
            if (!this.isApiReady) {
              this.initYoutubeApi();
            }
            if (this.isApiReady) {
              setTimeout(() => {
                this.startVideoInit();
              }, 100);
              return;
            }
          }
          if (this.iFrameAudioPlayerStateService.playingAudioUrl()) {
            this.showControls.set(true);
            this.initAudioElement();
          }
        }
      }, 150);
    });

    effect(() => {
      const songs = this.globalStateService.songs(); // Fetch once
      if (songs.length > 0) {
        this.firstSongId = songs[0].id;
        this.lastSongId = songs[songs.length - 1].id;
      }
    });

    effect(() => {
      const songs = this.globalStateService.songs(); // Fetch once

      if (this.playAllSongs() && !this.selectedSong() && songs.length > 0) {
        this.playAllSongsFromTheBeginning();
      }
    });
  }

  ngAfterViewInit() {
    if (window['YT'] && window['YT'].Player) {
      // The YouTube API is already loaded
      this.isApiReady = true;
    } else {
      // Set up the onYouTubeIframeAPIReady callback
      window['onYouTubeIframeAPIReady'] = () => {
        this.isApiReady = true;
      };
    }
  }

  playVideo() {
    if (!this.showControls()) {
      return;
    }
    if (this.player) {
      this.player.playVideo();
      return;
    }
    if (this.audioElement) {
      this.audioElement.play().then();
    }
  }

  pauseVideo() {
    if (!this.showControls()) {
      return;
    }
    if (this.player) {
      this.player.pauseVideo();
      return;
    }
    if (this.audioElement) {
      this.audioElement.pause();
    }
  }

  returnToSongLocation() {
    setTimeout(() => {
      const results = document.getElementById('results-scroll-container')!;
      results.scrollTo({
        top: this.iFrameAudioPlayerStateService.songPosition! - this.globalStateService.navBar?.nativeElement.offsetHeight - 10,
        behavior: 'smooth'
      });
    }, 0);
  }

  openLyrics() {
    const selectedSong = this.selectedSong();
    if (selectedSong) {
      if (selectedSong.lyrics && this.userStateService.isUserWhiteLabel()) {
        this.modalService.open(ModalType.LYRICS, {
          inputs: {
            title: selectedSong.lyrics,
            artist: selectedSong.artist,
            lyrics: selectedSong.title
          }
        });
      } else {
        const query = encodeURIComponent(`${selectedSong.artist} - ${selectedSong.title} lyrics`);
        const url = `https://www.google.com/search?q=${query}`;
        popupWindow(url, 'MyPart Songhunt', 640, 480);
      }
    }
  }

  togglePlayAllSongs() {
    if (this.playAllSongs()) {
      this.playAllSongs.set(false);
      this.toast.info('Play all songs is not active');
    } else {
      this.playAllSongs.set(true);
      this.toast.info('Play all songs is active');
    }
  }

  playPreviousSong() {
    if (!this.showControls()) {
      return;
    }
    const currentItemResult = this.getCurrentResultItemContainer(this.selectedSong()?.youtube_id);
    const previousItemResult = this.getPreviousResultItemContainer(currentItemResult);
    const youtubeContainer = this.getPreviousYoutubeContainer(previousItemResult);
    if (youtubeContainer) {
      youtubeContainer.click();
      this.returnToSongLocation();
    }
  }

  playNextSong() {
    if (!this.showControls()) {
      return;
    }
    const currentItemResult = this.getCurrentResultItemContainer(this.selectedSong()?.youtube_id);
    const nextItemResult = this.getNextResultItemContainer(currentItemResult);
    const youtubeContainer = this.getNextYoutubeContainer(nextItemResult);
    if (youtubeContainer) {
      youtubeContainer.click();
      this.returnToSongLocation();
    }
  }

  seekTo(event: MouseEvent) {
    if (!this.showControls()) {
      return;
    }

    try {
      const progressBar = event.currentTarget as HTMLElement;
      const rect = progressBar.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      const percentage = (x / rect.width) * 100;

      // Update visual progress immediately for better UX
      this.progress.set(percentage);

      if (this.player && typeof this.player.getDuration === 'function') {
        const duration = this.player.getDuration();
        const seekTime = (duration * percentage) / 100;
        this.player.seekTo(seekTime, true);
      } else if (this.audioElement) {
        const duration = this.audioElement.duration;
        if (!isNaN(duration)) {
          this.audioElement.currentTime = (duration * percentage) / 100;
        }
      }
    } catch (error) {
      console.error('Error seeking:', error);
      this.toast.error('Failed to seek to position');
    }
  }

  showPreviewTime(event: MouseEvent) {
    if (!this.showControls()) {
      return;
    }

    try {
      const progressBar = event.currentTarget as HTMLElement;
      const rect = progressBar.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      const percentage = (x / rect.width) * 100;

      let duration = 0;
      if (this.player && typeof this.player.getDuration === 'function') {
        duration = this.player.getDuration();
      } else if (this.audioElement) {
        duration = this.audioElement.duration;
      }

      if (duration) {
        const previewSeconds = (duration * percentage) / 100;
        this.previewTime.set(this.formatTime(previewSeconds));
        this.previewPosition.set(percentage);
      }
    } catch (error) {
      console.error('Error showing preview time:', error);
    }
  }

  hidePreviewTime() {
    this.previewTime.set('');
  }

  private isIphone() {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }

  private isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  private playAllSongsFromTheBeginning() {
    // For iOS devices, we need user interaction first
    if (this.isThisIphone) {
      this.toast.info('On iOS devices, please tap the song you want to start with first');
      return;
    }

    // For Safari, warn about potential autoplay restrictions
    if (this.isSafari()) {
      this.toast.info('Safari may restrict automatic playback. Click any song to start playing.');
    }

    const currentItemResult = this.getFirstResultItem();
    const youtubeContainer = this.getNextYoutubeContainer(currentItemResult);
    if (youtubeContainer) {
      youtubeContainer.click();
      this.returnToSongLocation();
    }
  }

  private onPlayerStateChange(event: { data: number }) {
    switch (event.data) {
      case -1: // (unstarted)
        // On Safari/iOS, we might need to attempt playback again
        if ((this.isThisIphone || this.isSafari()) && this.playAllSongs()) {
          setTimeout(() => {
            this.playVideo();
          }, 1000);
        }
        break;
      case 0: // (ended)
        this.isSongPlaying.set(false);
        if (this.playAllSongs()) {
          // Add delay for Safari/iOS to handle state changes
          setTimeout(() => {
            this.playNextSong();
          }, 500);
        }
        break;
      case 1: // (playing)
        this.isSongPlaying.set(true);
        break;
      case 2: // (paused)
        this.isSongPlaying.set(false);
        break;
      case 3: // (buffering)
        break;
      case 5: // (video cued)
        // On Safari/iOS, we might need to attempt playback
        if ((this.isThisIphone || this.isSafari()) && this.playAllSongs()) {
          setTimeout(() => {
            this.playVideo();
          }, 1000);
        }
        break;
    }
  }

  private onError(event: any) {
    const errorCode = event?.data;
    let errorMessage = 'An error occurred while playing the video.';

    // Handle specific YouTube player error codes
    switch (errorCode) {
      case 2:
        errorMessage = 'Invalid video ID or parameter.';
        break;
      case 5:
        errorMessage = 'HTML5 player error.';
        break;
      case 100:
        errorMessage = 'Video not found or removed.';
        break;
      case 101:
      case 150:
        errorMessage = 'Video playback not allowed.';
        break;
    }

    // Log error and notify user
    this.httpReqService.sendEventToServer('INVALID_YOUTUBE_LINK', {
      songId: this.selectedSong()?.id,
      errorCode,
      errorMessage
    });

    this.toast.error(errorMessage);
    this.destroyPlayer();

    // Auto-play next song if playAllSongs is enabled
    if (this.playAllSongs()) {
      // Add delay for Safari/iOS to handle state changes
      setTimeout(() => {
        this.playNextSong();
      }, 500);
    }
  }

  private startVideoInit() {
    // Ensure we don't have race conditions with multiple init calls
    if (this.initializationInProgress) {
      return;
    }

    this.initializationInProgress = true;

    try {
      this.destroyPlayer();

      this.YT = window['YT'];
      this.video = (<any>document).getElementById(this.selectedSong()?.youtube_id);

      if (!this.video) {
        throw new Error('Video element not found');
      }

      // Configure player with platform-specific settings
      const playerVars: any = {
        'autoplay': 1,
        'playsinline': 1,
        'origin': window.location.origin
      };

      // For iOS devices, ensure playsinline is enabled
      if (this.isThisIphone) {
        playerVars.playsinline = 1;
        playerVars.controls = 1; // Show native controls for better iOS compatibility
      }

      this.player = new this.YT.Player(this.video, {
        videoId: this.selectedSong()?.youtube_id,
        playerVars,
        events: {
          'onReady': this.onPlayerReady.bind(this),
          'onStateChange': this.onPlayerStateChange.bind(this),
          'onError': this.onError.bind(this)
        }
      });
    } catch (error) {
      this.toast.error('Failed to initialize video player');
      console.error('Video initialization error:', error);
    } finally {
      this.initializationInProgress = false;
    }
  }

  private onPlayerReady(event: any) {
    // Ensure player is ready before attempting playback
    if (event?.target?.playVideo) {
      setTimeout(() => {
        this.playVideo();
      }, 100);
      this.showControls.set(true);
      this.isSongPlaying.set(true);
      this.startProgressTracking();
    } else {
      this.toast.error('Player failed to initialize properly');
    }
  }

  private destroyPlayer() {
    try {
      if (this.player) {
        // Remove event listeners first
        this.player.removeEventListener('onReady');
        this.player.removeEventListener('onStateChange');
        this.player.removeEventListener('onError');

        // Stop and destroy the player
        this.player.stopVideo();
        this.player.destroy();
        this.player = null;
      }
    } catch (error) {
      console.error('Error destroying player:', error);
    } finally {
      // Reset state regardless of success/failure
      this.showControls.set(false);
      this.isSongPlaying.set(false);
      this.stopProgressTracking();
    }
  }

  private initAudioElement(): void {
    try {
      this.audioElement = this.iFrameAudioPlayerStateService.playingAudioElement;

      if (!this.audioElement) {
        throw new Error('Audio element not initialized');
      }

      this.startAudioElementListeners();
      this.showControls.set(true);
      this.startProgressTracking();
    } catch (error) {
      this.toast.error('Failed to initialize audio player');
      console.error('Audio initialization error:', error);
    }
  }

  private updateProgress() {
    try {
      if (this.player && typeof this.player.getCurrentTime === 'function') {
        const current = this.player.getCurrentTime() || 0;
        const total = this.player.getDuration() || 0;
        const loaded = this.player.getVideoLoadedFraction() || 0;

        this.progress.set((current / total) * 100);
        this.currentTime.set(this.formatTime(current));
        this.duration.set(this.formatTime(total));
        this.bufferProgress.set(loaded * 100);
      } else if (this.audioElement) {
        const current = this.audioElement.currentTime;
        const total = this.audioElement.duration;
        const loaded = this.audioElement.buffered.length
          ? (this.audioElement.buffered.end(this.audioElement.buffered.length - 1) / total) * 100
          : 0;

        this.progress.set((current / total) * 100);
        this.currentTime.set(this.formatTime(current));
        this.duration.set(this.formatTime(total));
        this.bufferProgress.set(loaded);
      }
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  }

  private formatTime(seconds: number): string {
    if (!seconds || isNaN(seconds)) return '0:00';

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  private startProgressTracking() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }

    this.progressInterval = setInterval(() => {
      this.updateProgress();
    }, 1000);
  }

  private stopProgressTracking() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  private getCurrentResultItemContainer(youtube_id: string | undefined) {
    return document.querySelector(`[data-result-item='${youtube_id}']`) as HTMLElement | null;
  }

  private getPreviousResultItemContainer(currentContainer: HTMLElement | null) {
    let previousContainer = currentContainer?.previousElementSibling;
    // Optionally check if the previousContainer is a result item container
    while (previousContainer && !previousContainer.matches('[data-result-item]')) {
      previousContainer = previousContainer.previousElementSibling;
    }

    return previousContainer as HTMLElement | null; // Return the previous container or null if none is found
  }


  private getNextResultItemContainer(currentContainer: HTMLElement | null) {
    let nextContainer = currentContainer?.nextElementSibling;
    // Optionally check if the nextContainer is a result item container
    while (nextContainer && !nextContainer.matches('[data-result-item]')) {
      nextContainer = nextContainer.nextElementSibling;
    }

    return nextContainer as HTMLElement | null; // Return the next container or null if none is found
  }

  private getPreviousYoutubeContainer(container: any | null) {
    return container?.firstChild?.firstChild?.children[1].lastChild;
  }

  private getNextYoutubeContainer(container: any | null) {
    return container?.firstChild?.firstChild?.children[1].firstChild;
  }

  private getFirstResultItem() {
    return document.querySelector('[data-result-item]') as HTMLElement | null;
  }

  private handleIphone() {
    this.counter++;
    if (this.counter === 2) {
      const currentItemResult = this.getCurrentResultItemContainer(this.selectedSong()?.youtube_id);
      const youtubeContainer = this.getNextYoutubeContainer(currentItemResult);
      if (youtubeContainer) {
        youtubeContainer.click();
      }
    }
  }

  private startAudioElementListeners() {
    if (this.audioElement) {
      this.audioElement.addEventListener('play', this.updatePlayerState.bind(this));
      this.audioElement.addEventListener('pause', this.updatePlayerState.bind(this));
      this.audioElement.addEventListener('ended', this.updatePlayerState.bind(this));
      this.audioElement.addEventListener('timeupdate', this.updatePlayerState.bind(this));
    }
  }

  private stopAudioElementListeners() {
    if (this.audioElement) {
      this.audioElement.removeEventListener('play', this.updatePlayerState.bind(this));
      this.audioElement.removeEventListener('pause', this.updatePlayerState.bind(this));
      this.audioElement.removeEventListener('ended', this.updatePlayerState.bind(this));
      this.audioElement.removeEventListener('timeupdate', this.updatePlayerState.bind(this));
    }
  }

  private updatePlayerState() {
    try {
      if (this.audioElement) {
        const isPlaying = !this.audioElement.paused &&
          !this.audioElement.ended &&
          this.audioElement.currentTime > 0 &&
          !this.audioElement.error;

        this.isSongPlaying.set(isPlaying);

        // Handle audio element errors
        if (this.audioElement.error) {
          const errorMessage = `Audio playback error: ${this.audioElement.error.message}`;
          this.toast.error(errorMessage);

          if (this.playAllSongs()) {
            this.playNextSong();
          }
        }
      }
    } catch (error) {
      console.error('Error updating player state:', error);
    }
  }

  private getSongIndexById(selectedSongId: string): number {
    const songs = this.globalStateService.songs(); // Get the current value from the signal
    return songs.findIndex(song => song.id === selectedSongId);
  }

  private checkUserPlaylistEngagement(newSong: FinalSong) {
    if (!this.songsWereAddedToPlaylist && this.globalStateService.playlistSongs().size === 0) {
      this.songsToAddToPlaylist.push(newSong);

      if (this.songsToAddToPlaylist.length === 5) {
        this.songsWereAddedToPlaylist = true;
        this.songsToAddToPlaylist.forEach(song => {
          this.globalStateService.addRemoveSongFromPlaylist(song);
          this.toast.info('We added the last 5 songs you played to your playlist. Export it to Spotify or keep exploring your song recommendations!', 8000);
          this.globalStateService.showPlaylist.set(true);
          if (this.isMobileDevice()) {
            setTimeout(() => {
              this.globalStateService.showPlaylist.set(false);
            }, 2000);
          }
        });
      }
    }
  }

  private initYoutubeApi() {
    try {
      if (window['YT'] && window['YT'].Player) {
        // The YouTube API is already loaded
        this.isApiReady = true;
      } else {
        // Load the YouTube IFrame API if not already loaded
        if (!document.getElementById('youtube-api')) {
          const tag = document.createElement('script');
          tag.id = 'youtube-api';
          tag.src = 'https://www.youtube.com/iframe_api';
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
        }

        // Set up the onYouTubeIframeAPIReady callback
        window['onYouTubeIframeAPIReady'] = () => {
          this.isApiReady = true;
          // If we have a pending video initialization, start it now
          if (this.iFrameAudioPlayerStateService.playingIframeId()) {
            this.startVideoInit();
          }
        };
      }
    } catch (error) {
      console.error('Error initializing YouTube API:', error);
      this.toast.error('Failed to initialize YouTube player');
    }
  }
}
