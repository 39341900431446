<section #tooltip="tippy" (click)="showShareTooltip()" [class.affiliation-popup]="hideShareButton" [tp]="tpl"
         class="share-container w-7"
         tpClassName="social-popper"
         tpTrigger="manual" tpVariation="popper">
  @if (hideShareButton) {
    <div class="flex flex-row w-full h-full p-2 gap-2.5 icons-container !justify-evenly rounded-lg bg-transparent">
      <div class="twitter" title="Share {{source}} on X (Twitter)">
        <svg (click)="share('twitter')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
             viewBox="0 0 24 24">
          <g>
            <path
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
          </g>
        </svg>
      </div>

      <div class="facebook" title="Share {{source}} on Facebook">
        <svg (click)="share('facebook')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
             viewBox="0 0 8 19" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                fill-rule="evenodd"/>
        </svg>
      </div>

      <div class="linkedin" title="Share {{source}} on Linkedin">
        <svg (click)="share('linkedin')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
             viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                fill-rule="evenodd"/>
          <path d="M3 5.012H0V15h3V5.012Z"/>
        </svg>
      </div>

      <div class="whatsapp" title="Share {{source}} on WhatsApp">
        <svg (click)="share('whatsapp')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001
          6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893
           11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448
           0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651
           3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941
            1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198
             0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213
             3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
        </svg>
      </div>

      @if (source !== 'playlist') {
        <div class="bg-gray-800" [title]="getCopyText()">
          <svg (click)="copyToClipboard()" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
               viewBox="0 0 18 20"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 9V4.13a2.96 2.96 0 0 0-1.293.749L.879 7.707A2.96 2.96 0 0 0 .13 9H5Zm11.066-9H9.829a2.98 2.98 0 0 0-2.122.879L7 1.584A.987.987 0 0 0 6.766 2h4.3A3.972 3.972 0 0 1 15 6v10h1.066A1.97 1.97 0 0 0 18 14V2a1.97 1.97 0 0 0-1.934-2Z"></path>
            <path
                d="M11.066 4H7v5a2 2 0 0 1-2 2H0v7a1.969 1.969 0 0 0 1.933 2h9.133A1.97 1.97 0 0 0 13 18V6a1.97 1.97 0 0 0-1.934-2Z"></path>
          </svg>
        </div>
      }

      @if (source === 'song') {
        <div class="relative w-6 h-6 bg-black rounded-full cursor-pointer" title="Copy song link to clipboard"
             (click)="copyToClipboard(true)">
          <img imageLoader ngSrc="assets/sh.png" alt="Songhunt logo" priority class="sh-logo" fill>
        </div>
      }
    </div>
  } @else {
    @if (source !== 'playlist') {
      <button [tp]="isMobileDevice() || !showTooltip ? null : 'Share ' + source"
              class="flex relative z-10 bg-transparent p-2 {{shareColor}}"
              type="button">
        <svg aria-hidden="true"
             class="w-7 h-6 hover:drop-shadow-plus-light hover:dark:drop-shadow-plus-dark" fill="currentColor"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M16.922 11.76a1.56 1.56 0 0 0-.551-1.208L11.264 6.3a1.35 1.35 0 0 0-1.473-.2 1.542 1.542 0 0 0-.872 1.427v1.221a6.922 6.922 0 0 0-6 7.134v1.33A1.225 1.225 0 0 0 4.143 18.5a1.187 1.187 0 0 0 1.08-.73 4.72 4.72 0 0 1 3.7-2.868v1.085a1.546 1.546 0 0 0 .872 1.428 1.355 1.355 0 0 0 1.472-.2l5.108-4.25a1.56 1.56 0 0 0 .547-1.206Z"/>
          <path
              d="m21.428 10.205-5.517-4.949a1 1 0 1 0-1.336 1.488l5.517 5.014-5.611 5.088a1 1 0 1 0 1.344 1.482l5.611-5.088a2.049 2.049 0 0 0-.008-3.035Z"/>
        </svg>
      </button>
    } @else {
      <button [tp]="isMobileDevice() ? null : 'Share ' + source"
              class="flex relative z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4
            focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm !px-5 !py-2.5
            text-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700
            dark:text-white dark:hover:bg-gray-600 ml-3"
              type="button">
        <svg aria-hidden="true"
             class="w-6 h-5 mr-2 -ml-1" fill="currentColor"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M16.922 11.76a1.56 1.56 0 0 0-.551-1.208L11.264 6.3a1.35 1.35 0 0 0-1.473-.2 1.542 1.542 0 0 0-.872 1.427v1.221a6.922 6.922 0 0 0-6 7.134v1.33A1.225 1.225 0 0 0 4.143 18.5a1.187 1.187 0 0 0 1.08-.73 4.72 4.72 0 0 1 3.7-2.868v1.085a1.546 1.546 0 0 0 .872 1.428 1.355 1.355 0 0 0 1.472-.2l5.108-4.25a1.56 1.56 0 0 0 .547-1.206Z"/>
          <path
              d="m21.428 10.205-5.517-4.949a1 1 0 1 0-1.336 1.488l5.517 5.014-5.611 5.088a1 1 0 1 0 1.344 1.482l5.611-5.088a2.049 2.049 0 0 0-.008-3.035Z"/>
        </svg>
        <span>Share</span>
      </button>
    }
  }
</section>

<ng-template #tpl>
  <div (click)="hideTooltip(tooltip)"
       class="flex flex-row w-full h-full p-2 gap-2.5 icons-container rounded-lg bg-transparent">
    <div class="twitter" title="Share {{source}} on X (Twitter)">
      <svg (click)="share('twitter')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
           viewBox="0 0 24 24">
        <g>
          <path
              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
        </g>
      </svg>
    </div>

    <div class="facebook" title="Share {{source}} on Facebook">
      <svg (click)="share('facebook')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
           viewBox="0 0 8 19" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
              fill-rule="evenodd"/>
      </svg>
    </div>

    <div class="linkedin" title="Share {{source}} on Linkedin">
      <svg (click)="share('linkedin')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
           viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
              fill-rule="evenodd"/>
        <path d="M3 5.012H0V15h3V5.012Z"/>
      </svg>
    </div>

    <div class="whatsapp" title="Share {{source}} on WhatsApp">
      <svg (click)="share('whatsapp')" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001
          6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893
           11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448
           0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651
           3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941
            1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198
             0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213
             3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
      </svg>
    </div>

    @if (source !== 'playlist') {
      <div class="bg-gray-800" [title]="getCopyText()">
        <svg (click)="copyToClipboard()" aria-hidden="true" class="w-6 h-6 text-white" fill="currentColor"
             viewBox="0 0 18 20"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5 9V4.13a2.96 2.96 0 0 0-1.293.749L.879 7.707A2.96 2.96 0 0 0 .13 9H5Zm11.066-9H9.829a2.98 2.98 0 0 0-2.122.879L7 1.584A.987.987 0 0 0 6.766 2h4.3A3.972 3.972 0 0 1 15 6v10h1.066A1.97 1.97 0 0 0 18 14V2a1.97 1.97 0 0 0-1.934-2Z"></path>
          <path
              d="M11.066 4H7v5a2 2 0 0 1-2 2H0v7a1.969 1.969 0 0 0 1.933 2h9.133A1.97 1.97 0 0 0 13 18V6a1.97 1.97 0 0 0-1.934-2Z"></path>
        </svg>
      </div>
    }

    @if (source === 'song') {
      <div class="relative w-6 h-6 bg-black rounded-full cursor-pointer" title="Copy song link to clipboard"
           (click)="copyToClipboard(true)">
        <img imageLoader ngSrc="assets/sh.png" alt="Songhunt logo" priority class="sh-logo" fill>
      </div>
    }
  </div>
</ng-template>
