import {AfterViewInit, Component} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'mypart-filter-overload',
  templateUrl: './filter-overload.component.html',
  styleUrls: ['./filter-overload.component.scss'],
  standalone: false
})
export class FilterOverloadComponent extends BaseModalComponent implements AfterViewInit {
  dontShowAgain: boolean = false;

  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }

  override hideModal() {
    super.hideModal();
    if (this.dontShowAgain) {
      localStorage.setItem('dont_show_filter_overload', 'true');
    }
  }
}
