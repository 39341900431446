import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  EnvironmentInjector,
  inject,
  Input,
  runInInjectionContext,
  signal
} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {GlobalStateService} from '../../../services/global-state.service';
import {FormControl, Validators} from '@angular/forms';
import {HttpReqService} from '../../../services/http-req.service';
import {switchMap} from 'rxjs/operators';
import {tap} from 'rxjs';
import {UserStateService} from '../../../services/user-state.service';
import {User} from '../../../interfaces/user';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Coupon} from '../../../interfaces/coupon';
import {ToastService} from '../../../services/toast.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';
import {pixelateInOut} from '../../../animations/pixelateInOut';
import {animate, style, transition, trigger} from '@angular/animations';
import {getAffiliateCode, removeAffiliateCode, setAffiliateCode} from '../../../helper/affiliation-storage';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  animations: [
    pixelateInOut,
    trigger('slideOut', [
      transition(':leave', [
        animate('0.3s ease-out',
          style({
            opacity: 0,
          })
        )
      ])
    ])
  ]
})
export class LoginRegisterComponent extends BaseModalComponent implements AfterViewInit {
  @Input() loginPopupTitle: string = 'Sign Up';
  @Input() onlyExplorer: boolean = false;
  @Input() onlyHunter: boolean = false;
  @Input() hideCloseButton: boolean = false;
  @Input() activateHardRefresh: boolean = false;
  @Input() promotionCodeFromUrl: string = '';
  @Input() affiliateCode: string = '';
  @Input() loginClicked: boolean = false;

  modalState: 'login' | 'register' = 'login';
  isUpgradeMode: boolean = false;

  loginEmail = new FormControl('', [Validators.required, Validators.email]);
  loginPassword = new FormControl('', Validators.required);
  promotionCode = new FormControl('');
  loginShowPassword = false;
  selectedPlan!: 'free' | 'paid';
  showPasswordPopover = false;

  regName = new FormControl('', Validators.required);
  regEmail = new FormControl('', [Validators.required, Validators.email]);
  // With password pattern
  // regPassword = new FormControl('', [
  //   Validators.required,
  //   Validators.minLength(6),
  //   Validators.pattern('^(?=.*[a-z])(?=.*\\d).+$')
  // ]);

  regPassword = new FormControl('', [Validators.required]);
  regShowPassword = false;
  isPaidHovered = true;
  isFreeHovered = false;
  hasAffiliateCode = false;
  coupon = signal<Coupon | null>(null);
  user = this.userStateService.user;

  isCurrentlyLoading = signal(false);
  whiteLabelName = signal<string | undefined>(undefined);
  isWhiteLabel = signal(false);
  isLocalHost = signal(false);
  svgContent = signal<SafeHtml | undefined>(undefined);
  priceAfterDiscount = signal<string | undefined>(undefined);
  currentStep: 1 | 2 = 1;
  selectedPackage: 'free' | 'paid' | null = null;
  private isVipUser = false;
  private readonly environmentInjector = inject(EnvironmentInjector); // ✅ Get injector

  constructor(private globalStateService: GlobalStateService,
              private httpReqService: HttpReqService,
              private userStateService: UserStateService,
              private cdr: ChangeDetectorRef,
              private toast: ToastService,
              private sanitizer: DomSanitizer,
              modalService: ModalService) {
    super(modalService);
    const domain = location.host.split('.')[0];
    effect(() => {
      this.isWhiteLabel.set(domain !== 'www' && !domain.includes('localhost:') && !domain.includes('dev'));
      this.whiteLabelName.set(domain);
      this.isLocalHost.set(location.href.includes('localhost') || location.href.includes('dev.mypart.com/'));
      this.isCurrentlyLoading.set(true);
    });

    effect(() => {
      const user = this.user();
      if (user?.isSubscribed) {
        this.hideModal();
      }
    });
  }

  get passwordHasMinLength(): boolean {
    return (this.regPassword.value || '').length >= 6;
  }

  get passwordHasNumber(): boolean {
    return /\d/.test(this.regPassword.value || '');
  }

  get passwordHasLowercase(): boolean {
    return /[a-z]/.test(this.regPassword.value || '');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    runInInjectionContext(this.environmentInjector, () => {
      effect(
        () => {
          const user = this.user();
          this.modalState = this.loginClicked ? 'login' : 'register';
          if (this.activateHardRefresh) {
            this.modalState = 'login';
          }
          this.isUpgradeMode = !!(user && !user.isSubscribed);
          if (this.isUpgradeMode) {
            this.modalState = 'register';
          }

          if (this.isUpgradeMode) {
            this.loginPopupTitle = 'Upgrade';
          }

          let timeout = this.modalState === 'register' ? 500 : 0;
          setTimeout(() => {
            this.isCurrentlyLoading.set(false);
          }, timeout);
          if (this.promotionCodeFromUrl !== '') {
            this.promotionCode.setValue(this.promotionCodeFromUrl);
            this.searchPromotionCode();
            setTimeout(() => {
              this.isCurrentlyLoading.set(true);
              this.modalState = 'register';
              this.selectedPlan = 'paid';
              this.toggleHover(this.selectedPlan);
            }, 300);
          }

          const promotionCodeFromStorage = getAffiliateCode();
          if (promotionCodeFromStorage) {
            this.affiliateCode = promotionCodeFromStorage;
          }

          if (this.affiliateCode) {
            setAffiliateCode(this.affiliateCode);
          }

          const isInvited = this.user()?.isInvited;
          if (isInvited || this.affiliateCode !== '') {
            this.modalState = 'register';
            this.isCurrentlyLoading.set(true);
            this.httpReqService.getPromoCodeFromRefCode(this.affiliateCode, isInvited)
              .then((response) => {
                this.hasAffiliateCode = true;
                this.coupon.set(response);
                this.isPaidHovered = true;
                this.isFreeHovered = false;
                this.handleCoupon(true);
                this.loginPopupTitle = 'You have been invited to Songhunt.ai';
              });
          }

          const svg = this.globalStateService.signUpSvg();
          if (svg) {
            this.svgContent.set(this.sanitizer.bypassSecurityTrustHtml(svg));
          }

          this.selectPlan(this.onlyHunter ? 'paid' : 'free');
          this.toggleHover(this.selectedPlan);

          this.initModal({
            backdrop: 'dynamic',
            closable: false
          });

          if (this.isUpgradeMode) {
            this.regName.setValue(this.user()!.name);
            this.regEmail.setValue(this.user()!.email);
          }

          this.cdr.markForCheck();
        });
    });
  }

  override hideModal() {
    super.hideModal();
    window.history.pushState({}, 'Songhunt', this.httpReqService.trimUrlForPopups(window.location.href, 'explorer-signup'));
    window.history.pushState({}, 'Songhunt', this.httpReqService.trimUrlForPopups(window.location.href, 'signup'));
  }

  switchModal() {
    this.modalState = this.modalState === 'login' ? 'register' : 'login';
    this.currentStep = 1;
  }

  login() {
    if (this.loginEmail.invalid || this.loginPassword.invalid) {
      this.loginEmail.markAsTouched();
      this.loginPassword.markAsTouched();
      return;
    }
    this.isCurrentlyLoading.set(true);
    this.httpReqService.login(this.loginEmail.value!, this.loginPassword.value!)
      .subscribe({
        next: (response: HttpResponse<User>) => {
          const baseDomain = response.body?.baseDomain?.toLowerCase() || 'undefined';
          const isWhiteLabel = response.body?.accountTypeId === 4;

          if (isWhiteLabel && !window.location.href.includes(baseDomain)) {
            // Encrypting before adding to URL
            const email = encodeURIComponent(this.loginEmail.value!);
            const pass = encodeURIComponent(this.loginPassword.value!);

            this.httpReqService.logout()
              .subscribe({
                complete: () => {
                  window.location.href = `${baseDomain}/songhunt/?isWhiteLabelUser=true&em=${email}&ps=${pass}`;
                }
              });
          } else {
            this.userStateService.setUser(response.body);
            this.toast.success(`Welcome Back ${response.body?.name}`);
            this.hideModal();
          }
        },
        error: (e) => {
          this.toast.error(e.error.error);
        }
      }).add(() => {
      this.isCurrentlyLoading.set(false);
      if (this.activateHardRefresh) {
        // Perform a hard refresh
        window.location.reload();
      }
    });
  }

  onRegister() {
    if (!this.user()) {
      if (this.regName.invalid || this.regEmail.invalid || this.regPassword.invalid) {
        this.regName.markAsTouched();
        this.regEmail.markAsTouched();
        this.regPassword.markAsTouched();
        return;
      }

      const baseDomain = this.isLocalHost()
        ? null
        : this.isWhiteLabel() ? location.origin : null;

      const accountTypeId = this.isLocalHost()
        ? 3
        : !window.location.href.includes('www') ? 4 : 3;

      let userRegistration: any = {
        name: this.regName.value,
        email: this.regEmail.value,
        password: this.regPassword.value,
        accountTypeId,
        baseDomain
      };

      userRegistration.regCode = this.isVipUser ? 'VIP24' : this.promotionCode.value;

      userRegistration.regCode = this.isVipUser ? 'VIP24' : this.promotionCode.value;

      if (this.affiliateCode) {
        userRegistration.refCode = this.affiliateCode;
        this.loginPopupTitle = 'Your friend hooked you up!';
      }

      if (this.userStateService.utmTags) {
        userRegistration.utm = this.userStateService.utmTags;
      }

      this.isCurrentlyLoading.set(true);
      this.handleRegistration(userRegistration, this.selectedPlan === 'paid');

    } else {
      this.isCurrentlyLoading.set(true);
      this.httpReqService.createCheckoutSession(this.promotionCode.value);
      setTimeout(() => {
        this.isCurrentlyLoading.set(false);
        this.hideModal();
      }, 1000);
    }
  }

  getTitle(): string {
    if (this.isWhiteLabel()) {
      return (this.whiteLabelName() || '').toUpperCase() + ' Sign-Up';
    }

    if (this.user()) {
      return 'Upgrade';
    }

    if (this.modalState === 'register' && this.loginPopupTitle === '') {
      return 'Register';
    }

    if (this.selectedPlan === 'free') {
      return this.loginPopupTitle;
    }

    return this.currentStep === 2 ? this.loginPopupTitle.replace(' for FREE', '') : this.loginPopupTitle;
  }

  toggleHover(price: 'paid' | 'free' | undefined = undefined) {
    if (!price) {
      this.isPaidHovered = false;
      this.isFreeHovered = false;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      return;
    }

    if (price === 'paid') {
      this.isPaidHovered = true;
      this.isFreeHovered = false;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      return;
    }

    if (price === 'free') {
      this.isPaidHovered = false;
      this.isFreeHovered = true;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      return;
    }
  }

  selectPlan(plan: 'free' | 'paid') {
    if (plan === 'free' && this.user()) {
      this.selectedPlan = 'paid';
      return;
    }
    this.selectedPlan = plan;
    this.cdr.markForCheck();
  }

  searchPromotionCode() {
    if (this.promotionCode.value === '') {
      return;
    }
    setTimeout(() => {
      this.selectPlan('paid');
    });

    if (this.promotionCode.value! === 'VIP24') {
      this.isVipUser = true;
      this.coupon.set({
        price: '5.0',
        reduction: '100.0',
        isReductionAmount: false,
        finalPrice: 0
      });
      this.handleCoupon();
      return;
    }

    if (this.promotionCode.value! === 'HOLIDAY') {
      this.coupon.set({
        price: '5.0',
        reduction: '100.0',
        isReductionAmount: false,
        finalPrice: 0
      });
      this.handleCoupon();
      return;
    }

    this.isCurrentlyLoading.set(true);
    this.httpReqService.checkPromoCode(this.promotionCode.value!)
      .then((response) => {
        this.coupon.set(response);
      })
      .finally(() => {
        this.handleCoupon();
        this.isCurrentlyLoading.set(false);
      });
  }

  selectPackage(plan: 'free' | 'paid') {
    if (this.isUpgradeMode) {
      return;
    }
    this.selectedPackage = plan;
    this.selectedPlan = plan;
  }

  selectPackageAndContinue(plan: 'free' | 'paid') {
    this.selectedPackage = plan;
    this.selectedPlan = plan;
    this.currentStep = 2;
  }

  goBackToPackages() {
    this.currentStep = 1;
  }

  getRandomPosition(): number {
    return Math.random() * 100;
  }

  private handleCoupon(isAffiliate = false) {
    this.isCurrentlyLoading.set(false);
    const coupon = this.coupon();
    if (coupon && coupon?.price !== null) {
      setTimeout(() => {
        this.selectPlan('paid');
        this.priceAfterDiscount.set(+(coupon.reduction!) === 100 ? 'Free' : `$${coupon.finalPrice} per month`);
      });
      if (!isAffiliate) {
        this.toast.success('Coupon applied successfully');
      }
    } else {
      this.toast.info('No coupon found');
    }
  }

  private handleRegistration(userRegistration: {}, isHunter: boolean) {
    this.httpReqService.register(userRegistration).pipe(
      switchMap(() => {
        // This will only be executed if the register call is successful
        return this.httpReqService.login(this.regEmail.value!, this.regPassword.value!);
      }),
      tap((response: HttpResponse<User>) => {
        localStorage.removeItem('not_used_affiliate_code');
        // Handling successful login
        this.userStateService.setUser(response.body);
        this.toast.success(`Welcome ${response.body?.name}`);
        if (isHunter && !this.isVipUser) {
          this.httpReqService.createCheckoutSession(this.promotionCode.value);
          setTimeout(() => {
            this.hideModal();
            this.isCurrentlyLoading.set(false);
          }, 1000);
        } else {
          this.hideModal();
          this.isCurrentlyLoading.set(false);
        }
      })
    ).subscribe({
      next: () => {
        // Actions after successful login
        removeAffiliateCode();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        // This will handle errors from both register and login calls
        this.toast.error(httpErrorResponse.error.error);
        this.isCurrentlyLoading.set(false);
      }
    });
  }
}
