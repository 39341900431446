/**
 * @preserve jquery-param (c) KNOWLEDGECODE | MIT
 */
var e = function (e) {
  var n = [],
    o = function (e, o) {
      o = null == (o = "function" == typeof o ? o() : o) ? "" : o, n[n.length] = encodeURIComponent(e) + "=" + encodeURIComponent(o);
    },
    t = function (e, r) {
      var f, i, l;
      if (e) {
        if (Array.isArray(r)) for (f = 0, i = r.length; f < i; f++) t(e + "[" + ("object" == typeof r[f] && r[f] ? f : "") + "]", r[f]);else if ("[object Object]" === Object.prototype.toString.call(r)) for (l in r) t(e + "[" + l + "]", r[l]);else o(e, r);
      } else if (Array.isArray(r)) for (f = 0, i = r.length; f < i; f++) o(r[f].name, r[f].value);else for (l in r) t(l, r[l]);
      return n;
    };
  return t("", e).join("&");
};
export { e as default };