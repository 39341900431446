import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SongMetadata} from '../../../interfaces/final-song';
import {FormControl} from '@angular/forms';
import {ModalService} from '../../../services/modal.service';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'mypart-song-metadata',
  templateUrl: './song-metadata.component.html',
  styleUrl: './song-metadata.component.scss',
  standalone: false
})
export class SongMetadataComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  csvFileName = new FormControl('');

  @Input() songMetadata!: SongMetadata;
  @Input() noMetadata: boolean = false;
  @Input() artist: string = '';
  @Input() title: string = '';

  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngOnInit() {
    if (this.songMetadata) {
      this.songMetadata = this.sortSongMetadata();
    }
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }

  getObjectKeys(): Array<string> {
    return Object.keys(this.songMetadata!);
  }

  exportToCsv() {
    if (!this.songMetadata) {
      return;
    }

    const headers = Object.keys(this.songMetadata).join(',');
    const row = Object.values(this.songMetadata).join(',');
    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${row}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', this.csvFileName.value || 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private sortSongMetadata() {
    const prioritizedKeys = ['Catalog', 'Contact'];

    // Sort keys
    const sortedKeys = Object.keys(this.songMetadata).sort((a, b) => {
      const indexA = prioritizedKeys.indexOf(a);
      const indexB = prioritizedKeys.indexOf(b);

      // If both keys are in the priority list, sort based on their position
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // If only one key is in the priority list, it should come first
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      // Otherwise, sort alphabetically
      return a.localeCompare(b);
    });

    // Create a new object with sorted keys
    const sortedMetadata: SongMetadata = {};
    for (const key of sortedKeys) {
      sortedMetadata[key] = this.songMetadata[key];
    }

    return sortedMetadata;
  }
}
