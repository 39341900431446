import {Injectable, signal, Type} from '@angular/core';
import {ModalConfig} from '../interfaces/modal-config';
import {ModalType} from '../interfaces/modal-types.enum';
import {AffiliationLinkComponent} from '../shared/modal/affiliation-link/affiliation-link.component';
import {FilterOverloadComponent} from '../shared/modal/filter-overload/filter-overload.component';
import {LoginErrorComponent} from '../shared/modal/login-error/login-error.component';
import {LoginRegisterComponent} from '../shared/modal/login-register/login-register.component';
import {LyricsComponent} from '../shared/modal/lyrics/lyrics.component';
import {PurchaseSuccessComponent} from '../shared/modal/purchase-success/purchase-success.component';
import {ReportIssueModalComponent} from '../shared/modal/report-issue-modal/report-issue-modal.component';
import {SongMetadataComponent} from '../shared/modal/song-metadata/song-metadata.component';
import {YouTubeSuggestionsComponent} from '../shared/modal/you-tube-suggestions/you-tube-suggestions.component';
import {YoutubeUrlWindowComponent} from '../shared/modal/youtube-url-window/youtube-url-window.component';
import {NoResultsComponent} from '../shared/modal/no-results/no-results.component';
import {
  DifferentUserPreferencesComponent
} from '../shared/modal/different-user-preferences/different-user-preferences.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  activeModals = signal<ModalConfig[]>([]);
  isLoading = signal(false);

  private readonly modalComponents = new Map<ModalType, Type<any>>([
    [ModalType.AFFILIATION_LINK, AffiliationLinkComponent],
    [ModalType.FILTER_OVERLOAD, FilterOverloadComponent],
    [ModalType.LOGIN_ERROR, LoginErrorComponent],
    [ModalType.LOGIN_REGISTER, LoginRegisterComponent],
    [ModalType.LYRICS, LyricsComponent],
    [ModalType.PURCHASE_SUCCESS, PurchaseSuccessComponent],
    [ModalType.REPORT_ISSUE, ReportIssueModalComponent],
    [ModalType.SONG_METADATA, SongMetadataComponent],
    [ModalType.YOUTUBE_SUGGESTIONS, YouTubeSuggestionsComponent],
    [ModalType.YOUTUBE_URL_WINDOW, YoutubeUrlWindowComponent],
    [ModalType.NO_RESULTS, NoResultsComponent],
    [ModalType.DIFFERENT_USER_PREFERENCES, DifferentUserPreferencesComponent]
  ]);

  /** Set Loading State */
  setLoading(loading: boolean) {
    this.isLoading.set(loading);
  }

  /** Open a Modal */
  open(modalType: ModalType, config: Partial<ModalConfig> = {}) {
    const component = this.modalComponents.get(modalType);
    if (!component) {
      throw new Error(`Modal component not found for type: ${modalType}`);
    }

    const currentModals = this.activeModals();
    const isModalAlreadyOpen = currentModals.some(
      modal => modal.component === component
    );

    if (isModalAlreadyOpen) {
      return;
    }

    const modalId = this.generateModalId();
    const modalConfig: ModalConfig = {
      id: modalId,
      component,
      inputs: {
        ...config.inputs,
        modalId
      },
      zIndex: config.zIndex
    };

    this.activeModals.update(modals => [...modals, modalConfig]);
    return modalId;
  }

  /** Close a Specific Modal */
  close(modalId: string) {
    this.activeModals.update(modals => modals.filter(modal => modal.id !== modalId));
  }

  /** Close All Modals */
  closeAll() {
    this.activeModals.set([]);
  }

  /** Get Active Modals */
  getActiveModals(): ModalConfig[] {
    return this.activeModals();
  }

  /** Generate Unique Modal ID */
  private generateModalId(): string {
    return 'modal-' + Math.random().toString(36).substring(2, 11);
  }
}
