import {AfterViewInit, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'mypart-youtube-url-window',
  templateUrl: './youtube-url-window.component.html',
  styleUrl: './youtube-url-window.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class YoutubeUrlWindowComponent extends BaseModalComponent implements AfterViewInit {
  @Input() youtubeId!: string;
  iframeSrc?: SafeResourceUrl;

  constructor(
    modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.youtubeId) {
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${this.youtubeId}?autoplay=1&enablejsapi=1`
      );
    }
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }

  override hideModal() {
    super.hideModal();
    this.iframeSrc = undefined;
  }
}
