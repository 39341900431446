import {ChangeDetectionStrategy, Component, computed} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ModalService} from '../../../services/modal.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'mypart-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('modalAnimation', [
      state('void', style({
        transform: 'scale(0.5)',
        opacity: 0
      })),
      state('*', style({
        transform: 'scale(1)',
        opacity: 1
      })),
      transition(':enter', [
        animate('200ms ease-out')
      ]),
      transition(':leave', [
        animate('150ms ease-in', style({
          transform: 'scale(0)',
          opacity: 0
        }))
      ])
    ]),
    trigger('backdropAnimation', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 1
      })),
      transition(':enter', [
        animate('200ms ease-out')
      ]),
      transition(':leave', [
        animate('150ms ease-in', style({
          opacity: 0
        }))
      ])
    ])
  ],
  standalone: false
})
export class ModalContainerComponent {
  activeModals = this.modalService.activeModals; // Signal
  // Derived Signal for `hasModals`
  hasModals = computed(() => this.activeModals().length > 0);
  isLoading = this.modalService.isLoading;

  constructor(private modalService: ModalService) {
  }

  getModalZIndex(index: number): number {
    return 1002 + (index * 10);
  }

  getBackdropZIndex(): number {
    const modals = this.modalService.getActiveModals();
    return modals.length > 0 ? this.getModalZIndex(modals.length - 1) - 1 : 1001;
  }
}
