export const environment = {
  production: false,
  defaultUserEmail: 'shtest@songhunt.com',
  defaultUserPass: 'fdSa654%$#Dfs',
  defaultPlaylistName: 'My Songhunt playlist',

  toastSupportMessage: 'If the issue persists, please reach out to our support team at <a class="underline" href="mailto:songhunt@mypart.com">Songhunt Support</a>',

  baseUrl: 'http://dev.mypart.com/mypart-webapp/', // => Run Dev Locally
  baseUrlWithApi: 'http://dev.mypart.com/mypart-webapp/api/', // => Run Dev Locally
  baseUrlWithApiSh: 'http://dev.mypart.com/mypart-webapp/api/sh/', // => Run Dev Locally

  // baseUrl: 'https://www.mypart.com/mypart-webapp/', // => Run Prod Locally
  // baseUrlWithApi: 'https://www.mypart.com/mypart-webapp/api/', // => Run Prod Locally
  // baseUrlWithApiSh: 'https://www.mypart.com/mypart-webapp/api/sh/', // => Run Prod Locally

  // Spotify
  spotifyAuthEndpoint: 'https://accounts.spotify.com/authorize',
  spotifyTokenEndpoint: 'https://accounts.spotify.com/api/token',
  spotifyAppClientId: '5fd8804fa2c54c7381a745f4996a25b4',
  spotifyAppClientSecret: '0ae12af88e17464686a534ba2f4d730e',
  spotifyRefreshToken: 'AQAsH7VHJV2Li6VepxwtKogt-kjAC6iom27cL4TxbB5c5jIwOU3_wMmU4Mg08YO4BTPZ6DhSwiCjtYzFHSSyP649fEX0FkHGtG1N3tzmXyze3RxG6x2YLZASzHTmVD0IlrE',
  spotifyDefaultUserId: '6y3srerj60jwoc34mc3a0bkmj',
  // spotifyRedirectUri: 'http://localhost:4200/', // for localhost
  spotifyRedirectUri: 'http://dev.mypart.com/songhunt/', // for dev

  // Youtube
  youtubeDefaultUserAccessToken: '',
  youtubeDefaultUserRefreshToken: '',
  youtubeApiKey: '',
  youtubeClientId: '',
  youtubeProjectId: '',
  youtubeClientSecret: '',
  youtubeRedirectUri: ''
};
