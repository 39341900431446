<div class="modal-container transition-all">
  <!-- Backdrop -->
  @if (hasModals()) {
    <div class="modal-backdrop"
         [@backdropAnimation]
         [style.z-index]="getBackdropZIndex()">
    </div>
  }

  <!-- Modals -->
  @for (modal of activeModals(); track modal.id) {
    <div class="modal-wrapper"
         [@modalAnimation]
         [style.z-index]="getModalZIndex($index)">
      <div class="modal-content-wrapper">
        @if (isLoading()) {
          <div class="absolute inset-0 flex items-center justify-center z-50">
            <mypart-spinner-loading></mypart-spinner-loading>
          </div>
        }
        <div [ngClass]="{'blur-sm': isLoading()}">
          <ng-container [ngComponentOutlet]="modal.component"
                        [ngComponentOutletInputs]="modal.inputs">
          </ng-container>
        </div>
      </div>
    </div>
  }
</div>
