export interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
}

/**
 * Validates and processes the lyrics.
 * @param lyrics The input lyrics string.
 * @returns The processed lyrics if valid; otherwise, an empty string.
 */
export function getValidatedLyrics(lyrics?: string): string {
  if (!lyrics || lyrics.trim() === '') {
    return '';
  }

  // Trim the lyrics to a maximum of 7,000 characters
  if (lyrics.length > 7000) {
    lyrics = lyrics.substring(0, 7000);
  }

  const validationResult = validateLyrics(lyrics);
  if (validationResult.isValid) {
    return processLyrics(lyrics);
  } else {
    return '';
  }
}

/**
 * Tests the lyrics against validation rules and returns the results.
 * @param lyrics The input lyrics string.
 * @returns An object containing the validation result and error message.
 */
export function testValidation(lyrics: string): ValidationResult {
  // Trim the lyrics to a maximum of 7,000 characters for consistency
  if (lyrics.length > 7000) {
    lyrics = lyrics.substring(0, 7000);
  }

  return validateLyrics(lyrics);
}

// Helper functions below

function validateLyrics(lyrics: string): ValidationResult {
  let errorMessage = '';
  const lines = lyrics.split(/\r?\n/).filter((line) => line.trim() !== '');
  const nonSectionLines = lines.filter((line) => !isSectionHeader(line));

  if (nonSectionLines.length < 3) {
    errorMessage = 'A minimum of three lines is required.';
    return {isValid: false, errorMessage};
  }

  // Removed the length check as we are trimming the lyrics to 7,000 characters
  // if (lyrics.length > 7000) {
  //   errorMessage = 'Lyrics exceed the maximum allowed length of 7,000 characters.';
  //   return { isValid: false, errorMessage };
  // }

  // For other cases, we will fix the lyrics in processLyrics()

  return {isValid: true, errorMessage: ''};
}

function processLyrics(lyrics: string): string {
  // Remove leading/trailing whitespace from each line
  let processedLyrics = lyrics
    .split(/\r?\n/)
    .map((line) => line.trim())
    .join('\n');

  // Remove chord notations (lines with three or more consecutive spaces)
  processedLyrics = processedLyrics
    .split(/\r?\n/)
    .filter((line) => !line.match(/^[A-G][#b]?[\s]{2,}[A-G][#b]?.*$/))
    .join('\n');

  // Remove lines that consist solely of ellipses
  processedLyrics = processedLyrics
    .split(/\r?\n/)
    .filter((line) => {
      const trimmedLine = line.trim();
      return trimmedLine !== '' && !/^\.{3,}$/.test(trimmedLine);
    })
    .join('\n');

  // Remove HTML tags
  processedLyrics = processedLyrics.replace(/<\/?[^>]+(>|$)/g, '');

  // Handle section headers
  processedLyrics = handleSectionHeaders(processedLyrics);

  // Preserve double newlines to maintain section breaks
  processedLyrics = processedLyrics.replace(/\n{3,}/g, '\n\n');

  // Remove leading/trailing whitespace again after processing
  processedLyrics = processedLyrics
    .split(/\r?\n/)
    .map((line) => line.trim())
    .join('\n');

  // Trim any extra whitespace
  processedLyrics = processedLyrics.trim();

  return processedLyrics;
}

function handleSectionHeaders(lyrics: string): string {
  const lines = lyrics.split(/\r?\n/);
  const sectionLyricsMap = new Map<string, string[]>();
  let currentSectionName = '';
  let currentSectionLyrics: string[] = [];
  let processedLines: string[] = [];

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i].trim();

    if (isSectionHeader(line)) {
      const sectionName = line.toLowerCase().replace(/[\[\]\(\)\{\}]/g, '');

      // If section already exists, replace header with stored lyrics
      if (sectionLyricsMap.has(sectionName)) {
        const existingLyrics = sectionLyricsMap.get(sectionName)!;
        processedLines.push(...existingLyrics);
        currentSectionName = '';
        currentSectionLyrics = [];
      } else {
        // Start a new section
        currentSectionName = sectionName;
        currentSectionLyrics = [];
      }
    } else if (line.match(/^[A-G][#b]?[\s]{2,}[A-G][#b]?.*$/)) {
      // Skip chord notation lines (already handled earlier, but kept for safety)
      continue;
    } else {
      if (currentSectionName) {
        currentSectionLyrics.push(line);
      } else {
        processedLines.push(line);
      }
    }

    // If at the end of the section or last line, store the lyrics and add them to processedLines
    if (
      currentSectionName &&
      (i === lines.length - 1 || isSectionHeader(lines[i + 1]))
    ) {
      if (currentSectionLyrics.length > 0) {
        sectionLyricsMap.set(currentSectionName, [...currentSectionLyrics]);
        processedLines.push(...currentSectionLyrics); // Add lyrics to processedLines
        currentSectionName = '';
        currentSectionLyrics = [];
      }
    }
  }

  // Handle any remaining section lyrics
  if (currentSectionName && currentSectionLyrics.length > 0) {
    sectionLyricsMap.set(currentSectionName, [...currentSectionLyrics]);
    processedLines.push(...currentSectionLyrics); // Add lyrics to processedLines
  }

  return processedLines.join('\n');
}

function isSectionHeader(line: string): boolean {
  const baseSectionHeaders = [
    'intro',
    'verse',
    'pre-chorus',
    'pre chorus',
    'chorus',
    'post-chorus',
    'post chorus',
    'bridge',
    'hook',
    'drop',
    'outro',
    'interlude',
    'instrumental',
    'solo',
    'breakdown',
    'refrain',
    'coda',
  ];

  // Allow for section headers with or without numbers, and ignore case
  const sectionHeaderRegex = new RegExp(`^(${baseSectionHeaders.join('|')})( \\d+)?$`, 'i');

  const trimmedLine = line.trim().toLowerCase().replace(/[\[\]\(\)\{\}]/g, '');

  return sectionHeaderRegex.test(trimmedLine);
}

// Test suite below

interface TestCase {
  name: string;
  input: string;
  expectedProcessedLyrics: string;
  expectedValidationResult: ValidationResult;
}

export function runTests(): void {
  const testCases: TestCase[] = [
    {
      name: 'Test 1: Valid Lyrics Without Section Headers',
      input: `We sing together under the sky
Our voices reach up, flying high

Walking paths unknown to us
We trust the wind without a fuss

We sing together under the sky
Our voices reach up, flying high`,
      expectedProcessedLyrics: `We sing together under the sky
Our voices reach up, flying high

Walking paths unknown to us
We trust the wind without a fuss

We sing together under the sky
Our voices reach up, flying high`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 2: Lyrics with Ellipses (Should Be Fixed)',
      input: `The sun sets beyond the hill
Colors fade but beauty still

In the silence, whispers start...`,
      expectedProcessedLyrics: `The sun sets beyond the hill
Colors fade but beauty still`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 3: Lyrics with Chord Notations (Should Be Fixed)',
      input: `C       G       Am       F
Under the stars we dance tonight
Em      D       G
Holding hands until the light`,
      expectedProcessedLyrics: `Under the stars we dance tonight
Holding hands until the light`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 4: Lyrics That Are Too Short',
      input: `Whispers in the wind`,
      expectedProcessedLyrics: ``,
      expectedValidationResult: {isValid: false, errorMessage: 'A minimum of three lines is required.'},
    },
    {
      name: 'Test 5: Lyrics with Excessive Empty Lines',
      input: `Echoes fade in the distance



Shadows dance in the night




Hearts beat in unison`,
      expectedProcessedLyrics: `Echoes fade in the distance

Shadows dance in the night

Hearts beat in unison`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 6: Lyrics with Leading and Trailing Whitespace',
      input: `   Silent whispers call my name    
Dreams awaken, fuel the flame     

   Paths untaken lie ahead   `,
      expectedProcessedLyrics: `Silent whispers call my name
Dreams awaken, fuel the flame

Paths untaken lie ahead`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 7: Lyrics with Section Headers (Should Be Fixed)',
      input: `Chorus
Our hearts beat as one tonight
Underneath the pale moonlight

Verse
Steps we take into the wild
Hearts are open, spirits mild

Bridge
Crossing over, we arrive

Chorus`,
      expectedProcessedLyrics: `Our hearts beat as one tonight
Underneath the pale moonlight

Steps we take into the wild
Hearts are open, spirits mild

Crossing over, we arrive

Our hearts beat as one tonight
Underneath the pale moonlight`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 8: Lyrics with HTML Tags (Should Be Fixed)',
      input: `<p>In the beginning, silence...</p>

<a href="#">A new world unfolds</a>`,
      expectedProcessedLyrics: `A new world unfolds`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 9: Lyrics with Multiple Ellipses and Repeats (Should Be Fixed)',
      input: `Sunrise paints the sky
Birds begin to fly

We embrace the day
Letting go of yesterday

Sunrise paints the sky
Birds begin to fly

New paths we tread
With hope ahead

We embrace the day
Letting go of yesterday`,
      expectedProcessedLyrics: `Sunrise paints the sky
Birds begin to fly

We embrace the day
Letting go of yesterday

Sunrise paints the sky
Birds begin to fly

New paths we tread
With hope ahead

We embrace the day
Letting go of yesterday`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 10: Lyrics with Chord Notations and Section Headers (Should Be Fixed)',
      input: `Intro
C       G       Am       F
Under the stars we dance tonight
Em      D       G
Holding hands until the light`,
      expectedProcessedLyrics: `Under the stars we dance tonight
Holding hands until the light`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 11: Lyrics with Non-standard Section Headers (Should Be Fixed)',
      input: `Hook
Feel the rhythm in your feet

Drop
The beat goes on and on`,
      expectedProcessedLyrics: `Feel the rhythm in your feet

The beat goes on and on`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 12: Lyrics with Punctuation and Special Characters',
      input: `Life's a journey, not a race!
Embrace the chaos; find your place.

Hearts beat—rhythms align.
In the end, we'll be just fine.`,
      expectedProcessedLyrics: `Life's a journey, not a race!
Embrace the chaos; find your place.

Hearts beat—rhythms align.
In the end, we'll be just fine.`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 13: Lyrics with Numbers and Symbols',
      input: `We are the 99%
Fighting for our rights!

#Revolution starts today
@Change is on the way`,
      expectedProcessedLyrics: `We are the 99%
Fighting for our rights!

#Revolution starts today
@Change is on the way`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 14: Lyrics with Mixed Content (Should Be Fixed)',
      input: `Whispered secrets in the night

Echoes of love take flight

C       G       Am       F
Under the stars, we unite`,
      expectedProcessedLyrics: `Whispered secrets in the night

Echoes of love take flight

Under the stars, we unite`,
      expectedValidationResult: {isValid: true, errorMessage: ''},
    },
    {
      name: 'Test 15: Lyrics with Only Section Headers (Too Short)',
      input: `Chorus

Verse

Bridge`,
      expectedProcessedLyrics: ``,
      expectedValidationResult: {isValid: false, errorMessage: 'A minimum of three lines is required.'},
    },
    {
      name: 'Test 16: Lyrics Exceeding Maximum Length',
      input: 'A'.repeat(7001),
      expectedProcessedLyrics: ``,
      expectedValidationResult: {
        isValid: false,
        errorMessage: 'Lyrics exceed the maximum allowed length of 7,000 characters.'
      },
    },
  ];

  testCases.forEach((testCase, index) => {
    const validationResult = testValidation(testCase.input);
    const processedLyrics = getValidatedLyrics(testCase.input);

    const lyricsMatch = processedLyrics.trim() === testCase.expectedProcessedLyrics.trim();
    const validationMatch = validationResult.isValid === testCase.expectedValidationResult.isValid &&
      validationResult.errorMessage === testCase.expectedValidationResult.errorMessage;

    const passed = lyricsMatch && validationMatch;

    console.log(`Test ${index + 1}: ${testCase.name} - ${passed ? 'Passed' : 'Failed'}`);

    if (!passed) {
      if (!lyricsMatch) {
        console.log('Processed lyrics do not match expected output.');
        console.log('Expected:', testCase.expectedProcessedLyrics);
        console.log('Got:', processedLyrics);
      }
      if (!validationMatch) {
        console.log('Validation result does not match expected output.');
        console.log('Expected:', testCase.expectedValidationResult);
        console.log('Got:', validationResult);
      }
    }
  });
}
