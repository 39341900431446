import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IFrameAudioPlayerStateService} from '../../../services/i-frame-audio-player-state.service';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'mypart-you-tube-iframe',
  templateUrl: './you-tube-iframe.component.html',
  styleUrls: ['./you-tube-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class YouTubeIframeComponent implements OnChanges {
  @Input() youtube_id: string | undefined;
  @Input() timestamp!: number;
  @Output() playSongClicked = new EventEmitter<void>();
  iframeSrc: SafeResourceUrl | undefined;
  showIframe = signal(false);
  iframeLoaded = false;

  constructor(private sanitizer: DomSanitizer,
              private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService,
              private userStateService: UserStateService) {
    effect(() => {
      const playingIframeId = this.iFrameAudioPlayerStateService.playingIframeId();
      if (playingIframeId !== this.youtube_id) {
        this.showIframe.set(false);
      }
    });

    effect(() => {
      if (this.iFrameAudioPlayerStateService.playingAudioUrl()) {
        this.showIframe.set(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['youtube_id'] && changes['youtube_id'].currentValue) {
      this.iframeSrc =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube.com/embed/${changes['youtube_id'].currentValue}?start=${this.timestamp}&enablejsapi=1`);
    }
  }

  playVideo() {
    if (!this.userStateService.user()) {
      this.userStateService.checkUserHasExceededAllowedSongs();
    }

    this.iFrameAudioPlayerStateService.setPlayingIframe(this.youtube_id);
    this.playSongClicked.next();
    this.showIframe.set(true);
  }
}
