<div (click)="returnToSongLocation()"
     [class.show]="selectedSongSignal()"
     class="player fixed bottom-0 left-0 right-0 overflow-hidden md:rounded-tr-lg md:rounded-tl-lg cursor-pointer
            md:-translate-x-1/2 md:left-1/2 md:max-w-lg shadow-2xl">
  @if (selectedSong(); as song) {
    <div class="flex justify-between items-center pb-3 px-4 relative h-full">
      <!-- Song Info Section -->
      <div class="flex min-w-0 gap-x-4 flex-grow song-info -mt-4">
        <img imageLoader [src]="song.imageUrl"
             [alt]="'Cover image for the song - ' + song.title + ', By - ' + song.artist"
             class="flex-none bg-gray-50 cover-image" height="48" width="48">
        <div class="min-w-0 flex-auto flex flex-col justify-center">
          <span class="select-none text-white font-medium text-sm md:text-md max-[600px]:whitespace-normal ellipsis"
                isEllipsis>{{ song.title }}</span>
          <span class="select-none text-xs md:text-sm text-gray-200 opacity-90 ellipsis mt-0.5"
                isEllipsis>{{ song.artist }}</span>
        </div>
      </div>

      <!-- Player Controls Section -->
      <div class="flex items-center gap-x-4 -mt-4">
        <svg [class.text-white]="showControls()"
             [class.opacity-0]="firstSongId === song.id"
             [class.text-gray-300]="!showControls()"
             [class.cursor-pointer]="showControls()"
             [class.cursor-not-allowed]="!showControls()"
             [class.disabled]="!showControls() || firstSongId === song.id"
             (click)="playPreviousSong()"
             stopPropagation
             class="w-8 h-8"
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             stroke-width="2"
             stroke-linecap="round"
             stroke-linejoin="round"
             xmlns="http://www.w3.org/2000/svg">
          <path d="m12 19-7-7 7-7"/>
          <path d="m19 19-7-7 7-7"/>
        </svg>

        @if (isSongPlaying()) {
          <svg [class.text-white]="showControls()"
               [class.text-gray-300]="!showControls()"
               [class.cursor-pointer]="showControls()"
               [class.cursor-not-allowed]="!showControls()"
               [class.disabled]="!showControls()"
               (click)="pauseVideo()"
               class="w-9 h-9"
               stopPropagation
               viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
               xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="4" width="4" height="16"/>
            <rect x="14" y="4" width="4" height="16"/>
          </svg>
        } @else {
          <svg [class.text-white]="showControls()"
               [class.text-gray-300]="!showControls()"
               [class.cursor-pointer]="showControls()"
               [class.cursor-not-allowed]="!showControls()"
               [class.disabled]="!showControls()"
               (click)="playVideo()"
               class="w-9 h-9"
               stopPropagation
               viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
               xmlns="http://www.w3.org/2000/svg">
            <polygon points="5 3 19 12 5 21 5 3"/>
          </svg>
        }

        <svg [class.text-white]="showControls()"
             [class.opacity-0]="lastSongId === song.id"
             [class.text-gray-300]="!showControls()"
             [class.cursor-pointer]="showControls()"
             [class.cursor-not-allowed]="!showControls()"
             [class.disabled]="!showControls() || lastSongId === song.id"
             (click)="playNextSong()"
             stopPropagation
             class="w-8 h-8"
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             stroke-width="2"
             stroke-linecap="round"
             stroke-linejoin="round"
             xmlns="http://www.w3.org/2000/svg">
          <path d="m5 19 7-7-7-7"/>
          <path d="m12 19 7-7-7-7"/>
        </svg>

        @if (!isThisIphone) {
          <svg xmlns="http://www.w3.org/2000/svg"
               (click)="togglePlayAllSongs()"
               aria-hidden="true"
               class="w-5 h-5 text-white cursor-pointer no-effect"
               [class.text-yellow-300]="playAllSongs()"
               fill="currentColor"
               stopPropagation
               height="20"
               viewBox="0 0 358.000000 293.000000"
               preserveAspectRatio="xMidYMid meet"
               [attr.alt]="'Play All Songs'"
               [tp]="isMobileDevice() ? null : playAllSongs() ? 'Stop Play All' : 'Play All Songs'">
            <g transform="translate(0.000000,293.000000) scale(0.100000,-0.100000)"
               fill="currentColor" stroke="none">
              <path d="M81 2834 c-45 -37 -53 -79 -49 -246 3 -164 11 -186 79 -220 32 -17
                      120 -18 1419 -18 1323 0 1387 1 1419 18 64 34 72 65 69 255 l-3 169 -37 34
                      -38 34 -1414 0 -1414 0 -31 -26z"/>
              <path d="M85 1898 c-46 -36 -55 -73 -55 -230 0 -158 10 -193 63 -232 28 -21
                      36 -21 756 -21 678 0 729 1 754 18 51 33 61 60 65 188 6 175 -2 226 -45 266
                      l-34 33 -738 0 -738 0 -28 -22z"/>
              @if (playAllSongs()) {
                <rect x="2100" y="0" width="600" height="1826" rx="70" ry="70"/>
                <rect x="2900" y="0" width="600" height="1826" rx="70" ry="70"/>
              } @else {
                <path d="M2185 1826 c-40 -17 -84 -63 -101 -103 -11 -26 -14 -174 -14 -792 0
                      -512 3 -769 11 -788 24 -64 109 -122 179 -123 19 0 58 11 85 25 28 13 293 181
                      590 373 614 397 598 383 603 505 3 83 -17 130 -78 178 -50 39 -1108 719 -1137
                      730 -35 13 -103 10 -138 -5z"/>
              }
              <path d="M92 960 c-55 -34 -64 -71 -60 -249 5 -254 -22 -241 503 -241 437 0
                      437 0 475 63 18 28 20 51 20 190 0 176 -7 203 -63 237 -30 19 -53 20 -437 20
                      -389 0 -406 -1 -438 -20z"/>
            </g>
          </svg>
        }

        <svg (click)="openLyrics()"
             class="w-5 h-5 text-white cursor-pointer no-effect"
             stopPropagation
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             stroke-width="2"
             stroke-linecap="round"
             stroke-linejoin="round"
             xmlns="http://www.w3.org/2000/svg"
             [tp]="isMobileDevice() ? null : 'Lyrics Lookup'">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
          <path d="M14 2v6h6"/>
          <line x1="16" y1="13" x2="8" y2="13"/>
          <line x1="16" y1="17" x2="8" y2="17"/>
          <line x1="10" y1="9" x2="8" y2="9"/>
        </svg>

        <mypart-social-sharing
            [link]="song.youtube_id"
            shareColor="text-white"
            source="song"
            stopPropagation>
        </mypart-social-sharing>
      </div>

      <!-- Progress Bar Section -->
      <div class="progress-container mb-2">
        <!-- Time Display -->
        <div class="time-display">
          <span class="current-time">{{ currentTime() }}</span>
          <span class="duration">{{ duration() }}</span>
        </div>

        <!-- Progress Bar -->
        <div class="progress-bar"
             (click)="seekTo($event)"
             (mousemove)="showPreviewTime($event)"
             (mouseleave)="hidePreviewTime()">
          <!-- Buffer Progress -->
          <div class="buffer-progress"
               [style.width.%]="bufferProgress()">
          </div>
          <!-- Playback Progress -->
          <div class="playback-progress"
               [style.width.%]="progress()">
            <!-- Progress Handle -->
            <div class="progress-handle"></div>
          </div>
          <!-- Preview Time Tooltip -->
          @if (previewTime()) {
            <div class="preview-tooltip"
                 [style.left.%]="previewPosition()">
              {{ previewTime() }}
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
