import {ToastService} from '../services/toast.service';

export function copyToClipboard(toast: ToastService, text: string) {
  // Try the modern Clipboard API first
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Copying to clipboard was successful');
      })
      .catch(() => {
        // If Clipboard API fails, fallback to execCommand
        fallbackCopyToClipboard(toast, text);
      });
  } else {
    // For non-secure contexts or older browsers
    fallbackCopyToClipboard(toast, text);
  }
}

function fallbackCopyToClipboard(toast: ToastService, text: string) {
  try {
    // Create a temporary textarea element
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Make it non-editable to avoid focus
    textArea.setAttribute('readonly', '');

    // Make it invisible
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';

    // For mobile devices
    textArea.style.fontSize = '12pt';
    textArea.style.border = '0';
    textArea.style.padding = '0';

    document.body.appendChild(textArea);

    // Handle iOS devices
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }

    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);

    if (successful) {
      toast.success('Copying to clipboard was successful');
    } else {
      throw new Error('Copy command failed');
    }
  } catch (err) {
    toast.error(`Could not copy text: ${err}`);
  }
}
