export function toggleTheme(isLight: boolean) {
  // Ensure transition class is added before changing theme
  document.documentElement.classList.add('theme-transition');

  // Use setTimeout to ensure the transition class is applied before theme changes
  setTimeout(() => {
    if (isLight) {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.remove('h-screen');
      document.documentElement.classList.remove('max-h-screen');
      document.documentElement.style.backgroundColor = '#ffffff';
    } else {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.add('h-screen');
      document.documentElement.classList.add('max-h-screen');
      document.documentElement.style.backgroundColor = '#090b0b';
    }

    // Remove the transition class after the transition is complete
    setTimeout(() => {
      document.documentElement.classList.remove('theme-transition');
    }, 300); // Match this with the CSS transition duration
  }, 10);
}
