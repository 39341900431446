import {AfterViewInit, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'mypart-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrl: './lyrics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LyricsComponent extends BaseModalComponent implements AfterViewInit {
  @Input() title!: string;
  @Input() artist!: string;
  @Input() lyrics!: string;

  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }
}
