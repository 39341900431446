import {AfterViewInit, ChangeDetectionStrategy, Component, Input, signal} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-purchase-success',
  templateUrl: './purchase-success.component.html',
  styleUrl: './purchase-success.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PurchaseSuccessComponent extends BaseModalComponent implements AfterViewInit {
  @Input() isSuccess: boolean = false;
  isSuccessSignal = signal(false);

  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngOnInit() {
    this.isSuccessSignal.set(this.isSuccess);
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }
}
