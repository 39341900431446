import {Injectable, signal} from '@angular/core';
import {ToastMessage} from '../interfaces/toast-message';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastsSignal = signal<ToastMessage[]>([]);
  defaultToastDuration = 4000;

  success(message: string, duration = this.defaultToastDuration) {
    this.showToast(message, 'success', duration);
  }

  error(message: string, duration = this.defaultToastDuration) {
    this.showToast(message, 'error', duration);
  }

  info(message: string, duration = this.defaultToastDuration) {
    this.showToast(message, 'info', duration);
  }

  warning(message: string, duration = this.defaultToastDuration) {
    this.showToast(message, 'warning', duration);
  }

  removeToast(id: string) {
    this.toastsSignal.update(toasts => toasts.filter(toast => toast.id !== id));
  }

  private showToast(message: string, type: 'success' | 'error' | 'info' | 'warning', duration: number) {
    // Check if a toast with the same message already exists
    const currentToasts = this.toastsSignal();
    const hasDuplicateMessage = currentToasts.some(toast => 
      toast.message === message && toast.type === type
    );
    
    // If a duplicate exists, don't add a new toast
    if (hasDuplicateMessage) {
      return;
    }
    
    const id = this.generateUniqueId();
    const expiresAt = Date.now() + duration;

    const newToast: ToastMessage = {
      id,
      message,
      type,
      expiresAt
    };

    // Add the new toast to the array
    this.toastsSignal.update(toasts => [...toasts, newToast]);
  }

  private generateUniqueId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }
}
