import {AfterViewInit, ChangeDetectionStrategy, Component, Input, signal} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';
import {HttpReqService} from '../../../services/http-req.service';
import {YouTubeSuggestions} from '../../../interfaces/youtube-suggestions';
import {FinalSong} from '../../../interfaces/final-song';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IFrameAudioPlayerStateService} from '../../../services/i-frame-audio-player-state.service';
import {UserStateService} from '../../../services/user-state.service';
import {ToastService} from '../../../services/toast.service';
import {GlobalStateService} from '../../../services/global-state.service';
import {environment} from '../../../../environments/environment.prod';
import {ModalType} from '../../../interfaces/modal-types.enum';
import {getValidatedLyrics} from './lyrics-validator';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-you-tube-suggestions',
  templateUrl: './you-tube-suggestions.component.html',
  styleUrl: './you-tube-suggestions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class YouTubeSuggestionsComponent extends BaseModalComponent implements AfterViewInit {
  @Input() searchStr!: string;

  noResultsFound = signal(false);
  title = signal('Searching for Songs...');
  songs: FinalSong[] = [];
  selectedSong = signal<FinalSong | undefined>(undefined);
  songForm!: FormGroup;

  private readonly MAX_UPLOADS_NON_SUBSCRIBED_USER = 3;

  constructor(
    modalService: ModalService,
    private httpReqService: HttpReqService,
    private formBuilder: FormBuilder,
    private userStateService: UserStateService,
    private toast: ToastService,
    private globalStateService: GlobalStateService,
    private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.songForm = this.formBuilder.group({
      title: ['', Validators.required],
      artist: ['', Validators.required],
      lyrics: ['']
    });
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
    this.searchSongs();
  }

  override hideModal() {
    this.globalStateService.highlightedSong.set(undefined);
    this.iFrameAudioPlayerStateService.selectedSongSignal.set(undefined);
    super.hideModal();
  }

  onSubmit() {
    if (this.songForm.valid) {
      const user = this.userStateService.user();

      if (!user) {
        this.openLogin('Sign Up for FREE to search for ANY song, even if it\'s not in our library', false, true);
        return;
      }

      if (!user.isSubscribed && user.uploadedArtworkIds.length > this.MAX_UPLOADS_NON_SUBSCRIBED_USER) {
        this.openLogin('Unlock Premium Features', true, false);
        return;
      }

      this.setLoading(true);
      const song = this.selectedSong()!;
      song.title = this.sanitizeString(this.songForm.get('title')?.value);
      song.artist = this.sanitizeString(this.songForm.get('artist')?.value);
      song.lyrics = this.songForm.get('lyrics')?.value;

      this.httpReqService.submitSong(song)
        .subscribe({
          next: () => {
            this.toast.success('Thanks for adding your song! It\'s being analyzed by our AI, and you\'ll receive an email once it\'s finished processing');
            const currentUser = this.userStateService.user()!;
            currentUser.uploadedArtworkIds.push(1);
            this.userStateService.setUser(currentUser);
            this.setLoading(false);
            this.hideModal();
          },
          error: () => {
            this.toast.error(`We were unable to submit the song. ${environment.toastSupportMessage}`);
            this.setLoading(false);
            this.hideModal();
          }
        });
    }
  }

  chooseSong(song: FinalSong) {
    this.title.set('Verify Song Info');
    this.selectedSong.set(song);
    this.globalStateService.highlightedSong.set(undefined);
    this.iFrameAudioPlayerStateService.selectedSongSignal.set(undefined);
    this.songForm.patchValue({
      title: song.title,
      artist: song.artist,
      lyrics: getValidatedLyrics(song.lyrics)
    });
  }

  changeSongSelection() {
    this.selectedSong.set(undefined);
  }

  private openLogin(loginPopupTitle: string, onlyHunter: boolean, onlyExplorer: boolean) {
    this.modalService.open(ModalType.LOGIN_REGISTER, {
      inputs: {
        loginPopupTitle,
        onlyExplorer,
        onlyHunter,
      }
    });
  }

  private searchSongs() {
    this.setLoading(true);
    this.httpReqService.getYouTubeSuggestions(this.searchStr)
      .subscribe({
        next: (youTubeSuggestions: YouTubeSuggestions[]) => {
          this.songs = youTubeSuggestions.map((suggestion) => ({
            url: '',
            title: suggestion.parsedTitle,
            youtube_id: suggestion.youtubeId || '',
            artist: suggestion.parsedArtist,
            songAddedToPlaylist: false,
            id: suggestion.youtubeId || '',
            lyrics: suggestion.lyrics,
            imageUrl: suggestion.thumbnail.url,
            webp_url: '',
            large_image_url: '',
            timestamp: 0
          }));
        },
        error: (err) => {
          this.toast.warning(`Could not search for songs. ${environment.toastSupportMessage}`);
          this.hideModal();
        },
        complete: () => {
          this.setLoading(false);
          this.noResultsFound.set(this.songs.length === 0);
          this.title.set(this.songs.length === 0
            ? 'We could not find any songs matching your search'
            : 'Select the Best Link');
        }
      });
  }

  private sanitizeString(input: string): string {
    const regex = /[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/g;
    return input.match(regex)?.join('') || '';
  }
}
