import {Injectable} from '@angular/core';
import {popupWindow} from '../helper/window.helper';

@Injectable({
  providedIn: 'root'
})
export class SocialSharingService {
  shareOnSocial(url: string,
                shareTo: 'facebook' | 'twitter' | 'linkedin' | 'whatsapp',
                source: 'playlist' | 'song' | 'search' | 'affiliate link') {
    let text = this.createSocialText(source);

    switch (shareTo) {
      case 'facebook':
        this.shareOnFacebook(url);
        break;
      case 'linkedin':
        this.shareOnLinkedin(url, 'Songhunt and MyPart', 'Look at what I found', 'Songhunt.ai');
        break;
      case 'twitter':
        this.shareOnTwitter(url, text, []);
        break;
      case 'whatsapp':
        this.shareOnWhatsapp(url, text);
        break;
    }
  }

  createSocialText(source: 'playlist' | 'song' | 'search' | 'affiliate link') {
    switch (source) {
      case 'playlist':
        return 'Check out this playlist I created with Songhunt.ai!';
      case 'song':
        return 'Check out this song I discovered using Songhunt.ai!';
      case 'search':
        return 'Check out this song search I ran using Songhunt.ai!';
      case 'affiliate link':
        return 'Get 50% off Songhunt Premium! Sign up with my link and discover new music with AI-powered song search and playlisting.';
    }
  }

  private shareOnFacebook(url: string) {
    const encodedURL = encodeURIComponent(url);
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;

    this.popupWindow(facebookURL);
  }

  private shareOnTwitter(url: string, text: string, hashtags: string[]) {
    const encodedURL = encodeURIComponent(url);
    const encodedText = encodeURIComponent(text);
    const encodedHashtags = encodeURIComponent(hashtags.join(','));

    const twitterURL = `https://twitter.com/intent/tweet?url=${encodedURL}&text=${encodedText}&hashtags=${encodedHashtags}`;

    this.popupWindow(twitterURL);
  }

  private shareOnLinkedin(url: string, title: string, summary: string, source: string) {
    const encodedLink = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const encodedSummary = encodeURIComponent(summary);
    const encodedSource = encodeURIComponent(source);

    const linkedInURL = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=${encodedTitle}&summary=${encodedSummary}&source=${encodedSource}`;

    this.popupWindow(linkedInURL);
  }

  private shareOnWhatsapp(url: string, text: string) {
    const encodedLink = encodeURIComponent(`${text} - ${url}`);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedLink}`;
    this.popupWindow(whatsappURL);
  }

  private popupWindow(url: string) {
    popupWindow(url, 'MyPart Songhunt', 640, 480);
  }
}
