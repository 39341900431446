<div
    [ngClass]="{'outline outline-offset-2 outline-2 outline-zinc-900 dark:outline-zinc-100': highlightedSong()! && !isYouTubeSuggestion}"
    class="dark:bg-zinc-900 bg-gray-100 hover:shadow-red-950-md p-2 sm:p-0 transition-all"
    role="status">
  <div (click)="toggleBrokenIconForMobile()" (mouseenter)="toggleBrokenIcon()"
       (mouseleave)="toggleBrokenIcon()" [ngStyle]="{height: width()! - 16 + 'px'}"
       class="img-container">
    <img [attr.alt]="'Cover image for the song - ' + song.title + ', By - ' + song.artist" [checkPlaceholder]="true"
         [fill]="true" [ngSrc]="song.imageUrl"
         [priority]="imagePriority" class="shadow-xl object-cover rounded-t"
         disableOptimizedSrcset
         imageLoader>
    @if (song.youtube_id) {
      <mypart-you-tube-iframe
          [timestamp]="song.timestamp"
          (playSongClicked)="playSongClicked()"
          [ngClass]="{'p-4': highlightedSong()!}"
          [youtube_id]="song.youtube_id"></mypart-you-tube-iframe>
    } @else {
      <mypart-mp3-player
          (playSongClicked)="playSongClicked()"
          [ngClass]="{'p-4': highlightedSong()!}"
          [audioUrl]="song.url"></mypart-mp3-player>
    }
    @if (!isYouTubeSuggestion) {
      <div class="absolute flex left-[2px] right-[2px] bottom-[2px]">
        @if (song.lyricsMatch || song.isPopular) {
          @if (song.isPopular) {
            <span class="rounded-md px-1 bg-green-500 text-white text-xs sm:text-sm mr-2">Popular</span>
          }
          @if (song.lyricsMatch) {
            <span class="rounded-md px-1 bg-mypart_red-500 text-white text-xs sm:text-sm">Lyric Match</span>
          }
        }
        <svg #tooltip="tippy" (click)="showTooltip(tooltip)" [tp]="brokenLinkMenu"
             [class.hide-broken-link]="!brokenLinkIconVisible()!"
             [ngClass]="isMobileDevice() ? 'z-50' : 'z-40'"
             tpClassName="login-popper" tpTrigger="manual" tpVariation="popper" tpPlacement="top-end"
             class="ml-auto cursor-pointer text-white transition-all" xmlns="http://www.w3.org/2000/svg"
             enable-background="new 0 0 24 24" height="22" viewBox="0 0 24 24"
             width="22" focusable="false"
             aria-hidden="true">
          <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0.3)" rx="12" ry="12"/>

          <path fill="currentColor"
                d="M12 16.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5zM10.5 12c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5zm0-6c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5z"></path>
        </svg>
      </div>
    }
  </div>

  <div [ngClass]="{'!flex-row': isUserWhiteLabel()}"
       class="flex flex-col sm:flex-row items-center justify-between">
    <div [ngClass]="{'!px-2 !w-full': isYouTubeSuggestion}"
         class="flex text-container flex-col content-center justify-around px-1 w-full h-[60px] sm:w-[calc(100%-64px)]">
      <section [title]="'Discover songs similar to ' + song.artist + ' - ' + song.title">
        <div class="song-title w-full inline-block dark:text-white text-sm">
          <a class="hover:underline"
             href="/similar/{{song.artist}}-{{song.title}}"
             target="_blank">
            <p>{{ song.title | trim }}</p>
          </a>
        </div>
      </section>
      <section [title]="'Discover songs by ' + song.artist">
        <div class="artist-name w-full inline-block text-sm text-gray-500 dark:text-gray-400 ">
          <a class="hover:underline"
             href="/artist/{{song.artist}}"
             target="_blank">
            {{ song.artist | trim }}
          </a>
        </div>
      </section>
    </div>
    @if (!isYouTubeSuggestion) {
      <section class="flex items-end mb-[-.5rem] sm:mb-0 w-full sm:w-auto justify-end sm:h-[60px]">
        @if (isUserWhiteLabel()) {
          <svg #tooltip="tippy" (click)="showTooltip(tooltip)" [tp]="moreMenu"
               tpClassName="login-popper" tpTrigger="manual" tpVariation="popper" tpPlacement="top-end"
               class="cursor-pointer text-gray-800 dark:text-white order-1" xmlns="http://www.w3.org/2000/svg"
               enable-background="new 0 0 24 24" height="36" viewBox="0 0 24 24"
               width="36" focusable="false"
               aria-hidden="true">
            <path fill="currentColor"
                  d="M12 16.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5zM10.5 12c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5zm0-6c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5z"></path>
          </svg>
        } @else {
          <section class="w-full h-full flex sm:flex-row justify-end sm:justify-start items-end mb-[4px]">
            <mypart-social-sharing class="sm:mr-[4px]" id="remove-padding" [link]="shareLink"
                                   [youtubeId]="song.youtube_id" [songInfo]="song.artist + ' - ' + song.title"
                                   source="song"></mypart-social-sharing>
            <div (click)="addRemoveSongFromPlaylist()"
                 [tp]="isMobileDevice() ? null : song.songAddedToPlaylist ? 'Remove from playlist' : 'Add to Playlist'"
                 class="block h-[22px] w-[22px] ml-[6px] sm:ml-0 cursor-pointer relative hover:drop-shadow-plus-light
                      hover:dark:drop-shadow-plus-dark">
              @if (song.songAddedToPlaylist) {
                <img imageLoader
                     [attr.alt]="'stylized red \'V\' on a black background. The \'V\' appears to have a sleek, modern design with sharp edges and a slightly metallic or glossy finish, giving it a three-dimensional appearance. The overall aesthetic is bold and striking, likely intended to capture attention with its vibrant color contrast against the dark backdrop.'"
                     fill priority ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/red_v_optimized.png">
              } @else {
                <img imageLoader
                     [attr.alt]="'The image shows a red plus with a white border. The design is simple and clean, ' +
                   'with the red plus being centered and prominent'"
                     fill priority ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/playlist_red_opt.png">
              }
              <ng-template #showAdd>
                <img imageLoader
                     [attr.alt]="'The image shows a red plus with a white border. The design is simple and clean, ' +
                   'with the red plus being centered and prominent'"
                     class="absolute" src="https://d382nvfdu38z2f.cloudfront.net/songhunt/playlist_red_opt.png">
              </ng-template>
            </div>
            @if (song.spotify_id) {
              <a [href]="'https://open.spotify.com/track/' + song.spotify_id" target="_blank"
                 [tp]="isMobileDevice() ? null : 'Play on Spotify'"
                 class="block h-[22px] w-[22px] ml-[6px] cursor-pointer relative hover:drop-shadow-plus-light
                      hover:dark:drop-shadow-plus-dark sm:ml-[8px]">
                <img imageLoader ngSrc="assets/spotify_logo.png" alt="Spotify logo" fill>
              </a>
            }
          </section>
        }
      </section>
    }
  </div>
</div>

<ng-template #moreMenu>
  <section (outsideClick)="hideTooltip()" class="more-menu-container">
    <ul>
      <li>
        <div (click)="openExtraInfoPopup()" [style.width.px]="width()! - 32" class="li-container"
             role="button">
          <div class="left-child">
            <div class="flex items-center justify-center">
              <svg aria-hidden="true" class="w-6 h-6 text-mypart_red-500 ml-[6px] hover:drop-shadow-plus-light
               hover:dark:drop-shadow-plus-dark" fill="none"
                   height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm8 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"/>
              </svg>
            </div>
          </div>

          <div class="right-child">
            <span>More Info</span>
          </div>
        </div>

      </li>
      <li>
        <div (click)="showShareTooltip()" class="li-container">
          <div class="left-child">
            <mypart-social-sharing #socialSharing [link]="shareLink" [showTooltip]="false"
                                   [youtubeId]="song.youtube_id"
                                   source="song"></mypart-social-sharing>
          </div>
          <div class="right-child">
            <span>Share This Song</span>
          </div>
        </div>
      </li>
      <li>
        <div (click)="addRemoveSongFromPlaylist()" class="li-container">
          <div class="left-child">
            <div class="flex flex-col content-center p-1">
              <div class="block w-[22px] h-[22px] cursor-pointer relative hover:drop-shadow-plus-light
           hover:dark:drop-shadow-plus-dark">
                @if (song.songAddedToPlaylist) {
                  <img imageLoader
                       [attr.alt]="'stylized red \'V\' on a black background. The \'V\' appears to have a sleek, modern design with sharp edges and a slightly metallic or glossy finish, giving it a three-dimensional appearance. The overall aesthetic is bold and striking, likely intended to capture attention with its vibrant color contrast against the dark backdrop.'"
                       class="absolute" src="https://d382nvfdu38z2f.cloudfront.net/songhunt/red_v_optimized.png">
                } @else {
                  <img imageLoader
                       [attr.alt]="'The image shows a red plus with a white border. The design is simple and clean, ' +
                   'with the red plus being centered and prominent'"
                       class="absolute" src="https://d382nvfdu38z2f.cloudfront.net/songhunt/playlist_red_opt.png">
                }
                <ng-template #showAdd>
                  <img [attr.alt]="'The image shows a red plus with a white border. The design is simple and clean, ' +
                   'with the red plus being centered and prominent'"
                       class="absolute"
                       imageLoader src="https://d382nvfdu38z2f.cloudfront.net/songhunt/playlist_red_opt.png">
                </ng-template>
              </div>
            </div>
          </div>
          <div class="right-child">
            <span>{{ song.songAddedToPlaylist ? 'Remove from Playlist' : 'Add to Playlist' }}</span>
          </div>
        </div>
      </li>
      @if (song.spotify_id) {
        <li>
          <div class="li-container" (click)="openSpotifyTrack(song.spotify_id)" role="link" tabindex="0">
            <div class="left-child">
              <div class="w-[22px] h-[22px] hover:drop-shadow-plus-light
                      hover:dark:drop-shadow-plus-dark">
                <img imageLoader ngSrc="assets/spotify_logo.png" alt="Spotify logo" fill>
              </div>
            </div>
            <div class="right-child">
              <span>Play on Spotify</span>
            </div>
          </div>
        </li>
      }
    </ul>
  </section>
</ng-template>

<ng-template #brokenLinkMenu>
  <section (outsideClick)="hideTooltip()" class="more-menu-container">
    <ul>
      <li>
        <div (click)="reportIssue()" [style.width.px]="width()! - 32" class="li-container">
          <div class="left-child">
            <svg class="w-6 h-6 text-mypart_red-500 ml-[6px] hover:drop-shadow-plus-light
               hover:dark:drop-shadow-plus-dark" fill="currentColor" height="24px" id="Layer_1"
                 viewBox="0 0 511.999 511.999"
                 width="24px"
                 xmlns="http://www.w3.org/2000/svg">
              <g>
                <g>
                  <path d="M500.232,11.766c-15.688-15.687-41.218-15.688-56.909,0L340.459,114.63c-41.716-21.472-94.286-14.79-129.196,20.122
			                    L135.7,210.314c-34.91,34.91-41.594,87.481-20.122,129.196L11.767,443.323c-15.69,15.69-15.69,41.22,0,56.909
			                    c7.846,7.844,18.15,11.766,28.454,11.766s20.61-3.922,28.454-11.766l103.787-103.787c15.514,8.013,32.824,12.284,50.814,12.284
			                    c29.648,0,57.493-11.518,78.407-32.432c5.238-5.238,5.238-13.731,0-18.969l-37.939-37.939c-2.515-2.515-5.927-3.929-9.485-3.929
			                    c-3.557,0-6.97,1.414-9.485,3.929c-1.607,1.606-3.375,2.986-5.25,4.176c4.79-14.069,1.591-30.282-9.609-41.482
			                    c-11.2-11.199-27.413-14.398-41.482-9.608c1.188-1.875,2.57-3.644,4.176-5.25l75.562-75.562c1.388-1.388,2.883-2.602,4.451-3.663
			                    c-5.006,14.169-1.855,30.603,9.459,41.918c7.846,7.844,18.15,11.766,28.454,11.766c4.563,0,9.125-0.773,13.474-2.311
			                    c-1.073,1.58-2.294,3.074-3.673,4.453c-2.515,2.515-3.929,5.927-3.929,9.485c0,3.557,1.414,6.97,3.929,9.485l37.94,37.939
			                    c5.238,5.238,13.731,5.237,18.969,0c34.91-34.91,41.594-87.481,20.122-129.196L500.231,68.674
			                    C515.922,52.986,515.922,27.457,500.232,11.766z M253.147,346.729l19.333,19.333c-14.212,10.288-31.269,15.841-49.202,15.841
			                    c-10.638,0-20.967-1.957-30.576-5.695l21.785-21.785c2.883,0.444,5.819,0.677,8.793,0.677
			                    C233.992,355.101,244.251,352.185,253.147,346.729z M191.977,301.052c2.614-2.614,6.049-3.922,9.485-3.922
			                    c3.435,0,6.87,1.308,9.485,3.922c5.23,5.23,5.23,13.739,0,18.969L49.706,481.262c-5.229,5.23-13.739,5.23-18.969,0
			                    c-5.23-5.23-5.23-13.739,0-18.969L191.977,301.052z M249.202,172.692l-75.562,75.562c-10.796,10.796-16.743,25.168-16.743,40.468
			                    c0,2.974,0.233,5.909,0.677,8.792l-21.757,21.757c-11.711-30.131-5.435-65.699,18.851-89.987l75.563-75.562
			                    c16.387-16.387,37.912-24.58,59.438-24.58c10.378,0,20.752,1.913,30.552,5.726l-21.775,21.777
			                    C281.024,153.957,262.594,159.3,249.202,172.692z M367.005,271.526l-19.329-19.327c5.455-8.896,8.371-19.154,8.371-29.869
			                    c0-2.974-0.235-5.91-0.677-8.793l21.746-21.746C387.272,217.916,383.918,248.122,367.005,271.526z M481.263,49.705
			                    L320.021,210.947c-5.229,5.23-13.739,5.23-18.969,0s-5.23-13.741,0-18.971L462.292,30.737c5.231-5.23,13.741-5.231,18.971,0
			                    C486.493,35.967,486.493,44.476,481.263,49.705z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M420.56,400.641l-43.63-43.63c-5.238-5.238-13.731-5.238-18.969,0c-5.238,5.238-5.239,13.731,0,18.969l43.63,43.63
                          c2.62,2.62,6.052,3.929,9.485,3.929c3.432,0,6.866-1.31,9.485-3.929C425.798,414.373,425.799,405.879,420.56,400.641z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M476.759,327.83l-66.513-9.604c-7.33-1.057-14.135,4.025-15.193,11.359c-1.058,7.332,4.027,14.134,11.358,15.193
			                    l66.513,9.604c0.651,0.094,1.296,0.14,1.934,0.14c6.559,0,12.295-4.817,13.259-11.498
			                    C489.175,335.691,484.09,328.889,476.759,327.83z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M355.33,471.974l-9.604-66.512c-1.058-7.332-7.866-12.414-15.193-11.359c-7.332,1.06-12.417,7.86-11.359,15.193
			                    l9.604,66.512c0.964,6.681,6.7,11.498,13.259,11.498c0.638,0,1.285-0.046,1.934-0.139
			                    C351.304,486.108,356.389,479.307,355.33,471.974z"/>
                </g>
              </g>
            </svg>
          </div>
          <div class="right-child">
            <span>Report Issue</span>
          </div>
        </div>
      </li>
    </ul>
  </section>
</ng-template>
