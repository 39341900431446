<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <!-- Loading Overlay -->
  @if (isAffiliateLoading()) {
    <div
        class="absolute inset-0 flex items-center justify-center z-50 bg-black/20 dark:bg-black/40 backdrop-blur-[2px] rounded-lg">
      <div class="flex flex-col items-center gap-4">
        <!-- Animated Loading Spinner -->
        <div class="relative">
          <!-- Outer spinning ring -->
          <div
              class="w-16 h-16 border-4 border-mypart_red-500/20 dark:border-mypart_red-400/20 rounded-full animate-[spin_2s_linear_infinite]"></div>
          <!-- Inner spinning ring -->
          <div
              class="absolute top-0 left-0 w-16 h-16 border-4 border-transparent border-t-mypart_red-500 dark:border-t-mypart_red-400 rounded-full animate-[spin_1.2s_cubic-bezier(0.55,0.15,0.45,0.85)_infinite]"></div>
          <!-- Pulsing center dot -->
        </div>
      </div>
    </div>
  }

  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>

  <div class="p-6 pb-2 space-y-4 md:space-y-6 sm:p-8">
    <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
      Earn Money with Songhunt!
    </h1>
  </div>

  <div class="p-6 pt-0 text-center max-w-[600px]">
    <h4 class="mb-5 text-sm lg:text-lg font-normal text-gray-500 dark:text-gray-400">
      Join the Songhunt Affiliation program and earn 50% of all paid subscription revenue, for 12 months, from any
      users you refer!
      <br>Payments are paid out quarterly.
    </h4>

    @if (user()) {
      <p class="font-[600] text-sm lg:text-lg mt-2 text-gray-900 md:text-2xl dark:text-white">
        Copy your unique link to share with your network:</p>
      <mypart-social-sharing [hideShareButton]="true" [link]="user()!.refLink"
                             [showTooltip]="false"
                             source="affiliate link"></mypart-social-sharing>
    }

    <div class="flex items-center mt-4">
      <input [(ngModel)]="dontShowAgain" class="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500
      dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
             id="red-checkbox" type="checkbox"
             value="">
      <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="red-checkbox">
        Got it. Don't show this message again
      </label>
    </div>
  </div>
</div>
