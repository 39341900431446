<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>

  <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
      {{ title }}
    </h1>
    <h2 class="text-lg leading-tight tracking-tight text-gray-700 md:text-xl dark:text-gray-300 text-center">
      {{ artist }}
    </h2>
  </div>

  <div class="p-6 pt-0">
    @if (!noMetadata) {
      <div class="space-y-4">
        @for (metadata of songMetadata | keyvalue; track metadata.key) {
          <div class="flex flex-col">
            <span class="text-sm font-medium text-gray-900 dark:text-white">{{ metadata.key }}</span>
            <span class="text-sm text-gray-500 dark:text-gray-400">{{ metadata.value }}</span>
          </div>
        }
      </div>
    } @else {
      <div class="text-center">
        <p class="text-gray-500 dark:text-gray-400">No metadata available for this song.</p>
      </div>
    }
  </div>
</div>
