import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SocialSharingComponent} from './components/social-sharing/social-sharing.component';
import {SpinnerLoadingComponent} from './components/spinner-loading/spinner-loading.component';
import {TippyDirective} from '@ngneat/helipopper';
import {ResultItemComponent} from './components/result-item/result-item.component';
import {PipeModule} from '../pipes/pipe.module';
import {Mp3PlayerComponent} from './components/mp3-player/mp3-player.component';
import {PlayerComponent} from './components/player/player.component';
import {YouTubeIframeComponent} from './components/you-tube-iframe/you-tube-iframe.component';
import {SearchListSkeletonComponent} from './components/loading/search-list-skeleton/search-list-skeleton.component';
import {ResultSkeletonComponent} from './components/loading/result-skeleton/result-skeleton.component';
import {DirectiveModule} from '../directives/directive.module';
import {ModalContainerComponent} from './modal/modal-container/modal-container.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CdkDropList} from '@angular/cdk/drag-drop';
import {LoginErrorComponent} from './modal/login-error/login-error.component';
import {FilterOverloadComponent} from './modal/filter-overload/filter-overload.component';
import {YoutubeUrlWindowComponent} from './modal/youtube-url-window/youtube-url-window.component';
import {LoginRegisterComponent} from './modal/login-register/login-register.component';
import {YouTubeSuggestionsComponent} from './modal/you-tube-suggestions/you-tube-suggestions.component';
import {PurchaseSuccessComponent} from './modal/purchase-success/purchase-success.component';
import {SongMetadataComponent} from './modal/song-metadata/song-metadata.component';
import {LyricsComponent} from './modal/lyrics/lyrics.component';
import {ReportIssueModalComponent} from './modal/report-issue-modal/report-issue-modal.component';
import {AffiliationLinkComponent} from './modal/affiliation-link/affiliation-link.component';
import {NoResultsComponent} from './modal/no-results/no-results.component';
import {ImageLoaderDirective} from '../directives/image-loader.directive';
import {
  DifferentUserPreferencesComponent
} from './modal/different-user-preferences/different-user-preferences.component';

@NgModule({
  declarations: [
    SocialSharingComponent,
    SpinnerLoadingComponent,
    ResultItemComponent,
    Mp3PlayerComponent,
    PlayerComponent,
    YouTubeIframeComponent,
    SearchListSkeletonComponent,
    ResultSkeletonComponent,
    ModalContainerComponent,
    LoginErrorComponent,
    FilterOverloadComponent,
    YoutubeUrlWindowComponent,
    LoginRegisterComponent,
    YouTubeSuggestionsComponent,
    PurchaseSuccessComponent,
    SongMetadataComponent,
    LyricsComponent,
    ReportIssueModalComponent,
    AffiliationLinkComponent,
    NoResultsComponent,
    DifferentUserPreferencesComponent
  ],
  imports: [
    CommonModule,
    TippyDirective,
    PipeModule,
    NgOptimizedImage,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    CdkDropList,
    ImageLoaderDirective
  ],
  exports: [
    SocialSharingComponent,
    SpinnerLoadingComponent,
    ResultItemComponent,
    Mp3PlayerComponent,
    PlayerComponent,
    YouTubeIframeComponent,
    SearchListSkeletonComponent,
    ResultSkeletonComponent,
    ModalContainerComponent,
  ]
})
export class SharedModule {
}
