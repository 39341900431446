<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>
  <div class="p-6 text-center">
    <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          fill="currentColor"/>
    </svg>
    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      You have selected too many filters. Please remove some filters to continue.</h3>

    <div class="flex items-center mt-4">
      <input [(ngModel)]="dontShowAgain" class="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500
      dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
             id="red-checkbox" type="checkbox"
             value="">
      <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="red-checkbox">
        Got it. Don't show this message again
      </label>
    </div>
  </div>
</div>
