@if (preferences.length) {
  <div class="preferences-modal relative bg-white rounded-lg shadow dark:bg-gray-700">
    <div>
      <h2 class="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Different Preferences Detected</h2>
      <p class="mb-6 text-gray-500 dark:text-gray-400">Your preferences have changed. Please choose your preferred
        settings:</p>
    </div>

    <div class="preferences-list space-y-6 flex-1 overflow-y-auto">
      @for (pref of preferences; track pref.name) {
        <div class="preference-item">
          <div class="flex items-center justify-between flex-col sm:flex-row gap-8">
            <div class="flex-1 w-full">
              <p class="font-medium text-gray-900 dark:text-white w-full">{{ pref.name }}</p>
              @if (pref.name !== 'Theme') {
                <p class="text-xs text-gray-900 dark:text-white max-w-[80%]">{{ getSubtitle(pref.name) }}</p>
              }
            </div>

            <div class="toggle-switch">
              <div class="toggle-track" [class.active]="pref.selected === pref.options[1].value"
                   (click)="togglePreference(pref, pref.selected === pref.options[0].value ? pref.options[1].value : pref.options[0].value)">
                <div class="toggle-thumb">
                  <span
                      class="toggle-text">{{ pref.selected === pref.options[1].value ? pref.options[1].label : pref.options[0].label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="mt-8 flex justify-end">
      <button
          (click)="onApprove()"
          class="px-4 py-2 bg-mypart_red-500 text-white rounded hover:bg-mypart_red-600 transition-colors">
        Apply Changes
      </button>
    </div>
  </div>
}
