import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@UntilDestroy()
@Component({
  selector: 'mypart-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoginErrorComponent extends BaseModalComponent {
  constructor(modalService: ModalService) {
    super(modalService);
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }
}
