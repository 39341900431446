import {AfterViewInit, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {HttpReqService} from '../../../services/http-req.service';
import {ReportIssue} from '../../../interfaces/report-issue';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-report-issue-modal',
  templateUrl: './report-issue-modal.component.html',
  styleUrl: './report-issue-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ReportIssueModalComponent extends BaseModalComponent implements AfterViewInit {
  @Input() reportIssue!: ReportIssue;
  reportForm!: FormGroup;

  constructor(
    modalService: ModalService,
    private httpReqService: HttpReqService,
    private formBuilder: FormBuilder
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      issueType: ['', Validators.required],
      comments: ['']
    });
  }

  ngAfterViewInit() {
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
  }

  sendReport() {
    if (this.reportForm.valid && this.reportIssue) {
      this.setLoading(true);
      this.reportIssue.description = this.reportForm.get('issueType')!.value;
      this.reportIssue.comments = this.reportForm.get('comments')?.value;

      this.httpReqService.sendArtworkIssueReport(this.reportIssue)
        .subscribe({
          next: () => {
          },
          error: (e) => {
            console.log('sendReport', e);
          },
          complete: () => {
            this.setLoading(false);
            this.hideModal();
          }
        });
    }
  }

  override hideModal() {
    super.hideModal();
    this.reportForm.reset();
  }
}
