import {
  AfterViewInit,
  Component,
  effect,
  EnvironmentInjector,
  inject,
  runInInjectionContext,
  signal
} from '@angular/core';
import {UserStateService} from '../../../services/user-state.service';
import {AffiliationTimerService} from '../../../services/affiliation-timer.service';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';
import {ModalType} from '../../../interfaces/modal-types.enum';
import {HttpReqService} from '../../../services/http-req.service';

@Component({
  selector: 'mypart-affiliation-link',
  templateUrl: './affiliation-link.component.html',
  styleUrl: './affiliation-link.component.scss',
  standalone: false
})
export class AffiliationLinkComponent extends BaseModalComponent implements AfterViewInit {
  dontShowAgain: boolean = false;
  user = this.userStateService.user;
  isAffiliateLoading = signal(true);

  private loginModalId: string | undefined = undefined;
  private readonly environmentInjector = inject(EnvironmentInjector); // ✅ Get injector

  constructor(
    modalService: ModalService,
    private userStateService: UserStateService,
    private affiliationTimerService: AffiliationTimerService,
    private httpReqService: HttpReqService
  ) {
    super(modalService);
  }

  ngAfterViewInit() {
    this.setLoading(true);
    this.initModal({
      backdrop: 'dynamic',
      closable: false
    });
    runInInjectionContext(this.environmentInjector, () => {
      effect(
        () => {
          if (this.user() && this.loginModalId) {
            // Closing the login modal if user exists and modal is open
            this.modalService.close(this.loginModalId);
          }
          setTimeout(() => {
            if (!this.user()) {
              this.loginModalId = this.modalService.open(ModalType.LOGIN_REGISTER, {
                inputs: {
                  hideCloseButton: true
                }
              });
            }
            this.isAffiliateLoading.set(false);
          }, 1500);
        });
    });
  }

  override hideModal() {
    super.hideModal();
    if (this.dontShowAgain) {
      this.httpReqService.updateUserPreference('dontShowAffiliationAgain', 'true')
        .subscribe({
          next: data => {
            this.affiliationTimerService.stopTimer();
          }
        });
    }
  }
}
