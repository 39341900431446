import {Injectable} from '@angular/core';
import {UserStateService} from './user-state.service';
import {ModalService} from './modal.service';
import {ModalType} from '../interfaces/modal-types.enum';

@Injectable({
  providedIn: 'root'
})
export class AffiliationTimerService {
  private startTimeKey = 'startTime';
  private durationInMinutes = 15;
  private intervalId: any;
  private isTimerRunning = false; // Flag to track if the timer is running

  constructor(private modalService: ModalService,
              private userStateService: UserStateService) {
  }

  startTimer() {
    const user = this.userStateService.user();
    if (user && user.preferences['dontShowAffiliationAgain'] === 'true') {
      this.stopTimer();
      return;
    }
    // Check if the timer is already running
    if (this.isTimerRunning) {
      return; // If running, just return and do nothing
    }

    // Mark the timer as running
    this.isTimerRunning = true;

    // Check if startTime is stored in localStorage; if not, initialize it
    const startTime = localStorage.getItem(this.startTimeKey);
    if (!startTime) {
      localStorage.setItem(this.startTimeKey, Date.now().toString());
    }

    // Start the interval to check the time
    this.checkTime();
    this.intervalId = setInterval(() => this.checkTime(), 1000);
  }

  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.isTimerRunning = false; // Mark the timer as not running
    }
  }

  private checkTime() {
    const startTime = localStorage.getItem(this.startTimeKey);
    if (startTime) {
      const elapsedTime = (Date.now() - Number(startTime)) / 1000 / 60; // in minutes
      if (elapsedTime >= this.durationInMinutes) {
        const user = this.userStateService.user();
        if (user) {
          if (user.preferences['dontShowAffiliationAgain'] === 'true') {
            this.stopTimer();
          } else {
            this.modalService.open(ModalType.AFFILIATION_LINK);
          }
        }
        localStorage.setItem(this.startTimeKey, Date.now().toString()); // Reset the timer
      }
    }
  }
}
