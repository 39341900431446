import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'mypart-search-list-skeleton',
  templateUrl: './search-list-skeleton.component.html',
  styleUrls: ['./search-list-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchListSkeletonComponent {

}
