import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SpotifyBaseApiService} from './spotify-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SpotifyUserAccountService extends SpotifyBaseApiService {
  authenticateUser() {
    // Clear previous user data
    this.spotifyTokensService.clearCredentials();

    this.generateCodeChallengeObservable().subscribe(codeChallenge => {
      const authEndpoint = environment.spotifyAuthEndpoint;
      const scopes = 'user-read-private playlist-modify-public playlist-read-collaborative playlist-read-private playlist-modify-private';
      window.location.href = `${authEndpoint}?client_id=${encodeURIComponent(environment.spotifyAppClientId)}&response_type=code&redirect_uri=${encodeURIComponent(environment.spotifyRedirectUri)}&scope=${encodeURIComponent(scopes)}&code_challenge_method=S256&code_challenge=${codeChallenge}&show_dialog=true`;
    });
  }

  handleAuthenticationCallback(code: string): Observable<any> {
    const tokenEndpoint = environment.spotifyTokenEndpoint;
    const codeVerifier = this.spotifyTokensService.getCodeVerifierAddress();

    const body = new URLSearchParams();
    body.set('client_id', environment.spotifyAppClientId);
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', environment.spotifyRedirectUri);
    body.set('code_verifier', codeVerifier || '');

    return this.http.post<any>(tokenEndpoint, body.toString(), {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  saveUserData(accessToken: string, refreshToken: string, email: string) {
    this.spotifyTokensService.setTokens(accessToken, refreshToken, email);
    this.setIsSpotifyConnected();
    return this.getUserProfile();
  }

  setUserId(clientId: string) {
    this.spotifyTokensService.setUserId(clientId);
  }

  setIsSpotifyConnected() {
    this.spotifyTokensService.setIsSpotifyConnected();
  }

  getConnectedUserEmailAddress() {
    return this.spotifyTokensService.getConnectedUserEmailAddress();
  }

  disconnectAccount() {
    this.spotifyTokensService.clearCredentials();
  }

  private getUserProfile(): Observable<any> {
    const accessToken = this.spotifyTokensService.getAccessToken();
    if (!accessToken) {
      throw new Error('No access token available');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get('https://api.spotify.com/v1/me', {headers: headers});
  }

  private generateCodeVerifier(): string {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  private generateCodeChallengeObservable(): Observable<string> {
    return new Observable(observer => {
      const codeVerifier = this.generateCodeVerifier();
      this.spotifyTokensService.setCodeVerifier(codeVerifier);
      this.generateCodeChallenge(codeVerifier).then(codeChallenge => {
        observer.next(codeChallenge);
        observer.complete();
      }).catch(error => observer.error(error));
    });
  }
}
