export function setAffiliateCode(code: string) {
  const expirationTime = Date.now() + (1 * 60 * 60 * 1000); // 1 hour
  const data = {
    code: code,
    expiresAt: expirationTime
  };
  localStorage.setItem('not_used_affiliate_code', JSON.stringify(data));
}

export function getAffiliateCode(): string | null {
  const item = localStorage.getItem('not_used_affiliate_code');

  if (!item) {
    // No item stored
    return null;
  }

  const parsedItem = JSON.parse(item);
  const currentTime = Date.now();

  if (currentTime > parsedItem.expiresAt) {
    // Item expired
    localStorage.removeItem('not_used_affiliate_code');
    return null;
  }

  // Item is valid
  return parsedItem.code;
}

export function removeAffiliateCode() {
  const item = localStorage.getItem('not_registered_affiliate_code');
  if (item) {
    localStorage.removeItem('not_registered_affiliate_code');
  }
}
