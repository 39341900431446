<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>

  <div [ngClass]="{'bg-green-600': isSuccessSignal(), 'bg-red-600': !isSuccessSignal()}"
       class="container">
    <div class="avatar">
      @if (isSuccessSignal()) {
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      } @else {
        <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle cx="26" cy="26" r="25" fill="none"/>
          <path fill="none" d="M16 16 36 36 M36 16 16 36"/>
        </svg>
      }
    </div>
    <p class="title">
      @if (isSuccessSignal()) {
        Payment Successful
      } @else {
        Payment Failed
      }
    </p>
    <p class="subtitle">
      @if (isSuccessSignal()) {
        Thank you for your purchase!
      } @else {
        Please try again or contact support.
      }
    </p>
  </div>
</div>
