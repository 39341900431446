export function manuallyAddScript(email: string): void {
  if (!email) {
    console.error('Email is required to add the script.');
    return;
  }

  // Check if the script already exists to prevent duplicates
  const existingScript = document.querySelector('script[data-manual-script="hj-identifier"]');
  if (existingScript) {
    console.warn('The script is already added.');
    return;
  }

  // Create the script element
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.dataset['manualScript'] = 'hj-identifier';

  // Add the script content
  script.innerHTML = `
    var userId = "${email}";
    window.hj && window.hj('identify', userId, {});
  `;

  // Append the script to the end of the body
  document.body.appendChild(script);
  console.log('Script successfully added to the end of the body.');
}
