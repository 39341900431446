<div [ngClass]="{'md:w-fit md:min-w-[800px]': modalState === 'register',
                 'md:!min-w-[32rem]': currentStep === 2}"
     class="relative w-[90vw] md:max-w-lg md:min-w-[32rem] transition-all">
  @if (isCurrentlyLoading()) {
    <div class="absolute inset-0 flex items-center justify-center z-50">
      <mypart-spinner-loading></mypart-spinner-loading>
    </div>
  }

  <div [ngClass]="{'blur-sm': isCurrentlyLoading()}"
       class="relative bg-white rounded-lg shadow dark:bg-gray-700 overflow-y-auto h-fit max-h-[75vh] lg:max-h-[92vh]">
    <!-- Close button -->
    @if (!hideCloseButton) {
      <button (click)="hideModal()" type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
                    rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
        <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2"/>
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
    }

    <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0
    dark:bg-gray-800 dark:border-gray-700 h-full">
      @switch (modalState) {
        @case ('login') {
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center font-bold">
              Log in
            </h1>
            <form class="space-y-4 md:space-y-6 !mt-2">
              <div class="relative z-0 w-full group">
                <input [formControl]="loginEmail"
                       class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                       border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                       focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"
                       id="floating_email" name="floating_email" placeholder="Email"
                       required
                       type="email">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg aria-hidden="true" class="w-4 h-4 text-gray-500 dark:text-gray-400"
                       fill="currentColor" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                    <path
                        d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                  </svg>
                </div>
                @if (loginEmail.invalid && (loginEmail.dirty || !loginEmail.untouched)) {
                  @if (loginEmail.hasError('required')) {
                    <span
                        class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Email is required.</span>
                  }
                  @if (loginEmail.hasError('email')) {
                    <span class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Please enter a valid email.</span>
                  }
                }
              </div>

              <div class="relative z-0 w-full group">
                <input [formControl]="loginPassword"
                       class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                       border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                        focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"
                       id="floating_password"
                       name="floating_password" placeholder="Password" required
                       type="{{loginShowPassword ? 'text' : 'password'}}">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5">
                  @if (loginShowPassword) {
                    <svg (click)="loginShowPassword = false" aria-hidden="true"
                         class="w-4 h-4 text-gray-500 dark:text-gray-400 cursor-pointer"
                         fill="currentColor"
                         stopPropagation viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                    </svg>
                  } @else {
                    <svg (click)="loginShowPassword = true" aria-hidden="true"
                         class="w-4 h-4 text-gray-500 dark:text-gray-400 cursor-pointer"
                         fill="currentColor"
                         stopPropagation viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314
               0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"/>
                    </svg>
                  }
                </div>
                @if (loginPassword.invalid && (loginPassword.dirty || !loginPassword.untouched) && loginPassword.hasError('required')) {
                  <span
                      class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Password is required</span>
                }
              </div>

              <div class="flex items-center justify-end !mt-0">
                <a class="text-sm font-medium text-gray-500 dark:text-gray-300 hover:underline"
                   href="mailto:songhunt@mypart.com?subject=Forgot My Password - (Enter Your Email)&body=Hello Songhunt support team.%0D%0A%0D%0AI forgot my password."
                   target="_blank">Forgot password?</a>
              </div>
              <button class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300
                          font-medium rounded-lg text-sm px-5 py-2.5 text-center" stopPropagation
                      (click)="login()">
                Log in
              </button>
              <p class="sm:text-sm lg:text-lg text-gray-500 dark:text-gray-400">
                Don’t have an account yet?
                <a (click)="switchModal()" stopPropagation
                   class="cursor-pointer font-medium text-gray-500 dark:text-gray-300 hover:underline">Create an
                  account</a>
              </p>
            </form>
          </div>
        }
        @case ('register') {
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <!-- Title -->
            <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white font-bold text-center">
              {{ getTitle() }}
            </h1>

            <h3 class="!text-lg leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center
             !my-4">
              {{ currentStep === 1 ? 'Step 1 - Choose Package' : isUpgradeMode ? 'Step 2 - Upgrade Account' : 'Step 2 - Create Account' }}
            </h3>

            @switch (currentStep) {
              @case (1) {
                <div class="space-y-4 md:space-y-6">
                  <div class="pricing-table !mt-8">

                    <!-- Desktop Version (md and up) -->
                    <div class="hidden md:flex w-full justify-center gap-8">
                      <!-- Original Explorer Package -->
                      <div class="table-item free cursor-pointer"
                           [class.selected]="selectedPlan === 'free'"
                           (click)="selectPackage('free')"
                           [ngClass]="{'hidden': onlyHunter}">
                        <div class="price-tag">
                          <div class="plan-name">Explorer</div>
                          <div class="price">
                            <span class="currency">$</span>0
                            <span class="period">/mo</span>
                          </div>
                          <div class="chosen-badge">Selected</div>
                          @if (isUpgradeMode) {
                            <div class="current-badge">Current Plan</div>
                          }
                        </div>
                        <div class="content">
                          <div class="feature-list">
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Song Searches
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Filters
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              3 Playlist Exports
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Public Playlists
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Add 3 New Songs to Songhunt
                            </div>
                          </div>
                          @if (!isUpgradeMode) {
                            <button (click)="selectPackageAndContinue('free')"
                                    class="choose-btn mt-4 w-full py-2 text-white bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors duration-200">
                              Choose Explorer
                            </button>
                          }
                        </div>
                      </div>
                      <!-- Original Hunter Package -->
                      <div class="table-item paid cursor-pointer"
                           [class.selected]="selectedPlan === 'paid'"
                           (click)="selectPackage('paid')"
                           [ngClass]="{'hidden': onlyExplorer}">
                        <div class="price-tag">
                          <div class="plan-name">Hunter</div>
                          <div class="price">
                            @if (coupon()?.price !== null) {
                              <div class="flex flex-col items-center justify-center space-y-1">
                                <span class="text-gray-400 text-sm sm:text-base line-through">$5 per month</span>
                                <span>{{ priceAfterDiscount() }}</span>
                              </div>
                            } @else {
                              <span class="currency">$</span>5
                              <span class="period">/mo</span>
                            }
                          </div>
                          <div class="chosen-badge">Selected</div>
                        </div>
                        <div class="content">
                          <div class="feature-list">
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Song Searches
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Filters
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Playlist Exports
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Private Playlist Linking
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Add Unlimited New Songs
                            </div>
                          </div>
                          <button (click)="selectPackageAndContinue('paid')"
                                  class="choose-btn mt-4 w-full py-2 text-white bg-mypart_red-500 hover:bg-mypart_red-600 rounded-lg transition-colors duration-200">
                            Choose Hunter
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Mobile Version (sm and below) -->
                    <div class="md:hidden w-full">
                      @if (!isUpgradeMode) {
                        <div class="pricing-table">
                          <!-- Explorer Package -->
                          <div class="table-item free"
                               [class.active]="selectedPlan === 'free'"
                               [ngClass]="{'hidden': onlyHunter}">
                            <div class="price-tag">
                              <div class="plan-name">Explorer</div>
                              <div class="price">
                                <span class="currency">$</span>0
                                <span class="period">/mo</span>
                              </div>
                              <div class="chosen-badge">Selected</div>
                            </div>
                            <div class="content">
                              <div class="feature-list">
                                <div class="feature">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"/>
                                  </svg>
                                  Unlimited Song Searches
                                </div>
                                <div class="feature">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"/>
                                  </svg>
                                  Unlimited Filters
                                </div>
                                <div class="feature">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"/>
                                  </svg>
                                  3 Playlist Exports
                                </div>
                                <div class="feature">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"/>
                                  </svg>
                                  Public Playlists
                                </div>
                                <div class="feature">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"/>
                                  </svg>
                                  Add 3 New Songs to Songhunt
                                </div>
                              </div>
                              <button (click)="selectPackageAndContinue('free')"
                                      class="choose-btn mt-4 w-full py-2 text-white bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors duration-200">
                                Choose Explorer
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                      <!-- Hunter Package -->
                      <div class="table-item paid"
                           [class.active]="selectedPlan === 'paid'"
                           [ngClass]="{'hidden': onlyExplorer}">
                        <div class="price-tag">
                          <div class="plan-name">Hunter</div>
                          <div class="price">
                            @if (coupon()?.price !== null) {
                              <div class="flex flex-col items-center justify-center space-y-1">
                                <span class="text-gray-400 text-sm sm:text-base line-through">$5 per month</span>
                                <span>{{ priceAfterDiscount() }}</span>
                              </div>
                            } @else {
                              <span class="currency">$</span>5
                              <span class="period">/mo</span>
                            }
                          </div>
                          <div class="chosen-badge">Selected</div>
                        </div>
                        <div class="content">
                          <div class="feature-list">
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Song Searches
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Filters
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Unlimited Playlist Exports
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Private Playlist Linking
                            </div>
                            <div class="feature">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Add Unlimited New Songs
                            </div>
                          </div>
                          <button (click)="selectPackageAndContinue('paid')"
                                  class="choose-btn mt-4 w-full py-2 text-white bg-mypart_red-500 hover:bg-mypart_red-600 rounded-lg transition-colors duration-200">
                            Choose Hunter
                          </button>
                        </div>
                      </div>

                      @if (!isUpgradeMode) {
                        <!-- Custom Package Switcher -->
                        <div class="package-switcher">
                          @if (selectedPlan === 'paid') {
                            <div class="switcher-text explorer" (click)="selectPlan('free')" @slideOut>
                              Switch to Explorer
                            </div>
                          }
                          @if (selectedPlan === 'free') {
                            <div class="switcher-text hunter" (click)="selectPlan('paid')" @slideOut>
                              Switch to Hunter
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>

                  <!-- Coupon Section -->
                  <div class="w-full max-w-md mx-auto mt-8">
                    <form [class.hidden]="hasAffiliateCode || onlyExplorer || isWhiteLabel()"
                          class="space-y-4 md:space-y-6">
                      <div class="relative z-0 w-full group">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-2.5 pointer-events-none">
                          <svg class="w-6 h-6 text-gray-500 dark:text-gray-400" aria-hidden="true"
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M10 21v-9m3-4H7.5a2.5 2.5 0 1 1 0-5c1.5 0 2.875 1.25 3.875 2.5M14 21v-9m-9 0h14v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-8Z"/>
                          </svg>
                        </div>
                        <input [formControl]="promotionCode" (keyup.enter)="searchPromotionCode()"
                               class="block py-2.5 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                                  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                                  focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10 pr-[3rem]"
                               placeholder="Enter coupon code" type="text">
                        <div
                            class="absolute inset-y-0 right-0 flex items-center pr-2.5 cursor-pointer border-l-2 border-gray-400"
                            (click)="searchPromotionCode()">
                          <svg class="w-6 h-6 text-gray-500 dark:text-gray-400 ml-2" aria-hidden="true"
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                          </svg>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                @if (!isUpgradeMode) {
                  <!-- Already have an account -->
                  <div class="sm:text-sm lg:text-lg text-gray-500 dark:text-gray-400 mt-8 text-center">
                    Already have an account?
                    <a (click)="switchModal()"
                       class="cursor-pointer font-medium text-gray-500 dark:text-gray-300 hover:underline">Log in</a>
                  </div>
                }
              }
              @case (2) {
                <div class="space-y-4 md:space-y-6 max-w-md mx-auto">
                  <form class="space-y-4 md:space-y-6" (ngSubmit)="onRegister()">
                    <!-- Name Input -->
                    <div class="relative z-0 w-full group">
                      <input [formControl]="regName" (keyup.enter)="onRegister()" [readOnly]="isUpgradeMode"
                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                                border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                                focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"
                             placeholder="Name" type="text">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"
                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"/>
                        </svg>
                      </div>
                      @if (regName.invalid && (regName.dirty || !regName.untouched) && regName.hasError('required')) {
                        <span
                            class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Name is required</span>
                      }
                    </div>
                    <!-- Email Input -->
                    <div class="relative z-0 w-full group">
                      <input [formControl]="regEmail" (keyup.enter)="onRegister()" [readOnly]="isUpgradeMode"
                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                                border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                                focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"
                             placeholder="Email" type="email">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"
                             fill="currentColor" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                          <path
                              d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                        </svg>
                      </div>
                      @if (regEmail.invalid && (regEmail.dirty || !regEmail.untouched) && regEmail.hasError('required')) {
                        <span
                            class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Email is required</span>
                      }
                    </div>
                    @if (!isUpgradeMode) {
                      <!-- Password Input -->
                      <div class="relative w-full group">
                        <input [formControl]="regPassword"
                               [type]="regShowPassword ? 'text' : 'password'"
                               class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500
                focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"
                               placeholder="Password">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"
                               fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                          </svg>
                        </div>
                        <button (click)="regShowPassword = !regShowPassword"
                                class="absolute right-0 top-1/2 -translate-y-1/2 p-2"
                                type="button">
                          <svg class="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            @if (!regShowPassword) {
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0
                 016 0z M2.458 12C3.732 7.943 7.523 5
                 12 5c4.478 0 8.268 2.943 9.542
                 7-1.274 4.057-5.064 7-9.542
                 7-4.477 0-8.268-2.943-9.542-7z"/>
                            } @else {
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13.875 18.825A10.05 10.05
                 0 0112 19c-4.478 0-8.268-2.943-9.543-7
                 a9.97 9.97 0 011.563-3.029m5.858.908a3
                 3 0 114.243 4.243M9.878 9.878l4.242
                 4.242M9.88 9.88l-3.29-3.29m7.532
                 7.532l3.29 3.29M3 3l3.59
                 3.59m0 0A9.953 9.953 0 0112 5c4.478
                 0 8.268 2.943 9.543 7a10.025 10.025
                 0 01-4.132 5.411m0 0L21 21"/>
                            }
                          </svg>
                        </button>
                      </div>
                    }
                    @if (regPassword.invalid && (regPassword.dirty || !regPassword.untouched) && regPassword.hasError('required')) {
                      <span
                          class="!m-0 absolute text-xs md:text-sm text-red-600 dark:text-red-500">Password is required</span>
                    }

                    <!--                    With password pattern -->
                    <!--                    <div class="relative w-full group">-->
                    <!--                      <input [formControl]="regPassword" (click)="showPasswordPopover = true"-->
                    <!--                             (keydown)="showPasswordPopover = true" (focus)="showPasswordPopover = true"-->
                    <!--                             (blur)="showPasswordPopover = false"-->
                    <!--                             [type]="regShowPassword ? 'text' : 'password'"-->
                    <!--                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2-->
                    <!--                border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500-->
                    <!--                focus:outline-none focus:ring-0 focus:border-blue-600 peer pl-10"-->
                    <!--                             placeholder="Password">-->
                    <!--                      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">-->
                    <!--                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"-->
                    <!--                             fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                          <path-->
                    <!--                              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>-->
                    <!--                        </svg>-->
                    <!--                      </div>-->
                    <!--                      <button (click)="regShowPassword = !regShowPassword"-->
                    <!--                              class="absolute right-0 top-1/2 -translate-y-1/2 p-2"-->
                    <!--                              type="button">-->
                    <!--                        <svg class="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                    <!--                          @if (!regShowPassword) {-->
                    <!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
                    <!--                                  d="M15 12a3 3 0 11-6 0 3 3 0-->
                    <!--                 016 0z M2.458 12C3.732 7.943 7.523 5-->
                    <!--                 12 5c4.478 0 8.268 2.943 9.542-->
                    <!--                 7-1.274 4.057-5.064 7-9.542-->
                    <!--                 7-4.477 0-8.268-2.943-9.542-7z"/>-->
                    <!--                          } @else {-->
                    <!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
                    <!--                                  d="M13.875 18.825A10.05 10.05-->
                    <!--                 0 0112 19c-4.478 0-8.268-2.943-9.543-7-->
                    <!--                 a9.97 9.97 0 011.563-3.029m5.858.908a3-->
                    <!--                 3 0 114.243 4.243M9.878 9.878l4.242-->
                    <!--                 4.242M9.88 9.88l-3.29-3.29m7.532-->
                    <!--                 7.532l3.29 3.29M3 3l3.59-->
                    <!--                 3.59m0 0A9.953 9.953 0 0112 5c4.478-->
                    <!--                 0 8.268 2.943 9.543 7a10.025 10.025-->
                    <!--                 0 01-4.132 5.411m0 0L21 21"/>-->
                    <!--                          }-->
                    <!--                        </svg>-->
                    <!--                      </button>-->

                    <!--                      &lt;!&ndash; Popover above the password input &ndash;&gt;-->
                    <!--                      @if ((regPassword.dirty || regPassword.touched) && showPasswordPopover) {-->
                    <!--                        <div class="absolute bottom-full left-0 w-64 bg-white dark:bg-gray-700 shadow-xl p-3 rounded-lg-->
                    <!--                              transform -translate-y-2 transition-opacity duration-200 z-50 text-sm">-->
                    <!--                          &lt;!&ndash; Arrow pointing down &ndash;&gt;-->
                    <!--                          <div class="absolute bottom-0 left-8 w-0 h-0 border-l-8 border-r-8 border-t-8 border-t-white-->
                    <!--                              dark:border-t-gray-700 border-l-transparent border-r-transparent transform translate-y-full">-->
                    <!--                          </div>-->

                    <!--                          &lt;!&ndash; Password requirements &ndash;&gt;-->
                    <!--                          <div class="text-black dark:text-white flex items-center space-x-2 mb-1">-->
                    <!--                            <span>-->
                    <!--                              <svg class="w-4 h-4 text-red-500" [ngClass]="{'!text-green-500': passwordHasMinLength}"-->
                    <!--                                   viewBox="0 0 24 24" fill="none"-->
                    <!--                                   stroke="currentColor"-->
                    <!--                                   stroke-width="2" stroke-linecap="round" stroke-linejoin="round">-->

                    <!--                                &lt;!&ndash; Checkmark path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="!passwordHasMinLength"-->
                    <!--                                      class="transition-all duration-300 ease-in-out"-->
                    <!--                                      d="M5 13l4 4L19 7" stroke="currentColor"></path>-->

                    <!--                                &lt;!&ndash; X path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="passwordHasMinLength"-->
                    <!--                                      class="transition-all duration-300 ease-in-out absolute top-0 left-0"-->
                    <!--                                      d="M6 18L18 6M6 6l12 12" stroke="currentColor"></path>-->
                    <!--                              </svg>-->
                    <!--                            </span>-->
                    <!--                            <span>At least 6 characters</span>-->
                    <!--                          </div>-->
                    <!--                          <div class="text-black dark:text-white flex items-center space-x-2 mb-1">-->
                    <!--                            <span>-->
                    <!--                              <svg class="w-4 h-4 text-red-500" [ngClass]="{'!text-green-500': passwordHasNumber}"-->
                    <!--                                   viewBox="0 0 24 24" fill="none"-->
                    <!--                                   stroke="currentColor"-->
                    <!--                                   stroke-width="2" stroke-linecap="round" stroke-linejoin="round">-->

                    <!--                                &lt;!&ndash; Checkmark path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="!passwordHasNumber"-->
                    <!--                                      class="transition-all duration-300 ease-in-out"-->
                    <!--                                      d="M5 13l4 4L19 7" stroke="currentColor"></path>-->

                    <!--                                &lt;!&ndash; X path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="passwordHasNumber"-->
                    <!--                                      class="transition-all duration-300 ease-in-out absolute top-0 left-0"-->
                    <!--                                      d="M6 18L18 6M6 6l12 12" stroke="currentColor"></path>-->
                    <!--                              </svg>-->
                    <!--                            </span>-->
                    <!--                            <span>At least one number</span>-->
                    <!--                          </div>-->
                    <!--                          <div class="text-black dark:text-white flex items-center space-x-2">-->
                    <!--                            <span>-->
                    <!--                              <svg class="w-4 h-4 text-red-500" [ngClass]="{'!text-green-500': passwordHasLowercase}"-->
                    <!--                                   viewBox="0 0 24 24" fill="none"-->
                    <!--                                   stroke="currentColor"-->
                    <!--                                   stroke-width="2" stroke-linecap="round" stroke-linejoin="round">-->

                    <!--                                &lt;!&ndash; Checkmark path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="!passwordHasLowercase"-->
                    <!--                                      class="transition-all duration-300 ease-in-out"-->
                    <!--                                      d="M5 13l4 4L19 7" stroke="currentColor"></path>-->

                    <!--                                &lt;!&ndash; X path &ndash;&gt;-->
                    <!--                                <path [class.opacity-0]="passwordHasLowercase"-->
                    <!--                                      class="transition-all duration-300 ease-in-out absolute top-0 left-0"-->
                    <!--                                      d="M6 18L18 6M6 6l12 12" stroke="currentColor"></path>-->
                    <!--                              </svg>-->
                    <!--                            </span>-->
                    <!--                            <span>At least one lowercase letter</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      }-->
                    <!--                    </div>-->

                    <!-- Privacy Policy -->
                    <div class="text-xs text-gray-500 dark:text-gray-400 mt-2 w-full">
                      <p>By registering, you agree to receive emails from us, including updates, newsletters,
                        and promotions. You can unsubscribe at any time by following the link in our emails. For
                        details, see our
                        <a href="https://www.mypart.net/faq" class="underline" target="_blank">Privacy Policy</a>
                      </p>
                    </div>

                    <!-- Action Buttons -->
                    <div class="flex justify-between items-center gap-4 !mt-14">
                      <button (click)="goBackToPackages()" type="button"
                              class="flex-1 text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:ring-gray-300
                                 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:text-white
                                 dark:hover:bg-gray-700 transition-colors duration-200">
                        Back
                      </button>
                      <button type="submit"
                              class="flex-1 text-white bg-mypart_red-500 hover:bg-mypart_red-600 focus:ring-4
                                 focus:ring-mypart_red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                 transition-colors duration-200">
                        {{ isUpgradeMode ? 'Upgrade' : 'Register' }}
                      </button>
                    </div>
                  </form>
                </div>

                @if (!isUpgradeMode) {
                  <!-- Already have an account -->
                  <div class="text-sm text-gray-500 dark:text-gray-400 mt-8 text-center">
                    Already have an account?
                    <a (click)="switchModal()"
                       class="cursor-pointer font-medium text-gray-500 dark:text-gray-300 hover:underline">Sign
                      in</a>
                  </div>
                }
              }
            }
          </div>
        }
      }
    </div>
  </div>
</div>
