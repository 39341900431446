<div
    class="animate-pulse sm:max-w-sm shadow hover:shadow-md rounded dark:bg-zinc-900 hover:shadow-red-950-md p-2 sm:p-0"
    role="status">
  <div [ngStyle]="{height: width - 16 + 'px'}"
       class="relative flex shadow-xl items-center justify-center mb-4 bg-gray-300 dark:bg-gray-700">
    <!--      <svg aria-hidden="true" class="w-10 h-10 text-gray-200 dark:text-gray-600" fill="currentColor"-->
    <!--           viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">-->
    <!--        <path-->
    <!--            d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>-->
    <!--        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>-->
    <!--      </svg>-->
  </div>

  <div class="flex items-center justify-between mt-4 space-x-3">
    <div>
      <div class="h-2 sm:h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 sm:w-48 mb-4"></div>
      <div class="w-16 sm:w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
    <!--    <svg class="block md:hidden w-10 h-10 text-gray-600 dark:text-gray-400" fill="currentColor"-->
    <!--         id="eFKx9TnOvpk1"-->
    <!--         shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 48 48"-->
    <!--         xmlns="http://www.w3.org/2000/svg">-->
    <!--      <ellipse fill="#374151" rx="2.771384" ry="2.421598" stroke-width="0"-->
    <!--               transform="translate(23.996224 34)"/>-->
    <!--      <ellipse fill="#374151" rx="2.771384" ry="2.421598" stroke-width="0"-->
    <!--               transform="translate(23.996224 24)"/>-->
    <!--      <ellipse fill="#374151" rx="2.771384" ry="2.421598" stroke-width="0"-->
    <!--               transform="translate(23.996224 14)"/>-->
    <!--    </svg>-->

    <div class="hidden md:block h-[48px] w-[48px] bg-gray-300 rounded dark:bg-gray-700"></div>
  </div>
  <span class="sr-only">Loading...</span>
</div>
