import {Injectable, NgZone, signal} from '@angular/core';
import {HttpClient, HttpContext, HttpContextToken, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  catchError,
  combineLatest,
  EMPTY,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  takeUntil,
  takeWhile,
  tap,
  throwError,
  TimeoutError,
  timer
} from 'rxjs';
import {switchMap, timeout} from 'rxjs/operators';
import param from 'jquery-param';
import {Filter} from '../interfaces/filter';
import {Artwork} from '../interfaces/artwork';
import {SearchResultsOs, Song} from '../interfaces/search-results-os';
import {FinalSong, SongMetadata} from '../interfaces/final-song';
import {GlobalStateService} from './global-state.service';
import {environment} from '../../environments/environment.prod';
import {FiltersStateService} from './filters-state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NGX_LOADING_BAR_IGNORED} from '@ngx-loading-bar/http-client';
import {User} from '../interfaces/user';
import {YouTubeSuggestions} from '../interfaces/youtube-suggestions';
import {UserStateService} from './user-state.service';
import {SpotifyUserAccountService} from './spotify/spotify-user-account.service';
import {CuratedPlaylist} from '../interfaces/curated-playlist';
import {PrompterResult} from '../interfaces/prompter-result';
import {FeaturedArtist} from '../interfaces/featured-artist';
import {ReportIssue} from '../interfaces/report-issue';
import {AffiliationTimerService} from './affiliation-timer.service';

const RETRY_COUNT = new HttpContextToken<number>(() => 1);

@Injectable({
  providedIn: 'root'
})
export class HttpReqService {
  clearArtistFromUrl = signal(false);
  artistFromUrl = signal<string | undefined>(undefined);

  clearSongFromUrl = signal<boolean>(false);
  songFromUrl = signal<string | undefined>(undefined);

  searchOrigin: 'photo_upload' | 'video_upload' | 'filter_click' | 'free_text' | 'url' | 'playlist_clicked' = 'url';

  readonly httpPostOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
    withCredentials: true,
    observe: 'response' as 'response'
  };

  readonly eventHttpHeaders = {
    headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
    withCredentials: true,
    context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
  };

  private readonly fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
  private readonly defaultLargeImage: string = 'https://d382nvfdu38z2f.cloudfront.net/Static/Songhunt/default_large.webp';
  private readonly defaultSmallImage: string = 'https://d382nvfdu38z2f.cloudfront.net/Static/Songhunt/default_small.webp';

  private userDetailsSubscription: Subscription | undefined;
  private cancelOngoingRequest$ = new Subject<void>();
  private abortController: AbortController | undefined;
  private currentAbort?: () => void;  // Store the current abort function
  private navigateQueryString: string | undefined; // this will prevent multiple calls with same filters

  constructor(private httpClient: HttpClient,
              private globalStateService: GlobalStateService,
              private filtersStateService: FiltersStateService,
              private userStateService: UserStateService,
              private spotifyUserAccountService: SpotifyUserAccountService,
              private affiliationTimerService: AffiliationTimerService,
              private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone) {
  }

  register(userRegistration: {}) {
    return this.httpClient.post(environment.baseUrl + 'register', param(userRegistration), this.httpPostOptions);
  }

  getUserDetails(): Observable<User> {
    return this.returnHttpGetRequestAsObservable(environment.baseUrlWithApiSh + 'auth/get-user-details')
      .pipe(
        tap(user => {
          if (user.email !== 'shtest@songhunt.com') {
            this.affiliationTimerService.startTimer();
          }

          const connectedUserEmailAddress = this.spotifyUserAccountService.getConnectedUserEmailAddress();
          if (connectedUserEmailAddress && connectedUserEmailAddress === user.email) {
            user.isSpotifyConnected = true;
          }
        })
      );
  }

  getSignupSvg() {
    const baseDomain = location.host.split('.')[0];

    this.httpClient.get(environment.baseUrl + 'get-signup-svg',
      this.generateHttpGetOptions({baseDomain}))
      .subscribe({
        next: (res: any) => {
          if (res && res.svg) {
            this.globalStateService.signUpSvg.set(res.svg);
          } else {
            this.globalStateService.signUpSvg.set(undefined);
          }
        },
        error: err => {
          this.globalStateService.signUpSvg.set(undefined);
        }
      });
  }

  getSongMetadata(id: string): Observable<SongMetadata> {
    return this.returnHttpGetRequestAsObservable(`${environment.baseUrlWithApi}artwork/${id}/metadata`);
  }

  freeTextQuery(query: string): Promise<PrompterResult> {
    return this.returnHttpGetRequestAsPromise(
      environment.baseUrlWithApi + 'free-text/find-by-query/', {query})
      .catch((httpError) => {
        // Handle or log the HTTP error here if needed
        // Then rethrow it to be caught by the specific HTTP error handler
        throw new Error(`HTTP error: ${httpError.message}`);
      });
  }

  async getFilters(): Promise<Filter[]> {
    const res: { metadata: [], features: Filter[], languages: [] } =
      await this.returnHttpGetRequestAsPromise(environment.baseUrlWithApiSh + 'search/list/filters');
    return res.features;
  }

  // when updating the filters file - rename the file
  getFiltersJson() {
    return lastValueFrom(this.httpClient.get<Filter[]>('assets/files/filters_v1.json'))
      .then((filters: Filter[]) => filters.map((filter) => this.finalFilterMapping(filter)));
  }

  async getProjectsIds() {
    return await this.returnHttpGetRequestAsPromise(environment.baseUrlWithApiSh + 'search/list/get-public-project-ids');
  }

  logout() {
    return this.httpClient.get<void>(environment.baseUrl + 'logout', this.generateHttpGetOptions())
      .pipe(tap(() => this.globalStateService.resetPlaylist()));
  }

  login(username: string, password: string): Observable<any> {
    const params = param({username, password});

    return this.httpClient.post<any>(environment.baseUrlWithApiSh + 'auth/login', params, this.httpPostOptions).pipe(
      // Use `tap` to perform the side effect when the call is successful
      tap(() => {
        if (username !== 'shtest@songhunt.com') {
          this.affiliationTimerService.startTimer();
        }
      })
    );
  }

  loginDefaultUser() {
    return this.login(environment.defaultUserEmail, environment.defaultUserPass);
  }

  getYouTubeSuggestions(searchStr: string): Observable<any> {
    return this.httpClient.get<YouTubeSuggestions[]>
    (environment.baseUrlWithApiSh + 'search/list/youtube-suggestions', this.generateHttpGetOptions({
      searchStr,
      take: '4'
    }));
  }

  async getSongs(searchStr: string): Promise<Artwork[]> {
    // Remove all occurrences of the character '
    const sanitizedSearchStr = searchStr.replace(/'/g, '');

    try {
      const artworks = await this.returnHttpGetRequestAsPromise(
        `${environment.baseUrlWithApiSh}search/list/songs`, {searchStr: sanitizedSearchStr});
      return artworks.map((artwork: Artwork) => this.updateArtworkCoverUrl(artwork));
    } catch (error) {
      // Handle the error appropriately
      console.error('Error fetching songs:', error);
      return [];
    }
  }

  getArtwork(id: string): Promise<Artwork> {
    return this.returnHttpGetRequestAsPromise(environment.baseUrlWithApiSh + 'search/get/song', {id});
  }

  cancelRequests() {
    this.cancelOngoingRequest$.next();
    if (this.currentAbort) {
      this.currentAbort();  // Call the abort function to cancel the request
    }
  }

  navigateWithQueryParams(): void {
    const location = window.location.href;
    if (location.includes('signup') || location.includes('affiliate')) {
      return;
    }
    const queryString = this.filtersStateService.convertFiltersToQueryString();
    // this will prevent multiple calls with same filters
    if (this.navigateQueryString === queryString) {
      return;
    } else {
      this.navigateQueryString = queryString;
    }

    // Split the query string into individual parameters
    const queryParams = queryString.split('&').reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {} as any);

    // Check if the only query parameter is referenceSongIds=0
    if (Object.keys(queryParams).length === 1 && queryParams.referenceSongIds === '0') {
      return;  // If true, exit the function early, avoiding the navigate call
    }

    if (this.clearArtistFromUrl()) {
      this.clearArtistFromUrl.set(false);
      this.filtersStateService.artistSearchStr.set(this.artistFromUrl());
      queryParams.searchInArtist = this.artistFromUrl();

      this.artistFromUrl.set(undefined);
      window.location.href = this.trimUrlForPopups(window.location.href, '/artist/') + this.objectToQueryString(queryParams);
      document.title = 'Songhunt: The Future of Song Search and Playlisting';
      return;
    }

    if (this.clearSongFromUrl()) {
      this.clearSongFromUrl.set(false);
      this.filtersStateService.multipleArtworksIds.set(this.songFromUrl());
      queryParams.referenceSongIds = this.songFromUrl();

      this.songFromUrl.set(undefined);
      window.location.href = this.trimUrlForPopups(window.location.href, '/similar/') + this.objectToQueryString(queryParams);
      document.title = 'Songhunt: The Future of Song Search and Playlisting';
      return;
    }

    // Navigate with the query parameters
    this.router.navigate(
      [], // or ['.']
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge' // or 'preserve'
      }
    ).then(() => {
      console.log('routed from: navigateWithQueryParams');
    });
  }

  getQuickTags(searchInArtist?: string): Promise<FinalSong[]> {
    this.globalStateService.setSongsLoading(true);
    this.cancelRequests();

    let params: any = {
      pageNum: this.globalStateService.getPageNumForCall()
    };

    if ((location.href.includes('searchInArtist=') || location.href.includes('/artist/'))
      && this.globalStateService.artistPages !== -1) {
      params.artistPages = this.globalStateService.artistPages;
    }

    let filters: any = {};
    let defaultSongs = false;

    if (this.filtersStateService.constructFeaturesAndCheckIfNotEmpty()) {
      filters = this.deepClone(this.filtersStateService.filterForApiCall);
      this.removeIdFromNonNumericMetadata(filters);

      const primaryFilterId = this.filtersStateService.getPrimaryFilterId();
      if (primaryFilterId) {
        filters.primaryFilterId = primaryFilterId;
      } else {
        delete filters.primaryFilterId;
      }
    } else {
      defaultSongs = true;
    }

    if (searchInArtist) {
      filters.features = [];
      filters.numericMetadata = [];
      filters.nonNumericMetadata = {};
      filters.searchInArtist = searchInArtist;
    }

    filters.projectIds = this.globalStateService.projectIdsToUse;

    if (this.filtersStateService.tagsWithOperator.length > 0) {
      const featureMap: any = new Map(
        filters.features?.map((feature: any) => [feature.id, feature]) || []
      );

      this.filtersStateService.tagsWithOperator.forEach((tag) => {
        const feature = featureMap.get(tag.filterId);
        if (feature) {
          feature.genresOperator = tag.genresOperator;
        }
      });
    }

    params.filters = JSON.stringify(filters);
    if (defaultSongs && !searchInArtist) {
      params.defaultSongs = JSON.stringify(defaultSongs);
    }

    params.discoveryMode = this.globalStateService.discoveryMode();

    return this.getSongsRequest(environment.baseUrlWithApiSh + 'search/tags/search', params);
  }

  getResultsOs(referenceSongIds: string): Promise<FinalSong[]> {
    this.globalStateService.setSongsLoading(true);
    this.cancelRequests();

    let params: any = {referenceSongIds, pageNum: this.globalStateService.getPageNumForCall()};

    let filters: any = {};
    if (this.filtersStateService.constructFeaturesAndCheckIfNotEmpty()) {
      filters = this.deepClone(this.filtersStateService.filterForApiCall);
      this.removeIdFromNonNumericMetadata(filters);
    }

    filters.projectIds = this.globalStateService.whiteLabelProjectIds;

    filters.origin = this.searchOrigin;

    params.filters = JSON.stringify(filters);

    params.discoveryMode = this.globalStateService.discoveryMode();

    const url = `${environment.baseUrlWithApiSh}search/song/${this.filtersStateService.getSelectedSortAddress()}`;
    return this.getSongsRequest(url, params);
  }

  getResultsOsFamilies(referenceSongIds: string, songIds: number[]): Promise<FinalSong[]> {
    let params: any = {referenceSongIds, pageNum: this.globalStateService.getPageNumForCall()};

    let filters: any = {};
    if (this.filtersStateService.constructFeaturesAndCheckIfNotEmpty()) {
      filters = this.filtersStateService.filterForApiCall;
    }

    filters.projectIds = this.globalStateService.whiteLabelProjectIds;
    filters.origin = this.searchOrigin;
    filters.skips = [];
    filters.ids = songIds;
    const weights = {'lyrics_semantics': 0.2, 'lyrics_aesthetics': 0.2, 'music': 0.1, 'production': 0.5};
    const columns = '';

    params.filters = JSON.stringify(filters);
    params.weights = JSON.stringify(weights);
    params.columns = JSON.stringify(columns);

    const urlSemantics = `${environment.baseUrlWithApiSh}search/song/semantics-alike-search`;
    const urlAesthetics = `${environment.baseUrlWithApiSh}search/song/aesthetics-alike-search`;

    return lastValueFrom(combineLatest([
      this.returnHttpGetRequestAsObservable(urlSemantics, params),
      this.returnHttpGetRequestAsObservable(urlAesthetics, params)
    ]).pipe(
      map(([semantics, aesthetics]) => {
        const songMap: { [key: number]: Song } = {};

        const addOrUpdateSong = (song: Song) => {
          // If the song doesn't exist in songMap and its score is greater than 8.5, add it
          if (!songMap[song.id] && song.score > 8.5) {
            songMap[song.id] = song;
          } else if (songMap[song.id]) {
            // If the song already exists in songMap, update its score to the highest score
            songMap[song.id].score = Math.max(songMap[song.id].score, song.score);
          }
        };

        if (semantics) semantics.songs.forEach(addOrUpdateSong);
        if (aesthetics) aesthetics.songs.forEach(addOrUpdateSong);

        const combinedSongs: FinalSong[] = Object.values(songMap).map(song => ({
          id: song.id.toString(),
          artist: song.artist,
          title: song.title,
          url: song.url,
          imageUrl: song.imageUrl,
          youtube_id: song.youtube_id,
          large_image_url: song.large_image_url,
          webp_url: song.webp_url,
          songAddedToPlaylist: false,
          isPopular: song.isPopular,
          lyricsMatch: song.score > 8.5,
          spotify_id: song.spotify_id,
          timestamp: song.timestamp ? song.timestamp : 0
        }));

        return combinedSongs;
      }),
      catchError(error => this.handleError(error))
    ));
  }

  constructFiltersAndNavigate() {
    this.filtersStateService.constructFeaturesAndCheckIfNotEmpty();
    this.navigateWithQueryParams();
  }

  checkPromoCode(promotionCode: string) {
    return this.returnHttpGetRequestAsPromise(environment.baseUrlWithApi + 'accounting/stripe/check-promo-code', {promotionCode});
  }

  getPromoCodeFromRefCode(refCode: string, isInvited?: boolean) {
    if (isInvited) {
      return this.returnHttpGetRequestAsPromise(environment.baseUrlWithApi + 'accounting/check-affiliate-price');
    }
    return this.returnHttpGetRequestAsPromise(environment.baseUrlWithApi + 'accounting/check-affiliate-price', {refCode});
  }

  createCheckoutSession(promotionCode: string | null) {
    let params = promotionCode
      ? param({
        productId: 5,
        promotionCode
      })
      : param({
        productId: 5
      });

    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
      withCredentials: true
    };
    return this.httpClient.post<any>(environment.baseUrlWithApi + 'accounting/stripe/create-checkout-session', params, options)
      .subscribe({
        next: (obj) => {
          window.location.href = obj.url;
          // this.initiateGetUserDetailsTimeout();
        }
      });
  }

  submitSong(song: FinalSong) {
    const title = song.title;
    const artist = song.artist;
    const lyrics = song.lyrics || '';
    const coverUrl = song.imageUrl;
    const youtubeLink = `https://www.youtube.com/watch?v=${song.youtube_id}`;
    const projectId = 3000;
    const params = param({title, artist, lyrics, coverUrl, youtubeLink, projectId});

    return this.httpClient.post<any>(environment.baseUrlWithApi + 'artwork/create', params, this.httpPostOptions)
      .pipe(
        switchMap((res: any) => {
          const triggerParams = param({artworkId: res.body.id});
          return this.httpClient.post<any>(environment.baseUrlWithApiSh + 'search/trigger-processing',
            triggerParams, this.httpPostOptions);
        })
      );
  }

  getFeaturedArtist(): Observable<FeaturedArtist> {
    return this.returnHttpGetRequestAsObservable(environment.baseUrlWithApi + 'get-featured-artist');
  }

  getCuratedPlaylists(): Observable<CuratedPlaylist[]> {
    return this.returnHttpGetRequestAsObservable(environment.baseUrlWithApi + 'get-curated-playlists');
  }

  trimUrlForPopups(url: string, valueToTrim: string): string {
    // Check if the URL contains '/playlist/ | /artists/'
    const index = url.indexOf(valueToTrim);
    if (index !== -1) {
      // Trim the URL from '/playlist/' onward
      return url.substring(0, index);
    }
    return url;  // Return the original URL if '/playlist/' is not found
  }

  uploadFile(uploadUrl: string, formData: FormData) {
    return this.httpClient.post(uploadUrl, formData, {
      withCredentials: true,
      observe: 'events',
      reportProgress: true
    }).pipe(
      timeout(2 * 60 * 1000), // 2 minute timeout
      catchError(error => {
        if (error instanceof TimeoutError) {
          return throwError(() => new Error('Upload timed out. Please try again.'));
        }
        if (error.status === 0) {
          return throwError(() => new Error('Network error occurred. Please check your connection and try again.'));
        }
        return throwError(() => error);
      })
    );
  }

  updateUserPreference(key: string, value: string) {
    const httpParams = param({[key]: value});

    return this.httpClient
      .post<any>(environment.baseUrlWithApi + 'update-preferences', httpParams, this.httpPostOptions)
      .pipe(
        tap(data => {
          const user = this.userStateService.user()!;
          user.preferences[key] = value;
          this.userStateService.setUser(user);
        })
      );
  }

  updateUserPreferences(preferences: Array<{ key: string, value: string }>) {
    const params = preferences.reduce((acc, {key, value}) => ({...acc, [key]: value}), {});
    const httpParams = param(params);

    return this.httpClient
      .post<any>(environment.baseUrlWithApi + 'update-preferences', httpParams, this.httpPostOptions)
      .pipe(
        tap(data => {
          const user = this.userStateService.user()!;
          preferences.forEach(({key, value}) => {
            user.preferences[key] = value;
          });
          this.userStateService.setUser(user);
        })
      );
  }

  async getSongsRequest(url: string, params: any): Promise<FinalSong[]> {
    this.navigateWithQueryParams();
    let results: SearchResultsOs;
    try {
      // Initiating a new request
      const {promise, abort} = this.returnHttpGetRequestAsPromiseWithAbort(url, params);
      this.currentAbort = abort;  // Store abort function for later cancellation
      results = await promise;
    } catch (error: any) {
      // This block should now catch AbortError
      if (error.name === 'AbortError' || error.name === 'EmptyError') {
        console.log('The request was aborted');
      } else {
        console.error('Request failed:', error);
      }
      throw error;
    }

    const filteredAndMappedSongs = results!.songs
      .filter(song => this.emptySongVerify(song))
      .map(song => this.imageMapping(song));

    const uniqueSongs = this.makeUnique(filteredAndMappedSongs);

    return uniqueSongs.map(song => this.finalSongMapping(song));
  }

  sendArtworkIssueReport(reportIssue: ReportIssue) {
    const params = param(reportIssue);

    return this.httpClient.post<any>(environment.baseUrl + 'report/artwork-issue', params, this.httpPostOptions);
  }

  sendEventToServer(eventName: string, objOrString: string | object): void {
    const processedProperties = typeof objOrString === 'string'
      ? this.convertObjectToKeyMapString({objOrString})
      : this.convertObjectToKeyMapString({properties: JSON.stringify(objOrString)});

    const httpParams = new HttpParams({
      fromObject: {
        eventName,
        properties: processedProperties
      }
    });

    this.httpClient.post<any>(
      environment.baseUrlWithApi + 'events/add-event',
      httpParams,
      this.eventHttpHeaders
    ).subscribe(() => {
      // Handle success or leave empty
    });
  }

  private objectToQueryString(params: Record<string, any>): string {
    // Create an instance of URLSearchParams
    const searchParams = new URLSearchParams();

    // Loop through the object and append each key-value pair to the searchParams
    // Ensure each value is converted to a string to avoid TypeScript errors
    for (const [key, value] of Object.entries(params)) {
      if (value !== null && value !== undefined) {
        searchParams.append(key, String(value));
      }
    }

    // Return the query string that starts with '?'
    return '?' + searchParams.toString();
  }

  private updateArtworkCoverUrl(artwork: Artwork): Artwork {
    const invalidCoverUrls = ['nan', 'assets/img/', 'NA'];
    const isInvalidUrl = invalidCoverUrls.some(invalidUrl => artwork.coverUrl?.includes(invalidUrl));

    if (!artwork.coverUrl || isInvalidUrl) {
      artwork.coverUrl = this.defaultSmallImage;
    }

    return artwork;
  }

  private initiateGetUserDetailsTimeout() {
    // If there's an existing subscription, unsubscribe first
    if (this.userDetailsSubscription) {
      this.userDetailsSubscription.unsubscribe();
    }

    const startTime = Date.now();

    this.userDetailsSubscription = timer(0, 30000).pipe(
      // Stop the timer if 5 minutes have passed
      takeWhile(() => Date.now() - startTime < this.fiveMinutes),
      switchMap(() => this.getUserDetails()),
      takeWhile(user => !user.isSubscribed, true) // Emit once after condition fails
    ).subscribe({
      next: (user) => {
        if (user.isSubscribed) {
          this.userDetailsSubscription!.unsubscribe();
          this.userStateService.setUser(user);
        }
      }
    });
  }

  private convertObjectToKeyMapString(obj: any): string {
    const keyValuePairs = Object.entries(obj);  // get an array of the object's key-value pairs

    // concatenate the key-value pairs into a single string
    return keyValuePairs.map(([key, value]) => `${key}: ${value}`).join(', ');
  }

  private handleError<T>(error: any, result?: T) {
    console.error(error); // Log the error to the console (or send to server logs)
    return of({error: 'An error occurred while processing your request.'} as any);
  }

  private emptySongVerify(song: Song | FinalSong): boolean {
    if (this.userStateService.isUserWhiteLabel()) {
      return Boolean(song.url && song.url.trim() !== '') &&
        Boolean(song.title && song.title.trim() !== '') &&
        Boolean(song.artist && song.artist.trim() !== '');
    }

    return Boolean(song.url && song.url.trim() !== '') &&
      Boolean(song.title && song.title.trim() !== '') &&
      Boolean(song.artist && song.artist.trim() !== '') &&
      Boolean(song.youtube_id);
  }

  private imageMapping<T extends Song | FinalSong>(song: T): T {
    let final_src;
    let defaultImage;

    if ('cover_url' in song) {  // If song has a cover_url property
      final_src = song.webp_url || song.large_image_url || song.cover_url || song.imageUrl || this.defaultLargeImage;
      defaultImage = song.cover_url;
    } else {
      final_src = song.webp_url || song.large_image_url || song.imageUrl || this.defaultLargeImage;
    }

    if (this.shouldGetRandomImage(final_src)) {
      final_src = this.defaultLargeImage;
    }

    return {...song, imageUrl: final_src, defaultImage: defaultImage} as T;
  }

  private shouldGetRandomImage(src: string): boolean {
    return src.includes('assets') || src.includes('default_cover.png') || src === 'nan';
  }

  private makeUnique(songs: Song[]): Song[] {
    return songs.reduce((unique: Song[], song: Song) => {
      if (!unique.find(s => (s.title === song.title && s.artist === song.artist) || s.youtube_id === song.youtube_id)) {
        unique.push(song);
      }
      return unique;
    }, []);
  }

  private finalFilterMapping(filter: Filter): Filter {
    filter.totalActiveFilters = 0;
    filter.filters.forEach((f) => f.state = 'none');
    return filter;
  }

  private finalSongMapping(song: Song): FinalSong {
    return {
      id: song.id.toString(),
      artist: song.artist,
      title: song.title,
      url: song.url,
      imageUrl: song.imageUrl,
      youtube_id: song.youtube_id,
      large_image_url: song.large_image_url,
      webp_url: song.webp_url,
      isPopular: song.isPopular,
      songAddedToPlaylist: false,
      lyrics: song.lyrics,
      defaultImage: song.defaultImage,
      spotify_id: song.spotify_id,
      timestamp: song.timestamp ? song.timestamp : 0
    };
  }

  private returnHttpGetRequestAsPromise(url: string, params?: HttpParams | {
    [param: string]: string | string[];
  }, cancelToken?: Subject<void>): Promise<any> {
    const httpCall = this.httpClient.get<any>(url, this.generateHttpGetOptions(params))
      .pipe(takeUntil(cancelToken ? cancelToken.asObservable() : EMPTY));

    return lastValueFrom(httpCall);
  }

  private returnHttpGetRequestAsPromiseWithAbort(url: string, params?: HttpParams | {
    [param: string]: string | string[];
  }): {
    promise: Promise<any>,
    abort: () => void
  } {
    // Cancel the previous request if it exists
    if (this.abortController) {
      this.abortController.abort();  // This will cancel the ongoing request
    }

    // Create a new AbortController instance for this request
    this.abortController = new AbortController();

    // Use a subject to trigger cancellation instead of relying solely on AbortController
    const cancelSubject = new Subject<void>();

    // Create the HTTP GET request with the cancelSubject
    const httpCall = this.httpClient.get<any>(url, this.generateHttpGetOptions(params))
      .pipe(
        takeUntil(cancelSubject)  // Cancel when the cancelSubject emits
      );

    // Return both the promise and an abort function
    return {
      promise: lastValueFrom(httpCall),
      abort: () => {
        cancelSubject.next();  // Trigger cancellation via takeUntil
        cancelSubject.complete();  // Complete the cancellation observable
        this.abortController?.abort();  // Also use AbortController if needed
      }
    };
  }

  private returnHttpGetRequestAsObservable(url: string, params?: HttpParams | {
    [param: string]: string | string[];
  }, cancelToken?: Subject<void>): Observable<any> {
    return this.httpClient.get<any>(url, this.generateHttpGetOptions(params))
      .pipe(takeUntil(cancelToken ? cancelToken.asObservable() : EMPTY));
  }

  private generateHttpGetOptions(params?: HttpParams | { [param: string]: string | string[]; }): any {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      withCredentials: true,
      params
    };
  }

  private removeIdFromNonNumericMetadata(filters: any) {
    this.ngZone.runOutsideAngular(() => {
      if (!filters.nonNumericMetadata) return;

      // Use Object.keys for better performance than for...in with hasOwnProperty
      const keys = Object.keys(filters.nonNumericMetadata);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const metadataArray = filters.nonNumericMetadata[key];

        // Process the array if it exists
        if (Array.isArray(metadataArray)) {
          for (let j = 0; j < metadataArray.length; j++) {
            if (metadataArray[j].id !== undefined) {
              delete metadataArray[j].id;
            }
          }
        }
      }
    });
  }

  /**
   * Creates a deep copy of an object with improved performance
   * @param obj The object to clone
   * @returns A deep copy of the input object
   */
  private deepClone(obj: any): any {
    return this.ngZone.runOutsideAngular(() => {
      // Handle null, undefined, and primitive types
      if (obj === null || obj === undefined || typeof obj !== 'object') {
        return obj;
      }

      // Use structured clone API for better performance when available
      if (typeof structuredClone === 'function') {
        try {
          return structuredClone(obj);
        } catch (e) {
          // Fallback to manual implementation if structuredClone fails
          // (e.g., for objects with functions or circular references)
        }
      }

      // Fallback to optimized manual implementation
      if (Array.isArray(obj)) {
        // Pre-allocate array for better performance
        const copy = new Array(obj.length);
        for (let i = 0; i < obj.length; i++) {
          copy[i] = this.deepClone(obj[i]);
        }
        return copy;
      } else {
        const copy: { [key: string]: any } = {};
        // Use Object.keys for better performance than for...in with hasOwnProperty
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          copy[key] = this.deepClone(obj[key]);
        }
        return copy;
      }
    });
  }
}
