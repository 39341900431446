<div (click)="playVideo()" [class.!z-50]="showIframe()"
     class="youtube-container">
  @if (showIframe()) {
    @if (!iframeLoaded) {
      <mypart-spinner-loading></mypart-spinner-loading>
    }
    <iframe (load)="iframeLoaded = true" [id]="youtube_id" [src]="iframeSrc" allow="autoplay"
            allowfullscreen class="w-full h-full" enablejsapi="1" referrerpolicy="origin"></iframe>
  }
</div>
