import {Injectable, signal} from '@angular/core';
import {interval, takeWhile} from 'rxjs';
import {MyPartProjects, User} from '../interfaces/user';
import {GlobalStateService} from './global-state.service';
import {ModalService} from './modal.service';
import {ModalType} from '../interfaces/modal-types.enum';
import {environment} from '../../environments/environment.prod';
import {manuallyAddScript} from '../helper/custom-hotjar-script';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  isUserWhiteLabel = signal(false);
  user = signal<User | null>(null);
  private timerSubscription: any;
  private totalSongsPlayed = 0;

  constructor(private globalStateService: GlobalStateService,
              private modalService: ModalService) {
    if (!this.user()) {
      this.startTimer();
    }
  }

  _utmTags: string | undefined = undefined;

  get utmTags(): string | undefined {
    return this._utmTags;
  }

  // Setter for whiteLabelEmail
  set utmTags(value: string | undefined) {
    this._utmTags = value?.trim(); // Optional: Trim whitespace
  }

  setUser(user: User | null): void {
    // if (user?.statusId === 1) {
    //   user.isSubscribed = true;
    // }

    this.initPreferences(user);

    if (!this.user() && user?.email !== environment.defaultUserEmail) {
      manuallyAddScript(user!.email);
    }

    this.user.set(user);
    const isWhiteLabel = user?.accountTypeId === 4;
    this.isUserWhiteLabel.set(isWhiteLabel);

    this.setProjectIds();
    if (user) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  checkUserHasExceededAllowedSongs() {
    this.totalSongsPlayed++;
    if (this.totalSongsPlayed > 5) {
      this.showSignUpPopup();
      this.totalSongsPlayed = 1;
    }
  }

  setProjectIds() {
    const isWhiteLabel = this.isUserWhiteLabel();
    const user = this.user();
    this.globalStateService.projectIdsToUse =
      isWhiteLabel && user?.projects
        ? this.extractProjectIds(user.projects) :
        this.globalStateService.publicProjectIds.join(',');
  }

  initPreferences(user: User | null) {
    if (user) {
      const userTheme: 'dark' | 'light' = user.preferences['theme'] as 'dark' | 'light';
      const userDiscovery = user.preferences['discovery'];
      const userSemanticsModel = user.preferences['semanticsModel'] as 'SimCSE' | 'Roberta';

      const localTheme = localStorage.getItem('theme');
      const localDiscovery = localStorage.getItem('discovery');
      const localSemanticsModel = localStorage.getItem('semanticsModel');

      if (userTheme !== localTheme || userDiscovery !== localDiscovery || userSemanticsModel !== localSemanticsModel) {
        this.modalService.open(ModalType.DIFFERENT_USER_PREFERENCES);
      }
      // Always update global state with local values
      this.globalStateService.appTheme.set(localTheme as 'dark' | 'light' || 'light');
      this.globalStateService.discoveryMode.set((localDiscovery || 'false') === 'true');
      this.globalStateService.semanticsModel.set(localSemanticsModel as 'SimCSE' | 'Roberta' || 'SimCSE');
    } else {
      const discovery = localStorage.getItem('discovery');
      if (discovery) {
        this.globalStateService.discoveryMode.set(discovery === 'true');
      } else {
        localStorage.setItem('discovery', 'false');
        this.globalStateService.discoveryMode.set(false);
      }

      const semanticsModel = localStorage.getItem('semanticsModel');
      if (semanticsModel) {
        this.globalStateService.semanticsModel.set(semanticsModel as 'SimCSE' | 'Roberta');
      } else {
        localStorage.setItem('semanticsModel', 'SimCSE');
        this.globalStateService.semanticsModel.set('SimCSE');
      }
    }
  }

  // Show sign up popup after user is not subscribed and in the app for 2 minutes
  private startTimer() {
    const twoMinutes = 2 * 60 * 1000; // 2 minutes in milliseconds

    this.timerSubscription = interval(twoMinutes)
      .pipe(
        takeWhile(() => !this.user()) // unsubscribe when user is not null
      )
      .subscribe({
        next: value => {
          this.showSignUpPopup();
        }
      });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private showSignUpPopup() {
    if (!this.user()) {
      this.modalService.open(ModalType.LOGIN_REGISTER, {
        inputs: {
          loginPopupTitle: 'Sign Up for FREE for unlimited access to Songhunt',
          hideCloseButton: true
        }
      });
    }
  }

  private extractProjectIds(projects: MyPartProjects[]) {
    return projects.map((p) => p.id).join(',');
  }
}
