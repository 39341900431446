import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  Input,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import {FinalSong, SongMetadata} from '../../../interfaces/final-song';
import {GlobalStateService} from '../../../services/global-state.service';
import {IFrameAudioPlayerStateService} from '../../../services/i-frame-audio-player-state.service';
import {MobileDeviceService} from '../../../services/mobile-device.service';
import {TippyDirective} from '@ngneat/helipopper';
import {SocialSharingComponent} from '../social-sharing/social-sharing.component';
import {UserStateService} from '../../../services/user-state.service';
import {HttpReqService} from '../../../services/http-req.service';
import {ToastService} from '../../../services/toast.service';
import {ReportIssue} from '../../../interfaces/report-issue';
import {ModalType} from '../../../interfaces/modal-types.enum';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'mypart-result-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ResultItemComponent implements OnInit, AfterViewInit {
  @ViewChild('socialSharing', {static: false}) socialSharing: SocialSharingComponent | undefined;

  @Input() song!: FinalSong;
  @Input() songPositionInList = 0;
  @Input() isYouTubeSuggestion = false;
  @Input() shouldPrioritize = false;
  @Input() isFirst = false;
  isMobileDevice = this.mobileDeviceService.isMobileDevice;
  isUserWhiteLabel = this.userStateService.isUserWhiteLabel;
  width = this.globalStateService.resultItemWidth;
  height = this.globalStateService.resultItemHeight;
  highlightedSong = signal(false);
  brokenLinkIconVisible = signal(false);
  shareLink = '';
  songMetadata: SongMetadata | undefined = undefined;
  noMetadata = false;
  scrollContainer: HTMLElement | null = null;
  private tooltip: TippyDirective | undefined;

  constructor(private globalStateService: GlobalStateService,
              private userStateService: UserStateService,
              private httpReqService: HttpReqService,
              private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService,
              private mobileDeviceService: MobileDeviceService,
              private modalService: ModalService,
              private el: ElementRef,
              private toast: ToastService) {
    effect(() => {
      const highlightedSong = this.globalStateService.highlightedSong();
      this.highlightedSong.set(highlightedSong?.id === this.song.id);
      if (!this.highlightedSong()) {
        this.brokenLinkIconVisible.set(false);
      }

    });
  }

  get imagePriority(): boolean {
    // Prioritize if it's either one of the first 25 items OR if it's the first item
    return this.shouldPrioritize || this.isFirst;
  }

  ngOnInit() {
    this.scrollContainer = document.getElementById('results-scroll-container');
    this.handleScroll = this.handleScroll.bind(this);
  }

  ngAfterViewInit() {
    this.checkWidthAndHeight();

    this.detectWindowResize();

    this.createShareLink();
  }

  addRemoveSongFromPlaylist() {
    if (!this.song.songAddedToPlaylist && this.globalStateService.totalPlaylistSongs === 100) {
      this.toast.warning('You can add a maximum of 100 songs to a playlist');
      return;
    }

    this.song.songAddedToPlaylist = !this.song.songAddedToPlaylist;
    if (this.globalStateService.addRemoveSongFromPlaylist(this.song)) {
      const user = this.userStateService.user();
      this.httpReqService.sendEventToServer('SH_PLAYLIST_ADD', {
        'email': user ? user.email : 'no user',
        'artworkId': this.song.id,
        'url': window.location.href,
        'songIndex': this.songPositionInList
      });
    }
  }

  openSpotifyTrack(spotifyId: string): void {
    window.open(`https://open.spotify.com/track/${spotifyId}`, '_blank');
  }

  playSongClicked() {
    const resultsContainer = document.getElementById('results-scroll-container')!;
    // Get the position of the current song element
    const position = this.el.nativeElement.getBoundingClientRect().top + resultsContainer.scrollTop;
    this.iFrameAudioPlayerStateService.setSongAndPosition(this.song, position);
    this.globalStateService.highlightedSong.set(this.song);
  }

  showTooltip(tooltip: TippyDirective) {
    if (!this.tooltip?.isVisible) {
      this.tooltip = tooltip;
      this.tooltip.show();
      // Add scroll event listener
      this.scrollContainer?.addEventListener('scroll', this.handleScroll);
    }
  }

  hideTooltip() {
    this.tooltip?.hide();
    // Remove scroll event listener
    this.scrollContainer?.removeEventListener('scroll', this.handleScroll);
  }

  showShareTooltip() {
    this.socialSharing?.showShareTooltip();
  }

  openExtraInfoPopup() {
    this.hideTooltip();
    if (this.songMetadata || this.noMetadata) {
      this.modalService.open(ModalType.SONG_METADATA, {
        inputs: {
          noMetadata: this.noMetadata,
          songMetadata: this.songMetadata || {},
          artist: this.song.artist,
          title: this.song.title
        }
      });
      return;
    }

    this.httpReqService.getSongMetadata(this.song.id)
      .subscribe({
        next: (res) => {
          this.noMetadata = res === null;
          this.songMetadata = res;
          this.modalService.open(ModalType.SONG_METADATA, {
            inputs: {
              noMetadata: this.noMetadata,
              songMetadata: this.songMetadata,
              artist: this.song.artist,
              title: this.song.title
            }
          });
        },
        error: (err) => {
          this.toast.warning('Could not get song info. please try again later');
        }
      });
  }

  toggleBrokenIcon() {
    if (this.isMobileDevice()) {
      return;
    }
    this.toggleBrokenIconForMobile();
  }

  reportIssue() {
    this.hideTooltip();
    const reportIssue: ReportIssue = {
      artworkId: +this.song.id,
      youtubeId: this.song.youtube_id,
      comments: '',
      description: ''
    };
    this.modalService.open(ModalType.REPORT_ISSUE, {
      inputs: {
        reportIssue: reportIssue
      }
    });
  }

  toggleBrokenIconForMobile() {
    this.brokenLinkIconVisible.set(!this.brokenLinkIconVisible());
  }

  private handleScroll(event: any) {
    this.hideTooltip();
  }

  private detectWindowResize() {
    window.addEventListener('resize', () => {
      // timeout is needed to initialize el element
      setTimeout(() => {
        this.checkWidthAndHeight();
      }, 100);
    }, true);
  }

  private checkWidthAndHeight() {
    if (this.isFirst) {
      const width = this.el.nativeElement.offsetWidth;
      const height = width + width / 2;

      this.width.set(width);
      this.height.set(height);
    }
  }

  private createShareLink() {
    const artist = this.song.artist.replaceAll(' ', '-');
    const title = this.song.title.replaceAll(' ', '-');
    this.shareLink = `https://www.mypart.com/songhunt/similar/${artist}-${title}`;
  }
}
