import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'mypart-spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SpinnerLoadingComponent {
}
