import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, tap} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {TokenResponse} from '../../interfaces/spotify';
import {switchMap} from 'rxjs/operators';
import {SpotifyTokensService} from './spotify-tokens.service';

const SPOTIFY_TOKEN_URL = 'https://accounts.spotify.com/api/token';

@Injectable({
  providedIn: 'root'
})
export class SpotifyBaseApiService {
  constructor(protected http: HttpClient, protected spotifyTokensService: SpotifyTokensService) {
  }

  protected refreshAccessToken(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.spotifyAppClientId + ':' + environment.spotifyAppClientSecret)
    });

    const body = new URLSearchParams({
      'grant_type': 'refresh_token',
      'refresh_token': this.spotifyTokensService.getRefreshToken(),
    });

    return this.http.post<TokenResponse>(SPOTIFY_TOKEN_URL, body.toString(), {headers})
      .pipe(
        tap(data => {
          if (data.refresh_token) {
            this.spotifyTokensService.setRefreshToken(data.refresh_token);
          }
        }),
        switchMap(data => of(data.access_token))
      );
  }

  protected getBaseHeaders(accessToken: string): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    });
  }

  protected handleError<T>(error: any, result?: T) {
    console.error(error); // Log the error to the console (or send to server logs)
    return of({error: 'An error occurred while processing your request.'} as any);
  }
}
