export function popupWindow(url: string, windowName: string, w: number, h: number, fullScreen = false) {
  if (fullScreen) {
    return window.open(url, '_blank');
  }

  const y = window.top!.outerHeight / 2 + window.top!.screenY - (h / 2);
  const x = window.top!.outerWidth / 2 + window.top!.screenX - (w / 2);
  return window.open(url, windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

export function logCurrentTime(text: string) {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');

  const formattedTime = `${hours}:${minutes}:${seconds}`;
  console.log(text, formattedTime);
}
