import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[imageLoader]',
  standalone: true
})
export class ImageLoaderDirective implements OnInit {
  @Input() defaultImage = 'assets/missing-song.svg';
  @Input() checkPlaceholder = false;

  private loadingElement?: HTMLElement;
  private isLoading = true;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.setupImage();
    this.showLoadingAnimation();
  }

  private setupImage(): void {
    const img = this.el.nativeElement;

    // Handle successful load
    this.renderer.listen(img, 'load', (event) => {
      if (this.checkPlaceholder) {
        this.handlePlaceholderCheck(event);
      } else {
        this.hideLoadingAnimation();
      }
    });

    // Handle load error
    this.renderer.listen(img, 'error', () => {
      this.renderer.setAttribute(img, 'src', this.defaultImage);
      this.hideLoadingAnimation();
    });
  }

  private handlePlaceholderCheck(event: Event): void {
    const img = event.target as HTMLImageElement;
    const isPlaceholder = img.naturalWidth === 120 && img.naturalHeight === 90;

    if (isPlaceholder) {
      this.renderer.setAttribute(img, 'src', this.defaultImage);
    }

    this.hideLoadingAnimation();
  }

  private showLoadingAnimation(): void {
    if (this.loadingElement) return;

    // Create loading container
    this.loadingElement = this.renderer.createElement('div');
    this.renderer.addClass(this.loadingElement, 'absolute');
    this.renderer.addClass(this.loadingElement, 'inset-0');
    this.renderer.addClass(this.loadingElement, 'bg-accent/10');
    this.renderer.addClass(this.loadingElement, 'animate-pulse');
    this.renderer.setStyle(this.loadingElement, 'z-index', '1');

    // Create loading spinner container
    const spinnerContainer = this.renderer.createElement('div');
    this.renderer.addClass(spinnerContainer, 'absolute');
    this.renderer.addClass(spinnerContainer, 'inset-0');
    this.renderer.addClass(spinnerContainer, 'flex');
    this.renderer.addClass(spinnerContainer, 'items-center');
    this.renderer.addClass(spinnerContainer, 'justify-center');

    // Create spinner
    const spinner = this.renderer.createElement('div');
    this.renderer.addClass(spinner, 'w-8');
    this.renderer.addClass(spinner, 'h-8');
    this.renderer.addClass(spinner, 'border-4');
    this.renderer.addClass(spinner, 'border-primary');
    this.renderer.addClass(spinner, 'border-t-transparent');
    this.renderer.addClass(spinner, 'rounded-full');
    this.renderer.addClass(spinner, 'animate-spin');

    // Assemble loading animation
    this.renderer.appendChild(spinnerContainer, spinner);
    this.renderer.appendChild(this.loadingElement, spinnerContainer);

    // Add to parent container
    const parent = this.renderer.parentNode(this.el.nativeElement);
    if (parent) {
      this.renderer.setStyle(parent, 'position', 'relative');
      this.renderer.insertBefore(parent, this.loadingElement, this.el.nativeElement);
    }
  }

  private hideLoadingAnimation(): void {
    if (this.loadingElement && this.isLoading) {
      const parent = this.renderer.parentNode(this.el.nativeElement);
      if (parent) {
        this.renderer.removeChild(parent, this.loadingElement);
      }
      this.isLoading = false;
      this.loadingElement = undefined;
    }
  }
}
