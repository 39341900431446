import {computed, effect, Injectable, NgZone, Signal, signal} from '@angular/core';
import {
  Feature,
  Filter,
  FilterForApiCall,
  FiltersDictionaryForPrompter,
  NonNumericMetadata,
  NumericMetadata,
  SubFilter
} from '../interfaces/filter';
import {Artwork} from '../interfaces/artwork';
import {SortBy} from '../interfaces/sort-by';
import {Tags} from '../interfaces/prompter-result';
import {ModalService} from './modal.service';
import {ModalType} from '../interfaces/modal-types.enum';
import {BehaviorSubject} from 'rxjs';
import {generateRandomString} from '../helper/random';

@Injectable({
  providedIn: 'root'
})
export class FiltersStateService {
  lockedFilters = signal<string[]>([]);
  filtersVisible = signal(false);
  lyricsContains = signal<string | undefined>(undefined);
  artistSearchStr = signal<string | undefined>(undefined);
  searchOnThisArtist = signal<string | undefined>(undefined);
  narrative = signal<string | undefined>(undefined);
  multipleArtworksIds = signal<string | undefined>(undefined);

  // ✅ Computed signal that reacts to all dependencies
  searchPayloadSignal = computed(() => {
    return {
      updateFilterSignal: this.updateSearchPayloadSignal(),
      lyrics: this.lyricsContains(),
      artist: this.artistSearchStr(),
      searchArtist: this.searchOnThisArtist(),
      narrative: this.narrative(),
    };
  });

  prompterText$ = new BehaviorSubject<string | undefined>(undefined);
  selectedArtwork = signal<Artwork | undefined>(undefined);
  sortByArray = signal<SortBy[]>(this.initializeSortBy());
  youtubeIdFromUrl = signal<string | undefined>(undefined);
  originalFilters = signal<Filter[]>([]);
  filters = signal<Filter[]>([]);
  metadataFilters = computed(() =>
    this.filters().filter((f) => f.isMetadata));
  selectedFilters: { [title: string]: Filter } = {};
  filterForApiCall: FilterForApiCall = this.resetFiltersForApiCall();
  filtersDictionaryForPrompter: FiltersDictionaryForPrompter = {};
  tagsWithOperator: Tags[] = [];

  constructor(private modalService: ModalService,
              private ngZone: NgZone) {
    let debounceTimer: any;

    effect(() => {
      // Synchronously capture the computed value.
      const merged = this.searchPayloadSignal();

      // Now, the effect is registered as dependent on all signals read inside mergedSignal.
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        if (merged.lyrics || merged.artist || merged.searchArtist || merged.narrative) {
          this.generatePrompterText();
        }
      }, 300);
    });
  }

  private _updateSearchPayloadSignal = signal(generateRandomString());

  get updateSearchPayloadSignal(): Signal<string> {
    return this._updateSearchPayloadSignal;
  }

  setRandomUpdateSearchPayloadSignal() {
    this._updateSearchPayloadSignal.set(generateRandomString());
  }

  setFilters(filters: Filter[]) {
    // Save the filters as a backup in originalFilters
    if (this.originalFilters().length === 0) {
      this.originalFilters.set(JSON.parse(JSON.stringify(filters)));
    }

    this.filtersDictionaryForPrompter = {};

    filters.forEach(filter => {
      filter.filters.forEach(subFilter => {
        this.filtersDictionaryForPrompter[subFilter.id] = {
          name: subFilter.name,
          familyName: filter.title
        };
      });
    });

    // Update lockedFilters with only a subset of filters (e.g., first 6 elements)
    this.lockedFilters.set(
      filters
        .map((filter, index) => (index > 6 ? filter.title : null))
        .filter(Boolean) as string[]
    );

    this.filters.set(filters);
  }

  resetFilters() {
    this.lyricsContains.set(undefined);
    this.setRandomUpdateSearchPayloadSignal();

    // Restore the original filters from the backup
    this.filters.set(JSON.parse(JSON.stringify(this.originalFilters()))); // Create a shallow copy to avoid reference issues
  }

  initializeSortBy(): SortBy[] {
    return [
      {
        name: 'Weighted Similarity',
        address: 'search',
        selected: true,
        id: 0
      },
      {
        name: 'Production & Sound',
        address: 'sound-alike-search',
        selected: false,
        id: 1
      },
      {
        name: 'Theme & Mood',
        address: 'semantics-alike-search',
        selected: false,
        id: 2
      },
      {
        name: 'Writing Style',
        address: 'aesthetics-alike-search',
        selected: false,
        id: 3
      },
      {
        name: 'Music & Arrangement',
        address: 'composition-alike-search',
        selected: false,
        id: 4
      }
    ];
  }

  getSortById(id: number): SortBy {
    return this.sortByArray().find((s) => s.id === id)!;
  }

  resetSortBy() {
    this.sortByArray.set(this.initializeSortBy());
  }

  changeSelectedSort(sortBy: SortBy): void {
    const sortByArray = this.sortByArray(); // get the current value of the array
    for (let sort of sortByArray) {
      sort.selected = sort.name === sortBy.name;
    }
    this.sortByArray.set(sortByArray); // push the new array into the signal
    this.generatePrompterText();
  }

  getSelectedSortAddress(): string {
    const selectedSort = this.getSelectedSortBy();
    return selectedSort ? selectedSort.address : ''; // return the address of the selected sort
  }

  getSelectedSortBy() {
    const sortByArray = this.sortByArray(); // get the current value of the array
    return sortByArray.find(sort => sort.selected);
  }

  changeSelectedArtwork(artwork: Artwork | undefined) {
    this.selectedArtwork.set(artwork);
    this.generatePrompterText();
  }

  updateFilter(updatedFilter: Filter) {
    const currentFilters = this.filters();
    const index = currentFilters.findIndex(filter => filter.title === updatedFilter.title);
    if (index !== -1) {
      currentFilters[index] = updatedFilter;
      this.filters.set(currentFilters);
    }
  }

  addOrChangeSubFilterSelectedFilters(filter: Filter, subFilter: SubFilter, state: 'green' | 'red',
                                      isInputFilter = false, genresOperator?: 'and' | 'or') {
    const prevState = subFilter.state;
    if (filter.title === 'Genre' || filter.title === 'Sub-genre') {
      this.handleGenreSubgenreLogic(filter, subFilter);
    }

    if (isInputFilter || this.isSingleSelection(filter.title)) {
      this.ensureSingleSelectionActive(filter, subFilter);
    }

    subFilter.state = state;
    subFilter.genresOperator = genresOperator;

    const selectedFilter = this.selectedFilters[filter.title];
    this.addOrUpdateSubFilter(selectedFilter, filter, subFilter, prevState);

    this.updateFilter(filter); // Update the filter after making changes
    this.checkFilterOverload();
    this.generatePrompterText();
  }

  removeFilter(filter: Filter, subFilter: SubFilter) {
    subFilter.state = 'none';

    const selectedFilter = this.selectedFilters[filter.title];
    this.removeSubFilterIfNecessary(selectedFilter, filter, subFilter);

    this.updateFilter(filter); // Update the filter after making changes
    this.checkFilterOverload();
    this.generatePrompterText();
  }

  clearSubFiltersFromFilter(filter: Filter) {
    filter.filters.forEach(subFilter => {
      subFilter.state = 'none';
    });
    filter.totalActiveFilters = 0;
    if (this.selectedFilters.hasOwnProperty(filter.title)) {
      delete this.selectedFilters[filter.title];
    }

    if (!this.getPrimaryFilterId()) {
      delete this.filterForApiCall.primaryFilterId;
    }

    this.updateFilter(filter);
    this.generatePrompterText();
  }

  getPrimaryFilterId(): number | null {
    // Iterate over the values of selectedFilters to find the first green sub-filter
    for (const filter of Object.values(this.selectedFilters)) {
      const greenSubFilter =
        filter.filters.find(subFilter => subFilter.state === 'green' && !subFilter.propertyName);
      if (greenSubFilter) {
        return greenSubFilter.id;
      }
    }

    // If no green state found, return null
    return null;
  }

  resetSelectedFilters() {
    this.selectedFilters = {};
    this.prompterText$.next(undefined);
    this.resetFilters();
  }

  constructFeaturesAndCheckIfNotEmpty(): boolean {
    let hasActiveFilters = false;

    this.ngZone.runOutsideAngular(() => {
      // Initialize the filterForApiCall
      this.filterForApiCall = this.resetFiltersForApiCall();

      // Iterate over the selectedFilters
      for (let key in this.selectedFilters) {
        let filter = this.selectedFilters[key];

        // Iterate over the SubFilters of the selected Filter
        for (let subFilter of filter.filters) {
          if (subFilter.propertyName) {
            if (subFilter.nonNumericMetadata) {
              const key = filter.title.toLowerCase();
              const f = {
                id: subFilter.id,
                name: subFilter.propertyName,
                state: subFilter.state === 'green'
              };
              if (this.filterForApiCall.nonNumericMetadata[key]) {
                this.filterForApiCall.nonNumericMetadata[key].push(f);
              } else {
                this.filterForApiCall.nonNumericMetadata[key] = [f];
              }
            } else {
              // This is metadata filter
              if (subFilter.state === 'green') {
                this.filterForApiCall.numericMetadata.push({
                  propertyName: subFilter.propertyName,
                  lower: subFilter.start,
                  upper: subFilter.end,
                  state: subFilter.state,
                  id: subFilter.id
                });
              } else if (subFilter.state === 'red') {
                this.filterForApiCall.numericMetadata.push({
                  propertyName: subFilter.propertyName,
                  lower: subFilter.min,
                  upper: subFilter.start,
                  lowerTwo: subFilter.end,
                  upperTwo: subFilter.max,
                  state: subFilter.state,
                  id: subFilter.id
                });
              }
            }
          } else {
            // This is normal filter
            if (subFilter.state === 'green') {
              this.filterForApiCall.features.push({
                id: subFilter.id,
                lower: subFilter.threshold,
                upper: 1000,
                state: subFilter.state
              });
            } else if (subFilter.state === 'red') {
              this.filterForApiCall.features.push({
                id: subFilter.id,
                lower: 0,
                upper: subFilter.limit,
                state: subFilter.state
              });
            }
          }
        }
      }

      const lyricsContains = this.lyricsContains();
      if (lyricsContains) {
        this.filterForApiCall.lyricsContains = lyricsContains;
      }

      const searchInArtist = this.artistSearchStr();
      if (searchInArtist) {
        this.filterForApiCall.searchInArtist = searchInArtist;
      }

      const multipleArtworksIds = this.multipleArtworksIds();
      if (multipleArtworksIds) {
        this.filterForApiCall.multipleArtworksIds = multipleArtworksIds;
      }

      const searchOnThisArtist = this.searchOnThisArtist();
      if (searchOnThisArtist) {
        this.filterForApiCall.searchOnThisArtist = searchOnThisArtist;
      }

      const narrative = this.narrative();
      if (narrative) {
        this.filterForApiCall.narrative = narrative;
      }

      hasActiveFilters = this.filterForApiCall.features.length > 0
        || !!lyricsContains
        || !!searchInArtist
        || !!multipleArtworksIds
        || !!searchOnThisArtist
        || !!narrative
        || this.filterForApiCall.numericMetadata.length > 0
        || Object.keys(this.filterForApiCall.nonNumericMetadata).length > 0;
    });

    return hasActiveFilters;
  }

  applyFilterFromUrl(filterArray: {
                       id: number;
                       state: 'green' | 'red'
                     }[], primaryFilterId?: number, lyricsContains?: string, searchInArtist?: string,
                     multipleArtworksIds?: string, searchOnThisArtist?: string, narrative?: string) {
    this.resetFilters();
    const currentFilters = this.filters();

    for (let filter of filterArray) {
      const correspondingFilter = this.findFilterBySubFilterId(currentFilters, filter.id)!;
      const correspondingSubFilter = this.findSubFilterById(correspondingFilter, filter.id)!;
      correspondingSubFilter.state = filter.state;

      const selectedFilter = this.selectedFilters[correspondingFilter.title];
      this.addOrUpdateSubFilter(selectedFilter, correspondingFilter, correspondingSubFilter, 'none');
    }

    if (primaryFilterId) {
      this.moveFilterToStartOfSelected(primaryFilterId);
    }

    if (lyricsContains) {
      this.lyricsContains.set(lyricsContains);
    }

    if (searchInArtist) {
      this.artistSearchStr.set(searchInArtist);
    }

    if (multipleArtworksIds) {
      this.multipleArtworksIds.set(multipleArtworksIds);
    }

    if (searchOnThisArtist) {
      this.searchOnThisArtist.set(searchOnThisArtist);
    }

    if (narrative) {
      this.narrative.set(narrative);
    }

    this.setFilters(currentFilters);

    this.generatePrompterText();
  }

  convertFiltersToQueryString(): string {
    // Helper function to convert features and numericMetadata arrays to dictionary format
    const convertArrayToQueryString = (arr: (Feature | NumericMetadata | NonNumericMetadata)[]): string =>
      arr.map(item => `id_${item.id}=${item.state}`).join('&');

    const convertNonNumericArrayToQueryString = (arr: NonNumericMetadata[]): string =>
      arr.map(item => `id_${item.id}=${item.state ? 'green' : 'red'}`).join('&');

    // Retrieve the ID from selectedArtwork
    const artworkId = this.selectedArtwork()?.id;

    // Convert features and numericMetadata to query strings
    const featuresString = convertArrayToQueryString(this.filterForApiCall.features);
    const numericMetadataString = convertArrayToQueryString(this.filterForApiCall.numericMetadata);
    const nonNumericMetadataArrays = Object.values(this.filterForApiCall.nonNumericMetadata);
    let nonNumericMetadataStrings: string[] = [];
    for (const array of nonNumericMetadataArrays) {
      nonNumericMetadataStrings.push(convertNonNumericArrayToQueryString(array));
    }
    const nonNumericMetadataString = nonNumericMetadataStrings.join('&');

    // Build the query string using an array
    const queryStringParts: string[] = [];

    if (this.youtubeIdFromUrl()) {
      queryStringParts.push(`youtubeId=${this.youtubeIdFromUrl()}`);
    }

    if (artworkId !== undefined) {
      queryStringParts.push(`referenceSongIds=${artworkId}`);
      const sortByArray = this.sortByArray();
      const index = sortByArray.findIndex((sort) => sort.selected);
      queryStringParts.push(`sortByIndex=${index}`);
    }

    if (featuresString) queryStringParts.push(featuresString);
    if (numericMetadataString) queryStringParts.push(numericMetadataString);
    if (nonNumericMetadataString) queryStringParts.push(nonNumericMetadataString);
    if (this.filterForApiCall.primaryFilterId !== undefined) queryStringParts.push(`primaryFilterId=${this.filterForApiCall.primaryFilterId}`);
    if (this.filterForApiCall.lyricsContains) queryStringParts.push(`lyricsContains=${this.filterForApiCall.lyricsContains}`);
    if (this.filterForApiCall.searchInArtist) queryStringParts.push(`searchInArtist=${this.filterForApiCall.searchInArtist}`);
    if (this.filterForApiCall.multipleArtworksIds) queryStringParts.push(`multipleArtworksIds=${this.filterForApiCall.multipleArtworksIds}`);
    if (this.filterForApiCall.searchOnThisArtist) queryStringParts.push(`searchOnThisArtist=${this.filterForApiCall.searchOnThisArtist}`);
    if (this.filterForApiCall.narrative) queryStringParts.push(`narrative=${this.filterForApiCall.narrative}`);

    // Concatenate the parts using & to form the final query string
    return queryStringParts.join('&');
  }

  areFiltersEmpty(): boolean {
    return Object.keys(this.selectedFilters).length === 0;
  }

  resetParams() {
    this.lyricsContains.set(undefined);
    this.artistSearchStr.set(undefined);
    this.searchOnThisArtist.set(undefined);
    this.multipleArtworksIds.set(undefined);
    this.narrative.set(undefined);
    this.setRandomUpdateSearchPayloadSignal();
  }

  private generatePrompterText(): void {
    const lyricsContains = this.lyricsContains();
    const sortByText = this.getSortText();
    const artwork = this.selectedArtwork();
    const artistSearchStr = this.artistSearchStr();
    const searchOnThisArtist = this.searchOnThisArtist();

    const narrative = this.narrative()
      ? `<strong id="narrative" narrative="true" tabindex="0">${this.narrative()}</strong>`
      : undefined;

    // Return if selectedFilters is empty
    if (!Object.keys(this.selectedFilters).length && !lyricsContains) {
      let title: string = 'Songs';

      // Helper to generate artist or artwork HTML
      const generateHtml = (id: string, name: string, additional?: string) =>
        `<strong id="${id}" artist-name="${name}">${name}${additional ? ` - ${additional}` : ''}</strong>`;

      if (artistSearchStr) {
        title += ` by ${generateHtml(`replaced-value-multiple-${artistSearchStr}`, artistSearchStr)}`;
      }

      if (artwork) {
        // title += `${artistSearchStr ? ' ' : ' by '}${sortByText} ${generateHtml(`replaced-value-${artwork.id}`, artwork.artist, artwork.title)}`;
        title += ` ${sortByText} ${generateHtml(`replaced-value-${artwork.id}`, artwork.artist, artwork.title)}`;
      } else if (searchOnThisArtist) {
        title += ` ${sortByText} ${generateHtml(`replaced-value-multiple-${searchOnThisArtist}`, searchOnThisArtist)}`;
      }

      if (narrative) {
        if (title !== 'Songs') {
          title += `, about ${narrative}`;
        } else {
          title += ` about ${narrative}`;
        }
      }

      // Only update if title has additional content
      this.prompterText$.next(title !== 'Songs' ? this.capitalizeFirstLetter(title) : undefined);
      return;
    }

    // Initialize the segments with placeholders
    const segmentsArray: { [title: string]: string[] } = {
      'Catalog Tier': [],
      'Popularity': [],
      'Decade': [],
      'Gender': [],
      'Genre': [],
      'Sub-genre': [],
      'Mood': [],
      'Sound': [],
      'Instruments': [],
      'Lyrical Theme': [],
      'Lyrical Mood': [],
      'Writing Style': [],
      'Harmony': [],
      'Melody': []
    };

    // Populate segmentsArray based on selected filters
    for (const title in this.selectedFilters) {
      const filter = this.selectedFilters[title];
      const greenStateFilters: string[] = [];
      const redStateFilters: string[] = [];
      for (const subFilter of filter.filters) {
        if (subFilter.state === 'none') continue;

        let name = `<strong filter-family-name="${filter.title}" filter-state="${subFilter.state}" filter-id="${subFilter.id}" id="${subFilter.id}">` + subFilter.name + '</strong>';
        if (subFilter.state === 'red') {
          if (['Genre', 'Sub-genre'].includes(title)) {
            name = '<strong>non</strong> ' + name;
          } else if (['Writing Style', 'Instruments'].includes(title)) {
            name = '<strong>little to no</strong> ' + name;
          } else if (['Harmony', 'Melody'].includes(title)) {
            name = '<strong>are not</strong> ' + name;
          } else {
            name = '<strong>not</strong> ' + name;
          }
          redStateFilters.push(name);
        } else {
          greenStateFilters.push(name);
        }
      }
      segmentsArray[title] = [...greenStateFilters, ...redStateFilters];
    }

    const segmentsString: { [title: string]: string } = {};
    for (const title in segmentsArray) {
      if (segmentsArray[title].length) {
        segmentsString[title] = segmentsArray[title].join(', ');
      }
    }

    // Construct the final prompterText using the segmentsString
    let prompterText = '';

    if (segmentsString['Catalog Tier']) prompterText += segmentsString['Catalog Tier'] + ' catalog ';
    if (segmentsString['Popularity']) prompterText += segmentsString['Popularity'] + ' ';
    if (segmentsString['Decade']) prompterText += segmentsString['Decade'] + ' ';
    if (segmentsString['Gender']) prompterText += segmentsString['Gender'] + ' ';
    if (segmentsString['Genre']) prompterText += segmentsString['Genre'];
    if (segmentsString['Genre'] && segmentsString['Sub-genre']) prompterText += ', ';

    // Replace the ": " prefix inside the <strong> tag while keeping the tag and its attributes intact
    if (segmentsString['Sub-genre']) prompterText += segmentsString['Sub-genre'].replace(/(>)[^>]*?:\s*/g, '$1');

    prompterText += ' songs';

    let parts: string[] = [''];

    const addArtistOrArtwork = () => {
      if (artistSearchStr) {
        parts.push(`by <strong id="replaced-value-multiple-${artistSearchStr}" artist-name="${artistSearchStr}">${artistSearchStr}</strong>`);
      }
      if (artwork) {
        const artworkPart = `${sortByText} <strong id="replaced-value-${artwork.id}" artwork-id="${artwork.id}">${artwork.artist} - ${artwork.title}</strong>`;
        // parts.push(artistSearchStr ? artworkPart : `by ${artworkPart}`);
        parts.push(artworkPart);
      } else if (searchOnThisArtist) {
        parts.push(`${sortByText} <strong id="replaced-value-multiple-${searchOnThisArtist}" artist-name="${searchOnThisArtist}">${searchOnThisArtist}</strong>`);
      }
    };

    addArtistOrArtwork();
    if (narrative) {
      parts.push(`about ${narrative}`);
    }

    // Join all parts with appropriate spacing and commas
    prompterText += parts.join(artistSearchStr || artwork || searchOnThisArtist ? ' ' : ', ');

    const moodSound = [segmentsString['Mood'], segmentsString['Sound']].filter(part => part).join(', ');
    if (moodSound) prompterText += ' that are ' + moodSound;

    const segInstruments = segmentsString['Instruments'];

    if (segInstruments) {
      if (segInstruments?.startsWith('<strong>little to no</strong>')) {
        prompterText += ', featuring ' + segmentsString['Instruments'];
      } else {
        prompterText += ', featuring <strong>prevalent</strong> ' + segmentsString['Instruments'];
      }
    }

    const lyricalParts: string[] = [];

    if (segmentsString['Lyrical Theme']) {
      const themes = segmentsString['Lyrical Theme'].split(', ');

      const greenStateFilters = themes.filter((theme: string) => !theme.startsWith('<strong>not</strong> '));
      const redStateFilters = themes.filter((theme: string) => theme.startsWith('<strong>not</strong> '))
        .map((theme: string) => theme.replace('<strong>not</strong> ', ''));

      let lyricalThemeSegment = '';

      // First, handle green state filters
      if (greenStateFilters.length) {
        lyricalThemeSegment += 'about ' + greenStateFilters.join(', ');
      }

      // Then, handle red state filters
      if (redStateFilters.length) {
        if (lyricalThemeSegment) lyricalThemeSegment += ', ';
        lyricalThemeSegment += '<strong>not about</strong> ' + redStateFilters.join(', ');
      }

      if (lyricalThemeSegment) {
        lyricalParts.push(lyricalThemeSegment);
      }
    }
    if (segmentsString['Lyrical Mood'] || segmentsString['Writing Style']) {
      lyricalParts.push('that ');
    }

    if (segmentsString['Lyrical Mood']) lyricalParts.push('feel ' + segmentsString['Lyrical Mood']);

    if (lyricsContains) {
      const isPlural = lyricsContains.includes(' ');
      const text = isPlural
        ? ', that contain the words '
        : ', that contain the word ';
      prompterText += `${text}<strong id="lyricscontains" lyricscontains="true">${lyricsContains}</strong>`;
    }

    if (lyricalParts.length) {
      const addToText = prompterText === ' songs' ? ' with lyrics ' : ', with lyrics ';
      prompterText += addToText + lyricalParts.join(' ');
    }

    const remainingParts = ['Writing Style', 'Harmony', 'Melody'];
    for (let i = 0; i < remainingParts.length; i++) {
      const part = remainingParts[i];
      if (segmentsString[part]) {
        switch (i) {
          case 0:
            if (segmentsString[part].startsWith(`<strong>little to no`)) {
              prompterText += ', have ' + segmentsString[part];
            } else {
              prompterText += ', have <strong>plenty of</strong> ' + segmentsString[part];
            }
            break;
          case 1:
            prompterText += ', ' + segmentsString[part] + ' musically';
            break;
          case 2:
            if (segmentsString[part].startsWith(`<strong>are not`)) {
              prompterText += ', that ' + segmentsString[part];
            } else {
              prompterText += ', that are ' + segmentsString[part];
            }
            break;
        }
      }
    }

    prompterText = prompterText.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .replace(/ , /g, ', ') // Replace " , " with ", "
      .replace(/that\s*,\s*have/g, 'that have'); // Replace "that , have" or "that, have" or "that ,have" with "that have"

    const totalSubFiltersCount = Object.values(this.selectedFilters)
      .reduce((total, filter) => total + filter.filters.length, 0);

    if ((segmentsString['Harmony'] || segmentsString['Melody']) &&
      !(segmentsString['Mood'] || segmentsString['Sound'] ||
        segmentsString['Lyrical Theme'] || segmentsString['Lyrical Mood'] || segmentsString['Writing Style'] ||
        lyricsContains)) {
      prompterText = prompterText.replace(/, (have|are)(.*)$/, (match, p1, p2) => ` that ${p1}${p2}`);
    }

    if (totalSubFiltersCount > 2) {
      if (segmentsString['Lyrical Theme'] || segmentsString['Lyrical Mood'] || segmentsString['Writing Style']
        || segmentsString['Harmony'] || segmentsString['Melody']) {
        prompterText = prompterText.replace(/,([^,]*)$/, ', and$1'); // Replace last comma with ,and
      }
    }

    this.prompterText$.next(this.capitalizeFirstLetter(prompterText.trim()));
  }

  private capitalizeFirstLetter(str: string): string {
    if (!str) return ''; // Return an empty string if str is empty or not provided
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private moveFilterToStartOfSelected(filterId: number): void {
    let targetFilterTitle: string | null = null;

    // Find the title of the filter with the targetId
    for (const title in this.selectedFilters) {
      if (this.selectedFilters[title].filters.some(sf => sf.id === filterId)) {
        targetFilterTitle = title;
        break;
      }
    }

    // If we found the target filter, move it to the start
    if (targetFilterTitle) {
      const targetFilter = this.selectedFilters[targetFilterTitle];
      // Remove the target filter from selectedFilters
      delete this.selectedFilters[targetFilterTitle];
      // Create a new object with the target filter at the start
      this.selectedFilters = {
        [targetFilterTitle]: targetFilter,
        ...this.selectedFilters
      };
    }
  }

  private isSingleSelection(filterTitle: string) {
    // return filterTitle === 'Genre' || filterTitle === 'Decade' || filterTitle === 'Gender';
    return filterTitle === 'Genre';
  }

  private ensureSingleSelectionActive(filter: Filter, activeSubFilter: SubFilter) {
    filter.filters.forEach(sf => {
      if (sf.id !== activeSubFilter.id && sf.state !== 'none') {
        sf.state = 'none';
        filter.totalActiveFilters -= 1; // decrease the active count for each de-selected sub-filter
      }
    });

    const selectedFilter = this.selectedFilters[filter.title];
    if (selectedFilter) {
      selectedFilter.filters = []; // Clear any existing filters in the selectedFilters for 'Genre'
    }
  }

  private removeSubFilterIfNecessary(selectedFilter: Filter, filter: Filter, subFilter: SubFilter) {
    const subFilterIndex = selectedFilter.filters.findIndex(sf => sf.id === subFilter.id);
    if (subFilterIndex !== -1) {
      selectedFilter.filters.splice(subFilterIndex, 1);
      filter.totalActiveFilters -= 1;
      if (selectedFilter.filters.length === 0) {
        delete this.selectedFilters[filter.title];
      }
    }
  }

  private addOrUpdateSubFilter(selectedFilter: Filter | undefined, filter: Filter, subFilter: SubFilter, prevState: string) {
    if (selectedFilter) {
      const subFilterIndex = selectedFilter.filters.findIndex(sf => sf.id === subFilter.id);
      if (subFilterIndex !== -1) {
        selectedFilter.filters[subFilterIndex] = subFilter;
      } else {
        selectedFilter.filters.push(subFilter);
      }
    } else {
      const filterCopy: Filter = JSON.parse(JSON.stringify(filter));
      filterCopy.filters = [subFilter];
      this.selectedFilters[filter.title] = filterCopy;
    }

    if (prevState === 'none') {
      filter.totalActiveFilters += 1;
    }
  }

  private checkFilterOverload() {
    const dontShow = localStorage.getItem('dont_show_filter_overload');
    if (dontShow) {
      return;
    }
    let totalFilters = 0;

    for (const filter of Object.values(this.selectedFilters)) {
      totalFilters += filter.filters.length;
      if (totalFilters > 5) {
        this.modalService.open(ModalType.FILTER_OVERLOAD);
        return;
      }
    }
  }

  private handleGenreSubgenreLogic(filter: Filter, subFilter: SubFilter) {
    const primaryGenreTitle = 'Genre';
    const subGenreTitle = 'Sub-genre';

    // Detect the current and opposite filter titles
    const currentFilterTitle = filter.title;
    const oppositeFilterTitle = currentFilterTitle === primaryGenreTitle ? subGenreTitle : primaryGenreTitle;

    // Get the primary genre name prefix
    const primaryGenrePrefix = subFilter.name.split(':')[0];

    // Check the opposite filter title from the selectedFilters
    const oppositeSelectedFilter = this.selectedFilters[oppositeFilterTitle];
    if (oppositeSelectedFilter) {
      const matchingFilters = oppositeSelectedFilter.filters.filter(sf => sf.name.includes(primaryGenrePrefix));

      if (matchingFilters && matchingFilters.length > 0) {
        // 1. Remove the primary genres from selectedFilters that match the criteria
        this.selectedFilters[oppositeFilterTitle].filters = this.selectedFilters[oppositeFilterTitle].filters.filter(f => !matchingFilters.includes(f));

        if (this.selectedFilters[oppositeFilterTitle].filters.length === 0) {
          delete this.selectedFilters[oppositeFilterTitle];
        }

        // 2. Update the filter itself in the _filters object with the state of none for each matching filter
        const allFilters = this.filters();
        const targetFilter = allFilters.find(f => f.title === oppositeFilterTitle);
        if (targetFilter) {
          matchingFilters.forEach(matchingFilter => {
            const targetSubFilter = targetFilter.filters.find(sf => sf.name === matchingFilter.name);
            if (targetSubFilter) {
              targetSubFilter.state = 'none';
            }
          });
          // 3. Update totalActiveFilters in the Genre
          targetFilter.totalActiveFilters -= matchingFilters.length;
        }
      }
    }
  }

  // Helper function to find the main filter by a sub-filter's ID
  private findFilterBySubFilterId(filters: Filter[], id: number): Filter | undefined {
    return filters.find(filter => filter.filters.some(sf => sf.id === id));
  }

  // Helper function to find the sub-filter by its ID within a given filter
  private findSubFilterById(filter: Filter, id: number): SubFilter | undefined {
    return filter.filters.find(sf => sf.id === id);
  }

  // Helper function to find the main filter by a sub-filter's propertyName
  private findFilterByPropertyName(filters: Filter[], propertyName: string): Filter | undefined {
    return filters.find(filter => filter.filters.some(sf => sf.propertyName === propertyName));
  }

  // Helper function to find the sub-filter by its propertyName within a given filter
  private findSubFilterByPropertyName(filter: Filter, propertyName: string): SubFilter | undefined {
    return filter.filters.find(sf => sf.propertyName === propertyName);
  }

  private getSortText(): string {
    const sortByArray = this.sortByArray();
    const selected = sortByArray.find((sortBy) => sortBy.selected)?.name!;
    switch (selected) {
      case 'Weighted Similarity':
        return 'similar to';
      case 'Production & Sound':
        return 'that sound like';
      case 'Theme & Mood':
        return 'that are similar in lyrical theme or mood to';
      case 'Writing Style':
        return 'that are similar in lyrical writing style to';
      case 'Music & Arrangement':
        return 'that are musically similar to';
    }
    return 'similar to';
  }

  private resetFiltersForApiCall() {
    return {features: [], numericMetadata: [], nonNumericMetadata: {}};
  }
}
