<div class="animate-pulse flex items-center mb-2.5" role="status">
  <div class="flex items-center justify-center
              md:w-[64px] md:min-w-[64px] md:h-[64px]
              w-[48px] min-w-[48px] h-[48px]
              bg-gray-300 rounded dark:bg-gray-700">
    <svg aria-hidden="true" class="w-10 h-10 text-gray-200 dark:text-gray-600" fill="currentColor"
         viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
    </svg>
  </div>
  <div class="w-full flex flex-col content-center ml-2">
    <div class="h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
  </div>
  <span class="sr-only">Loading...</span>
</div>
