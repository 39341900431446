import {AfterViewInit, Component, ElementRef} from '@angular/core';

@Component({
  selector: 'mypart-result-skeleton',
  templateUrl: './result-skeleton.component.html',
  styleUrls: ['./result-skeleton.component.scss'],
  standalone: false
})
export class ResultSkeletonComponent implements AfterViewInit {
  width = 300;
  height = 0;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.checkWidthAndHeight();
    this.detectWindowResize();
  }

  private detectWindowResize() {
    window.addEventListener('resize', () => {
      // timeout is needed to initialize el element
      setTimeout(() => {
        this.checkWidthAndHeight();
      }, 100);
    }, true);
  }

  private checkWidthAndHeight() {
    this.width = this.el.nativeElement.offsetWidth;
    this.height = this.width + this.width / 2;
  }
}
