<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>

  <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
      What's wrong with this song?
    </h1>
  </div>

  <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
    <div [ngClass]="{'blur-sm': isLoading()}" class="p-4">
      <form (submit)="sendReport()" [formGroup]="reportForm" class="overflow-y-scroll p-6 w-full">
        <div class="space-y-4 md:space-y-6">
          <!-- Issue Type Select -->
          <div class="relative z-0 w-full group">
            <select
                class="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border border-gray-300
                rounded-lg appearance-none dark:bg-gray-800 dark:text-white dark:border-gray-600 focus:outline-none
                focus:ring-2 focus:ring-blue-600 focus:border-blue-600 peer"
                formControlName="issueType"
                id="issueType"
                required>
              <option disabled selected value="">Select an issue</option>
              <option value="inappropriateImage">Wrong Artwork Image</option>
              <option value="brokenImage">Broken YouTube Link</option>
              <option value="brokenVideoLink">Incorrect Song (Song title / Artist mismatch)</option>
              <option value="incorrectVideoLink">Inappropriate Image</option>
              <option value="other">Other</option>
            </select>
            <label class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform
                  -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600
                  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75
                  peer-focus:-translate-y-6"
                   for="issueType">Issue Type</label>
          </div>

          <!-- Comments Textarea -->
          <div class="relative z-0 w-full group">
            <textarea
                class="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg
                        appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-2
                        focus:ring-blue-600 focus:border-blue-600 peer"
                formControlName="comments"
                id="comments"
                placeholder="Please provide additional thoughts or information that could assist us in resolving the issue."
                rows="4"></textarea>
          </div>

          <div class="flex flex-col sm:flex-row justify-end">
            <button
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium
                 rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit">
              Submit Issue
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
