import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalService} from '../../../services/modal.service';
import {GlobalStateService} from '../../../services/global-state.service';
import {HttpReqService} from '../../../services/http-req.service';
import {toggleTheme} from '../../../helper/theme';
import {UserStateService} from '../../../services/user-state.service';

interface PreferenceOption {
  name: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  selected: string;
}

type PreferenceKey = 'theme' | 'discovery' | 'semanticsModel';

interface SelectedPreferences {
  theme: string;
  discovery: string;
  semanticsModel: string;
}

@Component({
  selector: 'app-different-user-preferences',
  templateUrl: './different-user-preferences.component.html',
  styleUrls: ['./different-user-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DifferentUserPreferencesComponent extends BaseModalComponent {

  selectedPreferences: SelectedPreferences;
  preferences: PreferenceOption[] = [];
  private userStateService = inject(UserStateService);

  private globalStateService = inject(GlobalStateService);
  private httpReqService = inject(HttpReqService);
  private preferenceUpdates = [
    {
      key: 'theme' as PreferenceKey,
      getValue: () => this.selectedPreferences.theme,
      updateState: (value: string) => this.globalStateService.appTheme.set(value as 'dark' | 'light'),
      transformValue: (value: string) => value
    },
    {
      key: 'discovery' as PreferenceKey,
      getValue: () => this.selectedPreferences.discovery,
      updateState: (value: string) => this.globalStateService.discoveryMode.set(value === 'true'),
      transformValue: (value: string) => value
    },
    {
      key: 'semanticsModel' as PreferenceKey,
      getValue: () => this.selectedPreferences.semanticsModel,
      updateState: (value: string) => this.globalStateService.semanticsModel.set(value as 'SimCSE' | 'Roberta'),
      transformValue: (value: string) => value === 'true' ? 'Roberta' : 'SimCSE'
    }
  ];

  constructor(modalService: ModalService) {
    super(modalService);
    this.initializePreferences();
    this.selectedPreferences = {
      theme: this.userStateService.user()?.preferences['theme'] || 'light',
      discovery: this.userStateService.user()?.preferences['discovery'] || 'false',
      semanticsModel: this.userStateService.user()?.preferences['semanticsModel'] === 'Roberta' ? 'true' : 'false'
    };
  }

  onApprove() {
    const preferencesToUpdate = this.preferenceUpdates.map(({key, getValue, transformValue}) => ({
      key,
      value: transformValue(getValue())
    }));

    // Update localStorage
    preferencesToUpdate.forEach(({key, value}) => {
      localStorage.setItem(key, value);
    });

    // Update server with all preferences at once
    this.httpReqService.updateUserPreferences(preferencesToUpdate)
      .subscribe({
        next: (response: any) => {
          console.log('Preferences updated successfully:', response);
        },
        error: (error: any) => {
          console.error('Failed to update preferences:', error);
        }
      });

    this.hideModal();
  }

  togglePreference(preference: PreferenceOption, value: string) {
    preference.selected = value;
    const key = preference.name.toLowerCase() as keyof SelectedPreferences;
    this.selectedPreferences[key] = value;

    // Apply theme change immediately if this is a theme toggle
    if (key === 'theme') {
      const transformedValue = this.preferenceUpdates.find(p => p.key === 'theme')?.transformValue(value);
      if (transformedValue) {
        toggleTheme(transformedValue === 'light');
      }
    }
  }

  getSubtitle(name: string) {
    if (name === 'Discovery') {
      return 'Get exposed to more emerging artists and less popular songs.';
    }
    if (name === 'Multilingual Mode') {
      return 'Discover global artists and songs in multiple languages when searching for similar lyrical themes and narratives.\n';
    }
    return '';
  }

  private initializePreferences() {
    const cloudTheme = this.userStateService.user()?.preferences['theme'] || 'light';
    toggleTheme(cloudTheme === 'light');
    const cloudDiscovery = this.userStateService.user()?.preferences['discovery'] || 'false';
    const cloudSemanticsModel = this.userStateService.user()?.preferences['semanticsModel'] || 'SimCSE';

    this.preferences = [
      {
        name: 'Theme',
        options: [
          {value: 'dark', label: 'Dark'},
          {value: 'light', label: 'Light'}
        ],
        selected: cloudTheme
      },
      {
        name: 'Discovery',
        options: [
          {value: 'true', label: 'Enabled'},
          {value: 'false', label: 'Disabled'}
        ],
        selected: cloudDiscovery
      },
      {
        name: 'Multilingual Mode',
        options: [
          {value: 'false', label: 'Enabled'},
          {value: 'true', label: 'Disabled'}
        ],
        selected: cloudSemanticsModel === 'Roberta' ? 'true' : 'false'
      }
    ];
  }
}
