<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  <button
      (click)="hideModal()"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      type="button">
    <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>
  <div class="p-6 text-center">
    <svg aria-hidden="true" class="mx-auto mb-4 text-red-600 w-12 h-12"
         fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      We couldn't find a perfect match.
      <br>
      Try tweaking your search.</h3>
  </div>
</div>
