import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {IFrameAudioPlayerStateService} from '../../../services/i-frame-audio-player-state.service';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'mypart-mp3-player',
  templateUrl: './mp3-player.component.html',
  styleUrl: './mp3-player.component.scss',
  standalone: false
})
export class Mp3PlayerComponent implements AfterViewInit {
  @ViewChild('audioElementRef', {static: true}) audioElementRef!: ElementRef;
  audio: HTMLAudioElement | undefined;

  @Input() audioUrl: string | undefined;
  @Output() playSongClicked = new EventEmitter<void>();

  showAudio = signal(false);
  private songIsPlaying = false;

  constructor(private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService,
              private userStateService: UserStateService) {
    effect(() => {
      const playingAudioUrl = this.iFrameAudioPlayerStateService.playingAudioUrl();
      if (playingAudioUrl !== this.audioUrl) {
        this.showAudio.set(false);
      }
    });

    effect(() => {
      if (this.iFrameAudioPlayerStateService.playingIframeId()) {
        this.showAudio.set(false);
        this.audio?.pause();
        this.audio?.fastSeek(0);
      }
    });
  }

  ngAfterViewInit() {
    this.audio = this.audioElementRef.nativeElement;
  }

  onPlay() {
    this.showAudio.set(true);
    this.songIsPlaying = true;
    this.audio?.play().then();
  }

  onPause() {
    this.songIsPlaying = false;
    this.audio?.pause();
  }

  onEnded() {
    this.songIsPlaying = false;
  }

  playUrl() {
    if (this.songIsPlaying) {
      this.onPause();
    } else {
      this.onPlay();
    }

    if (!this.userStateService.user()) {
      this.userStateService.checkUserHasExceededAllowedSongs();
    }

    this.iFrameAudioPlayerStateService.setPlayingAudio(this.audioUrl, this.audio);
    this.playSongClicked.next();
  }
}
