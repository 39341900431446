<div [ngClass]="{'!max-w-[450px]': selectedSong(), 'max-w-[1000px]': !selectedSong()}"
     class="relative transition-all duration-300 w-[90vw] md:min-w-[32rem]">
  <div [ngClass]="{'blur-sm': isLoading()}"
       class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-[90%]">
    <button
        (click)="hideModal()"
        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        type="button">
      <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"/>
      </svg>
      <span class="sr-only">Close modal</span>
    </button>

    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 class="text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white text-center">
        {{ title() }}
      </h1>
    </div>

    @if (!selectedSong()) {
      <div class="p-4 flex flex-wrap gap-4 justify-center max-h-[calc(100vh-200px)] overflow-y-auto">
        @for (song of songs; track song.id) {
          <div
              class="w-[calc(50%-1rem)] transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl rounded-lg bg-white dark:bg-gray-800 overflow-hidden">
            <div class="relative">
              <mypart-result-item [isYouTubeSuggestion]="true"
                                  [song]="song"
                                  class="pop-animate"
                                  isInView>
              </mypart-result-item>
              <button (click)="chooseSong(song)"
                      class="w-full py-2 px-3 text-sm font-medium text-white bg-gradient-to-r from-red-600 to-red-700
                             hover:from-red-700 hover:to-red-800 transition-all duration-300 focus:ring-2
                             focus:ring-red-300 dark:focus:ring-red-800"
                      stopPropagation
                      type="button">
                <div class="flex items-center justify-center gap-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                  Select
                </div>
              </button>
            </div>
          </div>
        }
      </div>
    } @else {
      <div class="p-6 h-full flex">
        <form (submit)="onSubmit()" [formGroup]="songForm" class="overflow-y-auto p-6 w-full">
          <div class="space-y-4 md:space-y-6">
            <!-- Title Input -->
            <div class="relative z-0 w-full group">
              <input
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2
                border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none
                focus:ring-0 focus:border-blue-600 peer"
                  formControlName="title"
                  id="title"
                  placeholder=" " required type="text">
              <label
                  class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  for="title">Title</label>
            </div>

            <!-- Artist Input -->
            <div class="relative z-0 w-full group">
              <input
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  formControlName="artist"
                  id="artist"
                  placeholder=" " required type="text">
              <label
                  class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  for="artist">Artist</label>
            </div>

            <!-- Lyrics Textarea -->
            <div class="relative z-0 w-full group">
            <textarea
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                formControlName="lyrics"
                id="lyrics"
                placeholder=" "
                rows="8"></textarea>
              <label
                  class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  for="lyrics">Lyrics</label>
            </div>

            <div class="flex flex-col sm:flex-row justify-between">
              <button (click)="changeSongSelection()"
                      class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4
                    focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                    inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700
                    dark:text-white dark:hover:bg-gray-700 mb-4 sm:mb-0"
                      type="button">
                Select a different link
              </button>

              <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium
                 rounded-lg text-sm px-5 py-2.5 text-center"
                  type="submit">
                Submit Song
              </button>
            </div>
          </div>
        </form>
      </div>
    }
  </div>
</div>
