export enum ModalType {
  AFFILIATION_LINK = 'AFFILIATION_LINK',
  FILTER_OVERLOAD = 'FILTER_OVERLOAD',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_REGISTER = 'LOGIN_REGISTER',
  LYRICS = 'LYRICS',
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  REPORT_ISSUE = 'REPORT_ISSUE',
  SONG_METADATA = 'SONG_METADATA',
  YOUTUBE_SUGGESTIONS = 'YOUTUBE_SUGGESTIONS',
  YOUTUBE_URL_WINDOW = 'YOUTUBE_URL_WINDOW',
  NO_RESULTS = 'NO_RESULTS',
  DIFFERENT_USER_PREFERENCES = 'DIFFERENT_USER_PREFERENCES',
} 
